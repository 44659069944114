/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { WithChildren } from '../../../../_metronic/helpers'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { IViewUserRole } from '../../../common/interfaces'
import { ILoginResponse } from '../../../common/interfaces/auth.interface'
import { IUser } from '../../../common/interfaces/user.interface'
import * as authHelper from './AuthHelpers'

type AuthContextProps = {
  auth: ILoginResponse | undefined
  saveAuth: (auth: ILoginResponse | undefined) => void
  currentUser: IUser | undefined
  setCurrentUser: Dispatch<SetStateAction<IUser | undefined>>
  logout: () => void
  permissions: string[]
  savePermissions: (roles: IViewUserRole[] | undefined) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  permissions: [],
  savePermissions: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<ILoginResponse | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUser | undefined>()
  const [permissions, setPermissions] = useState<string[]>(authHelper.getAuthPermissions())

  const saveAuth = (auth: ILoginResponse | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const savePermissions = (roles: IViewUserRole[] | undefined) => {
    setPermissions(authHelper.setAuthPermissions(roles))
  }

  return (
    <AuthContext.Provider
      value={{ auth, saveAuth, currentUser, setCurrentUser, logout, permissions, savePermissions }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  useEffect(() => {
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    const requestUser = async (apiToken: string) => {
      try {
        // TODO: Pendiente servicio para validar token
        if (!didRequest.current) {
          setCurrentUser(auth?.user)
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
