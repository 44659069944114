import { useMutation, useQuery } from '@tanstack/react-query'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import * as Yup from 'yup'
import {
  IGeneralConfigItem,
  IGeneralConfigResponse,
} from '../../../common/interfaces/config.interface'
import { customSwal } from '../../../config/swal'
import { ConfigurationService } from '../../../services/ConfigurationService'

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Formato de correo inválido.')
    .required('Correo electrónico es requerido.'),
})
const phoneSchema = Yup.object().shape({
  phone: Yup.string().trim().required('Número de teléfono es requerido'),
})
const disclaimerSchema = Yup.object().shape({
  disclaimer: Yup.string().trim().required('Disclaimer es requerido.'),
})

export const GeneralConfig = () => {
  const updateConf = useMutation(
    (data: IGeneralConfigItem) => ConfigurationService().updateConfiguration(data),
    {
      onSuccess: () => {
        customSwal.fire({
          icon: 'success',
          title: 'Configuración actualizada correctamente.',
        })
      },
    }
  )
  const emailFormik = useFormik({
    initialValues: { email: '' },
    validationSchema: emailSchema,
    onSubmit: async (values) => {
      updateConf.mutate({
        ...config?.data.find((e) => e.title === 'email')!,
        description: values.email.trim(),
      })
    },
  })
  const phoneFormik = useFormik({
    initialValues: { phone: '' },
    validationSchema: phoneSchema,
    onSubmit: async (values) => {
      updateConf.mutate({
        ...config?.data.find((e) => e.title === 'phone')!,
        description: values.phone.trim(),
      })
    },
  })
  const disclaimerFormik = useFormik({
    initialValues: { disclaimer: '' },
    validationSchema: disclaimerSchema,
    onSubmit: async (values) => {
      updateConf.mutate({
        ...config?.data.find((e) => e.title === 'disclaimer')!,
        description: values.disclaimer.trim(),
      })
    },
  })

  const { data: config, isLoading } = useQuery<IGeneralConfigResponse>(
    ['config-general'],
    ConfigurationService().configurationList,
    {
      onSuccess: (config) => {
        fillForm(config)
      },
    }
  )

  const fillForm = (config: IGeneralConfigResponse) => {
    emailFormik.setFieldValue('email', getTitleFromKey('email', config!) as string)
    phoneFormik.setFieldValue('phone', getTitleFromKey('phone', config!) as string)
    disclaimerFormik.setFieldValue('disclaimer', getTitleFromKey('disclaimer', config!) as string)
  }

  useEffect(() => {
    if (config) {
      fillForm(config)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  const getTitleFromKey = (
    key: 'email' | 'disclaimer' | 'phone',
    config: IGeneralConfigResponse
  ) => {
    return config.data.find((e) => e.title === key)?.description
  }

  return (
    <div>
      <div className='d-flex justify-content-between gap-5'>
        <div className='col-5 d-flex flex-column gap-5'>
          <form onSubmit={emailFormik.handleSubmit} className='card p-10 gap-3 d-flex flex-column'>
            {!isLoading ? (
              <>
                <div>
                  <label className='text-muted fs-6 mb-2 required'>Correo electrónico</label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Ingrese correo electrónico'
                    type='text'
                    {...emailFormik.getFieldProps('email')}
                  />
                  {emailFormik.touched.email && emailFormik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {emailFormik.errors.email}
                      </span>
                    </div>
                  )}
                </div>

                <div className='text-end'>
                  <button
                    style={{
                      background: 'rgb(0, 123, 195)',
                      color: 'white',
                    }}
                    type='submit'
                    className='btn btn col mt-5 fw'
                  >
                    Guardar
                  </button>
                </div>
              </>
            ) : (
              <div className='text-center m-5 p-10'>
                <div className='spinner-border' role='status'></div>
              </div>
            )}
          </form>
          <form onSubmit={phoneFormik.handleSubmit} className='card p-10 gap-3 d-flex flex-column'>
            {!isLoading ? (
              <>
                <div>
                  <label className='text-muted fs-6 mb-2 required'>Número telefónico</label>
                  <input
                    className='form-control form-control-solid'
                    placeholder='Ingrese número de teléfono'
                    type='text'
                    {...phoneFormik.getFieldProps('phone')}
                  />
                  {phoneFormik.touched.phone && phoneFormik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {phoneFormik.errors.phone}
                      </span>
                    </div>
                  )}
                </div>

                <div className='text-end'>
                  <button
                    style={{
                      background: 'rgb(0, 123, 195)',
                      color: 'white',
                    }}
                    type='submit'
                    className='btn btn col mt-5 fw'
                  >
                    Guardar
                  </button>
                </div>
              </>
            ) : (
              <div className='text-center m-5 p-10'>
                <div className='spinner-border' role='status'></div>
              </div>
            )}
          </form>
        </div>
        <form onSubmit={disclaimerFormik.handleSubmit} className='card col-7 p-10'>
          {!isLoading ? (
            <>
              <div className='d-flex align-items-center justify-content-between'>
                <span className='text-muted text-start mt-2 mb-2 fs-6 required'>
                  Disclaimer - Home
                </span>
              </div>
              <textarea
                className='form-control form-control-solid'
                placeholder='Ingrese el disclaimer'
                {...disclaimerFormik.getFieldProps('disclaimer')}
                style={{ height: '250px', resize: 'none' }}
              ></textarea>
              {disclaimerFormik.touched.disclaimer && disclaimerFormik.errors.disclaimer && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger' role='alert'>
                    {disclaimerFormik.errors.disclaimer}
                  </span>
                </div>
              )}

              <div className='text-end'>
                <button
                  style={{
                    background: 'rgb(0, 123, 195)',
                    color: 'white',
                  }}
                  type='submit'
                  className='btn btn col mt-5 fw'
                >
                  Guardar
                </button>
              </div>
            </>
          ) : (
            <div className='text-center' style={{ marginTop: 150 }}>
              <div className='spinner-border' role='status'></div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
