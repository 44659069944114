import { UseMutateFunction } from '@tanstack/react-query'
import { FC } from 'react'
import {
  IPaymentStatusEnum,
  SolicitudeStatusEnum,
} from '../../../common/interfaces/payment.interface'
import { customSwal } from '../../../config/swal'
import { IFilterStatus } from './tabs/SuscriptionTab'

interface IStatus {
  id: string
  label?: string
  value: SolicitudeStatusEnum | IPaymentStatusEnum
}

const listStatus: IStatus[] = [
  { id: '3', label: 'Anulado', value: IPaymentStatusEnum.ANNULLED },
  { id: '2', label: 'Aprobado', value: IPaymentStatusEnum.APPROVED },
]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const listStatusV2: IStatus[] = [
  { id: '1', value: SolicitudeStatusEnum.ANULADA },
  { id: '2', value: SolicitudeStatusEnum.CONFIRMADA },
  { id: '3', value: SolicitudeStatusEnum.INGRESADA },
  { id: '4', value: SolicitudeStatusEnum.LIQUIDADA },
  { id: '5', value: SolicitudeStatusEnum.PROCESADA },
]

interface IProps {
  mutate?: UseMutateFunction<any, unknown, IFilterStatus, unknown>
  rescue?: boolean
  mutationLoading: boolean
  solicitude?: boolean
  setSelectedStatus: (
    state: null | IPaymentStatusEnum,
    cb?: ((state: null | IPaymentStatusEnum) => void) | undefined
  ) => void
  setComment?: (state: string, cb?: ((state: string) => void) | undefined) => void
}

export const StatusList: FC<IProps> = ({
  mutate,
  rescue,
  mutationLoading,
  setSelectedStatus,
  solicitude,
  setComment,
}) => {
  const handleMutation = (status: string) => {
    customSwal
      .fire({
        title: `¿Está seguro que desea cambiar el estado de ${
          rescue ? 'los rescates' : 'las suscripciones'
        } a <strong>${listStatus.find((e) => e.value === status)?.label}</strong>?`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: `Sí, cambiar`,
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          mutate && mutate(status as IFilterStatus)
        }
      })
  }

  return (
    <div className='d-flex gap-3'>
      {listStatus
        .filter((status) => {
          if (!solicitude) {
            return status
          }

          return status.value === IPaymentStatusEnum.ANNULLED
        })
        .map((status) => {
          return (
            <button
              disabled={mutationLoading}
              key={status.id}
              style={{
                border: 'none',
              }}
              onClick={() => {
                setSelectedStatus(status.value as IPaymentStatusEnum)
                if (status.value === IPaymentStatusEnum.ANNULLED) {
                  $('#comment-section').modal('toggle')
                  setComment && setComment('')
                } else {
                  handleMutation(status.value)
                }
              }}
              className='flex-grow-1 badge-light badge rounded py-2 px-3'
            >
              <div className='d-flex align-items-center '>
                <div>{status.label}</div>
              </div>
            </button>
          )
        })}
    </div>
  )
}
