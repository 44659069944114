import React from 'react'
import { ICustomerDetail } from '../../../../common/interfaces'
import { MovementsDocumentTable } from '../tables/MovementsDocumentTable'

interface IProps {
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  customerDetail: ICustomerDetail | undefined
}

export const MovementsTab: React.FC<IProps> = ({
  customerDetail,
  isError,
  isFetching,
  isLoading,
}) => {
  return (
    <>
      <div className='d-flex justify-content-between gap-5'>
        <div className='card col-sm-12 p-10'>
          <h6 className='text-muted fs-4' style={{ color: 'rgb(52, 64, 84)' }}>
            Movimientos
          </h6>
          <div className='table-responsive mt-5'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-20 p-20'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <MovementsDocumentTable customerDetail={customerDetail!} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
