import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { ConfigFunds } from '../../modules/funds/ConfigFunds'
import { Funds } from '../../modules/funds/Funds'
import { RecommendedFunds } from '../../modules/funds/RecommendedFunds'
import { SuspensedView } from '../PrivateRoutes'

export const FundsRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole roles={Permissions.FUND_LIST}>
            <SuspensedView>
              <Funds />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='recommended'
        element={
          <RequireRole roles={Permissions.FUND_RECOMMENDED}>
            <SuspensedView>
              <RecommendedFunds />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='config/:id'
        element={
          <RequireRole roles={Permissions.FUND_UPDATE}>
            <SuspensedView>
              <ConfigFunds />
            </SuspensedView>
          </RequireRole>
        }
      />
    </Routes>
  )
}
