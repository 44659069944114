import { apiUrl } from '../config/api'

export const PermissionService = () => {
  const listPermissions = (page?: number, limit?: number) =>
    apiUrl
      .get(`/capability/permission/list`, {
        params: {
          page,
          limit,
        },
      })
      .then((res) => res.data)

  const getPermissionsBackoffice = ({ isGrouped = false } = {}) =>
    apiUrl
      .get(`capability/permission/backoffice`, {
        params: {
          grouped: isGrouped,
        },
      })
      .then((res) => res.data)

  return {
    listPermissions,
    getPermissionsBackoffice,
  }
}
