import { IClient } from '../../../common/interfaces'

export const formatCustomerFulllname = (customer: Partial<IClient>) => {
  return (
    (customer.name ? `${customer.name.trim()} ` : '') +
    (customer.middlename ? `${customer.middlename.trim()} ` : ' ') +
    (customer.surname ? `${customer.surname.trim()} ` : ' ') +
    (customer.lastname?.trim() ?? '')
  )
}
