/* eslint-disable react/jsx-no-target-blank */
import { BackofficePermissionsEnum as Permissions } from '../../../../app/common/enums/backOfficePermissionsEnum'
import usePermissions from '../../../../app/common/hooks/usePermissions'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'

export function AsideMenuMain() {
  const { hasPermission } = usePermissions()

  return (
    <>
      {/* CLIENTES */}
      {hasPermission(Permissions.USER_CUSTOMER_ALL) && (
        <AsideMenuItemWithSubMain to={['/client']} title='Clientes' fontIcon=' bi-people fs-3'>
          {hasPermission(Permissions.USER_CUSTOMER_ALL) && (
            <AsideMenuItem to='/client/list' title=' Clientes' fontIcon='bi-people fs-3' />
          )}
          {hasPermission(Permissions.USER_CUSTOMER_IMPORT) && (
            <AsideMenuItem
              to='/client/import'
              title=' Importar clientes'
              fontIcon='bi-person-plus-fill fs-3'
            />
          )}
        </AsideMenuItemWithSubMain>
      )}

      {/* FONDOS */}
      {hasPermission(Permissions.FUND_ALL) && (
        <AsideMenuItemWithSubMain to={['/funds']} title='Fondos' fontIcon='bi bi-cash-coin fs-3'>
          {hasPermission(Permissions.FUND_LIST_ALL) && (
            <AsideMenuItem
              to='/funds/list'
              title='Listado de fondos'
              fontIcon='bi-list-stars fs-3'
            />
          )}
          {hasPermission(Permissions.FUND_RECOMMENDED) && (
            <AsideMenuItem
              to='/funds/recommended'
              title='Fondos recomendados'
              fontIcon='bi-bullseye fs-3'
            />
          )}
        </AsideMenuItemWithSubMain>
      )}

      {/* MOVIMIENTOS */}
      {hasPermission(Permissions.MOVEMENT_ALL) && (
        <AsideMenuItem
          className='py-3'
          to='/movements/list'
          title='Movimientos'
          fontIcon='bi-cash-stack fs-3'
        />
      )}

      {/* CONFIGURACION */}
      {hasPermission(Permissions.CONFIG_ALL) && (
        <AsideMenuItemWithSubMain
          to={['/user', '/role', '/configuration', '/prospect']}
          title='Config'
          fontIcon='bi-gear'
        >
          {hasPermission(Permissions.USER_PERSON_ALL) && (
            <AsideMenuItem to='/user/list' title='Usuarios' fontIcon='bi-people fs-3' />
          )}
          {hasPermission(Permissions.ROLE_ALL) && (
            <AsideMenuItem to='/role/list' title='Roles y permisos' fontIcon='bi-archive fs-3' />
          )}
          {hasPermission(Permissions.USER_PROSPECT_ALL) && (
            <AsideMenuItem
              to='/prospect/list'
              title='Prospectos'
              fontIcon='bi-person-bounding-box fs-3'
            />
          )}
          {hasPermission(Permissions.RISK_TOLERANCE_LIST) && (
            <AsideMenuItem
              to='/configuration/forms'
              title='Tolerancia al Riesgo'
              fontIcon='  bi-ui-checks fs-3'
            />
          )}
          {hasPermission(Permissions.USER_EXECUTE_ALL) && (
            <AsideMenuItem
              to='/user/businessExecutive'
              title=' Asesores / Ejecutivos'
              fontIcon='bi-graph-up-arrow fs-3'
            />
          )}
          {hasPermission(Permissions.GENERAL_CONFIG_UPDATE) && (
            <AsideMenuItem
              to='/configuration/general'
              title='Configuración general'
              fontIcon='bi-gear-wide fs-3'
            />
          )}
        </AsideMenuItemWithSubMain>
      )}

      {/* LOGGER */}
      {hasPermission(Permissions.LOGGER_LIST) && (
        <AsideMenuItem to='/logs/list' title='Logger' fontIcon='bi-hdd-stack fs-3' />
      )}
    </>
  )
}
