import { useAuth } from '../../modules/auth'

interface usePermissionsHook {
  hasPermission: (permission: string | string[]) => boolean
}

const usePermissions = (): usePermissionsHook => {
  const { permissions } = useAuth()

  const hasPermission = (permission: string | string[]): boolean => {
    if (typeof permission === 'string') {
      return permissions.includes(permission)
    }

    return permissions.some((p) => permission.includes(p))
  }

  return { hasPermission }
}

export default usePermissions
