import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Outlet } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './modules/auth'

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey='6Ld8am4oAAAAAHNCdSaOjknTuKTF0RW2OsSfLAPh'
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </GoogleReCaptchaProvider>
  )
}

export { App }