import React, { useState } from 'react'
import { customSwal } from '../../../config/swal'

interface PropsI {
  title: string
  onPerformanceData: any
  handleSubmit: (e: any) => void
  formData: any
  setFormData: any
  errors: any
}

interface Card {
  year: number | string
  total: string
  visibility?: boolean
}
export const TableBenchmark = ({ title, handleSubmit, formData, setFormData, errors }: PropsI) => {
  const [cards, setCards] = useState<Card[]>(formData.structure.performance.data)
  const [monthCards, setMonthCards] = useState<Card[]>(formData.structure.performance.monthCards)
  const [error, setError] = useState(cards.map((card, index) => Number(card.total) > 100))
  const [monthError, setMonthError] = useState(cards.map((card, index) => Number(card.total) > 100))

  const handleAddPerformanceDate = (cardIndex: number) => {
    setCards((prevCards) => {
      prevCards.splice(cardIndex + 1, 0, { year: '', total: '' })

      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, data: prevCards },
        },
      })
      return prevCards
    })
  }

  const handleAddPerformanceMonthDate = (cardIndex: number) => {
    setMonthCards((prevCards) => {
      prevCards.splice(cardIndex + 1, 0, { year: '', total: '' })

      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, monthCards: prevCards },
        },
      })
      return prevCards
    })
  }

  const handleCardInputChange = (e: any, cardIndex: any) => {
    const { name, value } = e.target

    if (name === 'total') {
      if (Number(value) > 100 || Number(value) < -100) {
        setError([...error.slice(0, cardIndex), true, ...error.slice(cardIndex + 1, error.length)])
      } else {
        setError([...error.slice(0, cardIndex), false, ...error.slice(cardIndex + 1, error.length)])
      }
    }
    setCards((prevCards) => {
      const updatedCards = [...prevCards]
      const updatedCard = { ...updatedCards[cardIndex], [name]: value }
      updatedCards[cardIndex] = updatedCard
      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, data: updatedCards },
        },
      })
      return updatedCards
    })
  }
  const handleMonthCardChange = (e: any, cardIndex: any) => {
    const { name, value } = e.target

    if (name === 'total') {
      if (Number(value) > 100 || Number(value) < -100) {
        setMonthError([
          ...monthError.slice(0, cardIndex),
          true,
          ...monthError.slice(cardIndex + 1, monthError.length),
        ])
      } else {
        setMonthError([
          ...monthError.slice(0, cardIndex),
          false,
          ...monthError.slice(cardIndex + 1, monthError.length),
        ])
      }
      if (value.trim() === '') {
        setMonthError([
          ...monthError.slice(0, cardIndex),
          true,
          ...monthError.slice(cardIndex + 1, monthError.length),
        ])
      } else {
        setMonthError([
          ...monthError.slice(0, cardIndex),
          false,
          ...monthError.slice(cardIndex + 1, monthError.length),
        ])
      }
    }
    setMonthCards((prevCards) => {
      const updatedCards = [...prevCards]
      const updatedCard = { ...updatedCards[cardIndex], [name]: value }
      updatedCards[cardIndex] = updatedCard

      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, monthCards: updatedCards },
        },
      })
      return updatedCards
    })
  }

  const handleError = () => {
    customSwal.fire({
      icon: 'error',
      title: 'No se puede ingresar un total mayor a 100 y menor a 0.',
    })
  }

  const handleDeletePerformanceDate = (cardIndex: number) => {
    if (cards.length === 2) {
      customSwal.fire({
        icon: 'error',
        title: 'Se tienen que ingresar al menos dos años.',
      })
      return
    }
    setCards((prevCards) => {
      const updatedCards = [...prevCards]
      updatedCards.splice(cardIndex, 1)
      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, data: updatedCards },
        },
      })
      return updatedCards
    })
  }
  const handleDeletePerformanceMonthDate = (cardIndex: number) => {
    if (monthCards.length === 2) {
      customSwal.fire({
        icon: 'error',
        title: 'Se tienen que ingresar al menos dos rangos.',
      })
      return
    }
    setMonthCards((prevCards) => {
      const updatedCards = [...prevCards]
      updatedCards.splice(cardIndex, 1)
      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          performance: { ...formData.structure.performance, monthCards: updatedCards },
        },
      })
      return updatedCards
    })
  }

  return (
    <React.Fragment>
      <div className='text-center card mt-10 p-5 pt-5'>
        <div className='col showInfoFund'>
          <div className='form-check form-switch d-flex align-items-center gap-4'>
            <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
              Mostrar información
            </label>

            <div className='form-check form-switch text-end '>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={formData.structure.performance.visibility}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    structure: {
                      ...formData.structure,
                      performance: {
                        ...formData.structure.performance,
                        visibility: e.target.checked,
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <span className='card-title text-center mt-2 mb-5  text-gray-800 fs-2'>{title}</span>
        <div className='text-end updateConfigFund'>
          <button
            style={{ background: 'rgb(0, 123, 195)' }}
            className='btn text-white cursor-pointer'
            onClick={error.includes(true) || monthError.includes(true) ? handleError : handleSubmit}
            type='submit'
          >
            Actualizar
          </button>
        </div>
      </div>
      <div className='card mt-10 p-10 pb-5'>
        <div className='d-flex justify-content-between w-full '>
          <label className='fw-bolder fs-4 text-gray-800 mb-5'>Rentabilidad Anualizada</label>
          <div className='form-check form-switch d-flex align-items-center gap-4'>
            <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
              Mostrar información
            </label>

            <div className='form-check form-switch text-end '>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={formData.structure.performance.titleVisibility}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    structure: {
                      ...formData.structure,
                      performance: {
                        ...formData.structure.performance,
                        titleVisibility: e.target.checked,
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className='mb-5'>
          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
          <textarea
            className='form-control'
            name='description'
            style={{
              height: '50px',
              resize: 'none',
            }}
            required
            value={formData.structure.performance.title}
            onChange={(e) =>
              setFormData({
                ...formData,
                structure: {
                  ...formData.structure,
                  performance: {
                    ...formData.structure.performance,
                    title: e.target.value,
                  },
                },
              })
            }
            placeholder='Título'
          />
          {errors['performanceAnualizedTitle'] && (
            <span className='text-danger' role='alert'>
              {errors['performanceAnualizedTitle']}
            </span>
          )}
        </div>
        <div className='row g-3'>
          <div className='col-sm-6'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Año - Eje X</label>
            {cards.map((card, cardIndex) => (
              <>
                <input
                  type='text'
                  className='form-control col'
                  name='year'
                  value={card.year}
                  onChange={(e) => handleCardInputChange(e, cardIndex)}
                />
                {errors['year' + cardIndex] && (
                  <span className='text-danger' role='alert'>
                    {errors['year' + cardIndex]}
                  </span>
                )}
                <div className='mb-5'></div>
              </>
            ))}
          </div>
          <div className='col-sm-4'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Rentabilidad (%) - Eje Y</label>
            {cards.map((card, cardIndex) => (
              <>
                {' '}
                <input
                  className='form-control mb-5 col'
                  type='number'
                  min={-100}
                  max={100}
                  name='total'
                  value={card.total}
                  onChange={(e) => handleCardInputChange(e, cardIndex)}
                />
                {error[cardIndex] && (
                  <span className='text-danger' role='alert'>
                    Rentabilidad debe estar entre 0 a 100
                  </span>
                )}
              </>
            ))}
          </div>
          <div className='col-sm-2 text-center'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Acciones</label>
            <br />
            {cards.map((card, cardIndex) => (
              <>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                    marginRight: '20px',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleAddPerformanceDate(cardIndex)}
                >
                  <i className='bi bi-plus-lg text-muted fs-3'></i>
                </button>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleDeletePerformanceDate(cardIndex)}
                >
                  <i className='bi bi-trash text-muted fs-3'></i>
                </button>
                <br />
              </>
            ))}
          </div>
        </div>
      </div>
      <div className='card mt-10 p-10 pb-5 '>
        <div className='d-flex justify-content-between w-full '>
          <label className='fw-bolder fs-4 text-gray-800 mb-5'>Resultado mensualizado</label>
          <div className='form-check form-switch d-flex align-items-center gap-4'>
            <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
              Mostrar información
            </label>

            <div className='form-check form-switch text-end '>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={formData.structure.performance.monthTitleVisibility}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    structure: {
                      ...formData.structure,
                      performance: {
                        ...formData.structure.performance,
                        monthTitleVisibility: e.target.checked,
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className='mb-5'>
          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
          <textarea
            className='form-control'
            name='description'
            style={{
              height: '50px',
              resize: 'none',
            }}
            required
            value={formData.structure.performance.monthTitle}
            onChange={(e) =>
              setFormData({
                ...formData,
                structure: {
                  ...formData.structure,
                  performance: {
                    ...formData.structure.performance,
                    monthTitle: e.target.value,
                  },
                },
              })
            }
            placeholder='Título'
          />
          {errors['performanceMonthTitle'] && (
            <span className='text-danger' role='alert'>
              {errors['performanceMonthTitle']}
            </span>
          )}
        </div>
        <div className='row g-3'>
          <div className='col-sm-6'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Rango - Eje X</label>
            {monthCards.map((card, cardIndex) => (
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control mb-5 col'
                  name='year'
                  value={card.year}
                  onChange={(e) => handleMonthCardChange(e, cardIndex)}
                />
                {errors['range-year' + cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    {errors['range-year' + cardIndex]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className='col-sm-4'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Rentabilidad (%) - Eje Y</label>
            {monthCards.map((card, cardIndex) => (
              <div className='position-relative'>
                <input
                  className='form-control mb-5 col'
                  type='number'
                  min={-100}
                  max={100}
                  name='total'
                  value={card.total}
                  onChange={(e) => handleMonthCardChange(e, cardIndex)}
                />
                {monthError[cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    Rentabilidad es requerido y entre 0 a 100
                  </span>
                )}
                {errors['range-total-' + cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    Rentabilidad es requerido y entre 0 a 100
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className='col-sm-2 text-center'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Acciones</label>
            <br />
            {monthCards.map((card, cardIndex) => (
              <>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                    marginRight: '20px',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleAddPerformanceMonthDate(cardIndex)}
                >
                  <i className='bi bi-plus-lg text-muted fs-3'></i>
                </button>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleDeletePerformanceMonthDate(cardIndex)}
                >
                  <i className='bi bi-trash text-muted fs-3'></i>
                </button>
                <br />
              </>
            ))}
          </div>
        </div>
        <div className='col showInfoFund'></div>
      </div>
    </React.Fragment>
  )
}
