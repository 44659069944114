/* eslint-disable jsx-a11y/anchor-is-valid */
import { UseMutationResult } from '@tanstack/react-query'
import React from 'react'
import { Link } from 'react-router-dom'
import { RiskBadge } from '../../../modules/funds/RiskBadge'
import { BackofficePermissionsEnum as Permissions } from '../../enums/backOfficePermissionsEnum'
import usePermissions from '../../hooks/usePermissions'

type Props = {
  className: string
  title: string
  id: string | number
  cod_risk_profile: number
  level_risk: number | 'none'
  annualized_return: string
  msci_acwi_index: string
  effectiveness: string
  codFund: string
  status: number
  bgColor?: string
  changeStatusFund: UseMutationResult<any, unknown, number, unknown>
  setModal: (modal: string, currentFund: number) => void
}

const FundCard: React.FC<Props> = ({
  className,
  title,
  id,
  status,
  level_risk,
  annualized_return,
  bgColor,
  codFund,
  changeStatusFund,
  setModal,
}) => {
  const { hasPermission } = usePermissions()
  return (
    <React.Fragment>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className='card-body p-0'>
          {/* begin::Header */}
          <div
            className={`px-9 pt-7 card-rounded h-150px w-100`}
            style={{
              background: 'rgb(0, 123, 195)',
            }}
          >
            {/* begin::Heading */}
            <div className='d-flex justify-content-between align-items-center'>
              <div className='m-1 mt-0 d-flex flex-column'>
                <h3 className='m-0 text-white fw-bold  text-start fs-3'>{title}</h3>
              </div>
              {hasPermission([Permissions.FUND_UPDATE, Permissions.FUND_CHANGE_STATUS]) && (
                <div className='text-end align-self-start'>
                  <div style={{ background: 'none', border: 'none' }}>
                    <div className='form-check form-check-sm form-check-custom form-check-solid text-end'>
                      <div className='dropdown'>
                        <button
                          className='btn btn-light d-flex justify-content-center align-items-center'
                          style={{
                            width: '32px',
                            height: '32px',
                            padding: 0,
                          }}
                          type='button'
                          id='dropdownMenuButton'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <i
                            style={{ color: '#0047ad', rotate: '90deg' }}
                            className='fas p-0 fa-ellipsis-v'
                          ></i>
                        </button>
                        <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                          {hasPermission(Permissions.FUND_UPDATE) && (
                            <Link
                              to={`/funds/config/${codFund}`}
                              style={{ color: '#728097' }}
                              className='dropdown-item'
                            >
                              <i className='bi-database-gear m-2'></i>Configuración
                            </Link>
                          )}
                          {hasPermission(Permissions.FUND_CHANGE_STATUS) && (
                            <>
                              {status === 0 ? (
                                <button
                                  data-bs-toggle='modal'
                                  data-bs-target='#Modal'
                                  onClick={() => setModal('Active', +id)}
                                  style={{ color: '#728097' }}
                                  className='dropdown-item text-success'
                                >
                                  <i className='bi bi-unlock m-2 text-success'></i>Activar
                                </button>
                              ) : (
                                status === 1 && (
                                  <button
                                    data-bs-toggle='modal'
                                    data-bs-target='#Modal'
                                    onClick={() => setModal('Inactive', +id)}
                                    style={{ color: '#728097' }}
                                    className='dropdown-item  text-danger'
                                  >
                                    <i className='bi bi-lock m-2 text-danger'></i>Inactivar
                                  </button>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className='shadow-xs card-rounded mx-9 px-6 py-6 position-relative z-index-1 bg-body'
            style={{ marginTop: '-60px' }}
          >
            {/* begin::Item */}
            {/* begin::Symbol */}
            {/* <div className='symbol symbol-45px w-20px me-5'>
                  <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/maps/map004.svg' className='svg-icon-1' />
                  </span>
                </div> */}
            <div className='d-flex mb-4 align-items-center justify-content-between'>
              {/* begin::Title */}
              <div className='fs-5 text-muted '>Rentabilidad Anual:</div>
              <span className='fs-5 text-muted fw-bold'>
                {annualized_return === '' ? '--' : Number(annualized_return).toFixed(2) + '%'}
              </span>
            </div>

            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <RiskBadge formattedRisk={level_risk as 1 | 2 | 3 | 'none'} size='small' />
              </div>
              <div>
                {status === 0 ? (
                  <span className='badge badge-light-danger text-end'>Inactivo</span>
                ) : status === 1 ? (
                  <span className='badge badge-light-success text-end'>Activo</span>
                ) : (
                  <span className='badge badge-light-warning text-end'>Archivado</span>
                )}
              </div>
            </div>
          </div>
          {/* end::Items */}
        </div>
        {/* end::Body */}
      </div>
    </React.Fragment>
  )
}

export { FundCard }
