import { useQuery } from '@tanstack/react-query'
import { FC, useRef, useState } from 'react'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
import { FundService } from '../../../services'
import { uploadFilesPublicity } from '../helpers/filesPublicity.helper'

interface IProps {
  codFund: string
  value: boolean
  handleHidePublicity: (b: boolean) => void
}

export const UploadFilesPub: FC<IProps> = ({ codFund, handleHidePublicity, value }) => {
  const [file, setFile] = useState<any>(uploadFilesPublicity)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const documentQuery = useQuery({
    queryKey: ['documents', codFund],
    queryFn: () => FundService().listFundDocuments(codFund),
    onSuccess: (data) => {
      const matchedItem = data.find(
        (item: any) => item.document_tag === uploadFilesPublicity.document_tag
      )

      if (matchedItem) {
        const newFile = {
          ...uploadFilesPublicity,
          constancy_filename: matchedItem.constancy_filename,
          constancy_url: matchedItem.constancy_url,
          sign: matchedItem.sign,
        }
        setFile(newFile)
      }
    },
    enabled: !!codFund,
  })

  const saveFile = (event?: any) => {
    const target = event?.target as HTMLInputElement
    const newFile: File = (target.files as FileList)[0]

    const formData = new FormData()

    if (!newFile.name.match(/[À-ÿ]/u)) {
      formData.append('file', newFile, newFile.name.replaceAll(' ', ''))

      formData.append('codFund', codFund)
      formData.append('document_tag', file.document_tag)

      apiUrl
        .post(process.env.REACT_APP_BACK_API_URL + '/fund/bo/upsert-document', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((e) => {
          documentQuery.refetch()
          customSwal.fire({
            icon: 'success',
            title: '¡El archivo se subió con éxito!',
          })
        })
      //   .finally(() => {
      //     customSwal.fire({
      //         icon: 'success',
      //         title: 'Se adjuntó el archivo con éxito',
      //       })
      //   })
    } else {
      customSwal.fire({
        icon: 'error',
        title: 'El archivo no es válido, asegúrate que no contenga caracteres inválidos.',
      })
    }
  }

  return (
    <table className='table table-row-dashed table-row-gray-300 gy-7'>
      <thead>
        <tr className='fw-bolder fs-6 text-gray-700'>
          <th>DOCUMENTO</th>
          <th className='text-center'>ACCIÓN</th>
          <th className='text-center'>VISIBILIDAD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span className='text-muted' style={{ marginBottom: '0px' }}>
              {file.placeholder}
            </span>

            <input
              type='text'
              className='form-control mb-2 mb-md-0'
              disabled
              title={file.placeholder}
              placeholder={file.placeholder}
              value={file.constancy_filename}
            />
          </td>
          <td className='text-center'>
            {/* <input type="file" onChange={saveFile} />
                        <i className='bi bi-upload text-muted fs-3' /> */}
            {/* <div className="d-flex align-items-center justify-content-center mt-5">
                            <input
                            type="file"
                            ref={fileInputRef}
                            className="d-none"
                            onChange={saveFile}
                            />
                            <i
                            className="bi bi-upload text-muted fs-3"
                            style={{ cursor: 'pointer' }}
                            onClick={handleClick}
                            />
                        </div> */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className='form-check form-check-custom form-check-solid'
            >
              <button
                style={{
                  background: 'transparent',
                  color: 'white',
                  border: '1px solid #ccc',
                  marginRight: '5px',
                }}
                type='button'
                className='btn btn-icon mb-5'
                onClick={handleClick}
              >
                <input
                  type='file'
                  accept='.jpg, .jpeg, .png'
                  ref={fileInputRef}
                  className='d-none'
                  onChange={saveFile}
                />
                <i className='bi bi-upload text-muted fs-3' style={{ cursor: 'pointer' }} />
                {/* onClick={handleClick} */}
              </button>
            </div>
          </td>

          <td className='text-center'>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className='form-check form-check-custom form-check-solid'
            >
              <button
                style={{
                  background: 'transparent',
                  color: 'white',
                  border: '1px solid #ccc',
                  marginRight: '5px',
                }}
                type='button'
                className='btn btn-icon mb-5'
                onClick={() => handleHidePublicity(value)}
              >
                <i
                  className={`bi bi-${
                    value === true || value === undefined ? 'eye' : 'eye-slash'
                  } text-muted fs-3`}
                ></i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
