/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { getPermissionsSelected } from '../../../../common/helpers/Permission'
import { usePermissionCheckboxV2 } from '../../../../common/hooks/usePermissionCheckboxV2'
import { ICreateRoleV2, IRoleView, StatusEnum } from '../../../../common/interfaces'

interface IProps {
  formProps: FormikProps<ICreateRoleV2>
  mode: 'create' | 'edit'
  data?: IRoleView
}

const RoleForm = ({ formProps, mode, data }: IProps) => {
  const { permissionTable } = usePermissionCheckboxV2({
    formProps,
    mode,
    defaultData: data?.permissions,
  })

  useEffect(() => {
    if (mode === 'create') {
      formProps.setFieldValue('status', StatusEnum.ACTIVATED)
    }
  }, [])

  useEffect(() => {
    if (data && mode === 'edit') {
      const permissionsSelected = getPermissionsSelected(data.permissions)
      formProps.setFieldValue('name', data.name)
      formProps.setFieldValue('status', data.status)
      formProps.setFieldValue('description', data.description)
      formProps.setFieldValue('permissions', permissionsSelected)
    }
  }, [data])

  return (
    <React.Fragment>
      <div className='card-header border-0'>
        <div className='card-title'>
          <span className='card-label fw-bolder' style={{ color: '#4B5667', fontSize: '24px' }}>
            Datos Generales
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='row row-gap-2'>
          <div className='col-lg-6'>
            <label className='col-form-label required'>Nombre del rol</label>
            <Field
              placeholder='Ingrese nombre del rol'
              name='name'
              className='form-control form-control-solid'
              maxLength={140}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='name' />
            </div>
          </div>
          <div className='col-lg-6'>
            <label className='col-form-label required'>Estado</label>
            <Field
              as='select'
              placeholder='Seleccione estado'
              name='status'
              className='form-select form-select-solid'
              defaultValue={StatusEnum.ACTIVATED}
              // disabled={mode === 'create'}
            >
              <option value=''>Seleccionar</option>
              <option value={StatusEnum.ACTIVATED}>Activo</option>
              <option value={StatusEnum.DISABLED}>Inactivo</option>
            </Field>
            <div className='text-danger mt-2'>
              <ErrorMessage name='status' />
            </div>
          </div>
          <div className='col-lg-12 d-flex flex-column'>
            <label className='col-form-label required'>Descripción</label>
            <Field
              as='textarea'
              placeholder='Ingrese descripción'
              name='description'
              className='form-control form-control-solid'
              maxLength={140}
              style={{ height: '60px', resize: 'none' }}
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='description' />
            </div>
          </div>
        </div>
      </div>
      <div className='card-header border-0'>
        <div className='card-title'>
          <span className='card-label fw-bolder' style={{ color: '#4B5667', fontSize: '24px' }}>
            Permisos Generales
          </span>
        </div>
      </div>
      <div className='card-body pt-1'>
        <div className='table-responsive'>
          <div className='text-danger my-2'>
            <ErrorMessage name='permissions' />
          </div>
          <div style={{ backgroundColor: '#ffffff', boxShadow: '#344054' }}>
            {permissionTable()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default RoleForm
