import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { FundService } from '../../../services/FundService'

interface IModalProps {
  id: string
  title: string
  selectedClient: number
  docType: string
  numDoc: string
}
export const ClientFundsModal = ({ id, title, selectedClient, numDoc, docType }: IModalProps) => {
  const {
    isLoading,
    data: fundsByUser,
    isFetching,
    isError,
  } = useQuery(
    ['fund-per-user', selectedClient],
    () => FundService().fundsByUser(docType, numDoc),
    {
      enabled: id.includes(selectedClient.toString()),
    }
  )

  return (
    <React.Fragment>
      <div className='modal fade' tabIndex={-1} id={id}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              {fundsByUser && (
                <h5 className='modal-title'>
                  {title} : {(isLoading || isFetching) && !isError ? '' : fundsByUser.data.length}
                </h5>
              )}
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>

            <div className='modal-body'>
              {/* {!data } */}
              {(isLoading || isFetching) && !isError ? (
                <div className='text-center m-5 p-10'>
                  <div className='spinner-border' role='status'></div>
                </div>
              ) : (
                <>
                  {fundsByUser && fundsByUser.data.length > 0 ? (
                    <div>
                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr className='fw-bold fs-6 text-gray-800'>
                              <th>NOMBRE DE FONDO </th>
                              <th>SERIE</th>
                              <th>ESTADO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {fundsByUser.data.map((data: any, i: number) => (
                              <tr key={i}>
                                <td>{data.descripFondo}</td>
                                <td>{data.descripFondoSerie}</td>
                                <td>{data.estado}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <span>No tiene actualmente fondos invertidos</span>
                  )}
                </>
              )}
            </div>

            <div className='modal-footer'>
              <button
                style={{
                  background: '#0066CC',
                  color: 'white',
                }}
                type='button'
                className='btn btn'
                data-bs-dismiss='modal'
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
