/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from '@tanstack/react-query'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { usePagination } from '../../../common/hooks/usePagination'
import { ILeaflet, IListILeaflet } from '../../../common/interfaces/user.interface'
import { apiUrl } from '../../../config/api'

const pageSizes = [20, 30, 50, 100]

export const BloquedClients = forwardRef((props, ref) => {
  const [selectUserId, setSelectUserId] = useState(0)
  const {
    isLoading,
    isError,
    data: usersClient,
    isFetching,
    isRefetching,
    refetch,
  } = useQuery<IListILeaflet>(['user-client'], () => listUsers(), {
    keepPreviousData: true,
  })
  const { page, limit, setPage, setLimit, paginationComponent } = usePagination({
    initPage: 1,
    initLimit: 20,
    list: usersClient,
    pageSizes,
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  useEffect(() => {
    refetch()
  }, [page])
  const listUsers = () =>
    apiUrl.get(`/user/app/refresh-list?limit=${limit}&page=${page}`).then((res) => res.data)

  const cleanupRequest = () => {
    setPage(1)
    setLimit(20)
  }
  const handleUserID = (id: number) => {
    setSelectUserId(id)
  }

  useImperativeHandle(ref, () => ({
    refreshList() {
      refetch()
    },
  }))

  return (
    <React.Fragment>
      <div className='card p-5'>
        <div className='card-body'>
          <div className='table-responsive'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-5 p-10'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th className='min-w-150px'>NOMBRE Y APELLIDOS</th>
                    <th className='min-w-150px'>CORREO</th>
                    <th className='w-25px'>ACCIONES</th>
                  </tr>
                </thead>

                <tbody>
                  {usersClient?.data.map((user: ILeaflet, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          <span className='text-dark text-hover-primary fs-6'>{user.name}</span>
                          <span className='text-muted d-block fs-5'>{user.lastname}</span>
                        </td>

                        <td>
                          <span className='text-muted d-block fs-5'>{user.email}</span>
                        </td>

                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <div className='dropdown'>
                              <button
                                className='btn btn-light'
                                data-bs-toggle='modal'
                                data-bs-target={`#Validate${user.id}`}
                                onClick={() => handleUserID(user.id)}
                                type='button'
                                id='dropdownMenuButton'
                              >
                                {' '}
                                Ver
                              </button>
                            </div>
                            <div className='modal fade' tabIndex={-1} id={`Validate${user.id}`}>
                              <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                  <div className='modal-header'>
                                    <h5 className='modal-title'>Transacciones inválidas</h5>
                                    <div
                                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                      data-bs-dismiss='modal'
                                      aria-label='Close'
                                    >
                                      {/* <KTSVG
                                        path="/media/icons/duotune/arrows/arr061.svg"
                                        className="svg-icon svg-icon-2x" /> */}
                                    </div>
                                  </div>
                                  <div className='modal-body'>
                                    <div className='row mt-2 m-5'>
                                      <div className='table-responsive'>
                                        <table className='table table-bordered'>
                                          <thead>
                                            <tr className='fw-bold fs-6 text-gray-800'>
                                              <th>TRANSACCIÓN</th>
                                              <th className='text-center'>INTENTOS</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr className='m-5'>
                                              <td>Validación de correo electrónico</td>
                                              <td
                                                className={`text-center ${
                                                  user.email_validation_retries >= 3
                                                    ? 'text-danger'
                                                    : 'text-dark'
                                                }`}
                                              >
                                                {user.email_validation_retries}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Recuperación de contraseña</td>
                                              <td
                                                className={`text-center ${
                                                  user.email_password_recovery_retries >= 3
                                                    ? 'text-danger'
                                                    : 'text-dark'
                                                }`}
                                              >
                                                {user.email_password_recovery_retries}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Validación Face ID</td>
                                              <td
                                                className={`text-center ${
                                                  user.toc_retries >= 3
                                                    ? 'text-danger'
                                                    : 'text-dark'
                                                }`}
                                              >
                                                {user.toc_retries}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Reintentos de crear contraseña</td>
                                              <td
                                                className={`text-center ${
                                                  user.email_creation_password_retries >= 3
                                                    ? 'text-danger'
                                                    : 'text-dark'
                                                }`}
                                              >
                                                {user.email_creation_password_retries}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      type='button'
                                      className='btn'
                                      data-bs-dismiss='modal'
                                      style={{ background: '#e2f4ff', color: '#1c73cf' }}
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      data-bs-toggle='modal'
                                      data-bs-target='#Sure'
                                      style={{
                                        background: '#0066CC',
                                        color: 'white',
                                      }}
                                      type='button'
                                      className='btn btn'
                                      data-bs-dismiss='modal'
                                    >
                                      Restablecer
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
            {isError && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-5'>
                No se encontraron resultados
                <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  refrescar la lista
                </a>
              </span>
            )}
            {usersClient?.data.length === 0 && !isLoading && !isRefetching && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
                No se encontraron resultados
                {/* <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  mostrar todos
                </a> */}
              </span>
            )}
          </div>
          {paginationComponent()}
        </div>
        <ConfirmModal
          selectUserId={selectUserId}
          id='Sure'
          title='¿Está seguro de restablecer los intentos?'
          negativeAnswer='Cancelar'
          positiveAnswer='Sí, restablecer'
          confirmationId='#ConfirmSure'
          confirmationTitle='Los intentos han sido restablecidos'
          confirmationAnswer='Aceptar'
        />
      </div>
    </React.Fragment>
  )
})
