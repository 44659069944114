/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-script-url */
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStateCallback } from '../../common/hooks/useStateCallback'
import { FundAmountEnum, IFundAmountFeature } from '../../common/interfaces'
import { apiUrl } from '../../config/api'
import { customSwal } from '../../config/swal'
import { FundService } from '../../services'
import { UploadFiles } from './components/UploadFiles'
import { UploadFilesPub } from './components/UploadFilesPublicity'
import { TableBenchmark } from './configuration/TableBenchmark'
import { TableResults } from './configuration/TableResults'
import { featuresDisabled } from './helpers/files.helper'

const steps = [
  'INFORMACIÓN Y RIESGO',
  'ESTRATEGIA DE INVERSIÓN',
  'RENDIMIENTO',
  'DESEMPEÑO',
  'PORTAFOLIO',
  'CONTENIDO',
]

export const ConfigFunds = () => {
  const { id: fundId } = useParams()
  const currentYear = new Date().getFullYear()
  const [refetch, setRefetch] = useState(false)
  const [file, setFile] = useState('')
  const [errors, setErrors] = useStateCallback<any>({})
  var errorAcc: any = {}

  const [items, setItems] = useState([
    {
      name: '',
      color: '#0066cc',
      graphicValue: '',
    },
  ])

  const [itemCaracter, setItemCaracter] = useState([
    {
      title: '',
      description: '',
      habitat: '',
    },
  ])
  const [itemComission, setItemComission] = useState([
    {
      title: '',
      description: '',
      habitat: '',
    },
  ])
  const [briefcase, setBriefcase] = useState([
    {
      name: '',
      color: '#0066cc',
      graphicValue: '',
    },
  ])

  function addItemBrief(e: any) {
    e.preventDefault()
    setBriefcase([...briefcase, newItems])
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        briefcase: {
          ...formData.structure.briefcase,
          data: [...formData.structure.briefcase.data, newItems],
        },
      },
    })
  }
  const newItems = {
    name: '',
    color: '#0066cc',
    graphicValue: '',
  }
  const ContentValue = {
    title: '',
    description: '',
    habitat: '',
  }
  function removeItemBrief(index: number, e: any) {
    e.preventDefault()
    const newItems = [...briefcase]
    newItems.splice(index, 1)
    setBriefcase(newItems)
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        briefcase: {
          ...formData.structure.briefcase,
          data: newItems,
        },
      },
    })
  }

  function handleTextInputChangeBrief(index: number, value: any) {
    const newItems = [...briefcase]
    newItems[index].name = value
    setBriefcase(newItems)
    setErrors({
      ...errors,
      ['briefcaseName' + index]: '',
    })
  }

  function handleColorInputChangeBrief(index: number, value: any) {
    const newItems = [...briefcase]
    newItems[index].color = value
    setBriefcase(newItems)
  }
  function handleNumberValueBrief(index: number, value: any) {
    const newItems = [...briefcase]
    newItems[index].graphicValue = value
    setBriefcase(newItems)
    setErrors({
      ...errors,
      ['briefcaseValue' + index]: '',
    })
  }

  function handleTitleInputCaracter(index: number, value: any) {
    const newItems = [...itemCaracter]
    newItems[index].title = value
    setItemCaracter(newItems)
  }

  function changeDescription(index: number, value: any) {
    const newItems = [...itemComission]
    newItems[index].description = value
    setItemComission(newItems)
  }
  function changeHabitat(index: number, value: any) {
    const newItems = [...itemComission]
    newItems[index].habitat = value
    setItemComission(newItems)
  }

  function titleComission(index: number, value: any) {
    const newItems = [...itemComission]
    newItems[index].title = value
    setItemComission(newItems)
  }

  function handledescriptionInputChangeCaracter(index: number, value: any) {
    const newItems = [...itemCaracter]
    newItems[index].description = value
    setItemCaracter(newItems)
  }
  function handleHabitatInputChangeCaracter(index: number, value: any) {
    const newItems = [...itemCaracter]
    newItems[index].habitat = value
    setItemCaracter(newItems)
  }

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean
  }>({})
  const handlePerformanceData = (cardsData: any) => {
    // Realiza las acciones necesarias con los datos de cardsData
  }

  useEffect(() => {
    $('form').on('focus', 'input[type=number]', function (e) {
      $(this).on('wheel.disableScroll', function (e) {
        e.preventDefault()
      })
    })
    $('form').on('blur', 'input[type=number]', function (e) {
      $(this).off('wheel.disableScroll')
    })
  }, [])

  useEffect(() => {
    // Lógica para obtener los datos del fondo utilizando el ID
    // Puedes hacer una llamada a una API, acceder a una base de datos, etc.
    //Falta conectar la demas vizualizacion del fondo y verificar la api de funds
    apiUrl
      .patch(`/fund/find/${fundId}`)
      .then((response: any) => {
        setItemComission(response.data.at(0).structure.content.commissions.data)
        setItemCaracter(response.data.at(0).structure.content.features.data)
        setBriefcase(response.data.at(0).structure.briefcase.data)
        setItems(response.data.at(0).structure.investmentStrategy.data)
        const { performance, content, investmentStrategy, riskLevel } =
          response.data.at(0).structure
        const { publicity, disclaimerVisibility } = content
        if (publicity === undefined) {
          response.data.at(0).structure.content.publicity = {
            title: '',
            visibility: true,
          }
        }

        if (disclaimerVisibility === undefined)
          response.data.at(0).structure.content.disclaimerVisibility = true

        const { informationTitle } = riskLevel
        if (informationTitle === undefined)
          response.data.at(0).structure.riskLevel.informationTitle = ''

        const { graphicTitle } = investmentStrategy
        if (graphicTitle === undefined)
          response.data.at(0).structure.investmentStrategy.graphicTitle = ''

        const { titleVisibility, monthTitleVisibility, monthTitle } = performance
        if (monthTitle === undefined) response.data.at(0).structure.performance.monthTitle = ''
        if (titleVisibility === undefined)
          response.data.at(0).structure.performance.titleVisibility = true
        if (monthTitleVisibility === undefined)
          response.data.at(0).structure.performance.monthTitleVisibility = true
        setFormData(response.data.at(0))
        setFile(response.data.at(0).document)
      })
      .catch((error) => {
        const status = error.response.status
        customSwal.fire({
          title:
            status === 403
              ? 'No tienes permisos para realizar esta acción'
              : 'Ha ocurrido un error al cargar el archivo',
          icon: 'error',
          allowOutsideClick: false,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundId, refetch])

  const [formData, setFormData] = useStateCallback({
    codFund: fundId,
    title: '',
    structure: {
      sub_description: '',
      description: '',
      riskLevel: {
        informationTitle: '',
        visibility: true,
        riskData: 0,
        color: '#0066cc',
        name: '',
      },
      investmentStrategy: {
        visibility: true,
        strategyTitle: '',
        legalInformation: '',
        graphicTitle: '',
        data: [
          {
            name: '',
            color: '#0066cc',
            graphicValue: '',
          },
        ],
      },
      performance: {
        title: '',
        monthTitle: '',
        visibility: true,
        titleVisibility: true,
        monthTitleVisibility: true,
        description: '',
        data: [
          {
            year: (currentYear - 5).toString(),
            total: 0,
          },
          {
            year: (currentYear - 4).toString(),
            total: 0,
          },
          {
            year: (currentYear - 3).toString(),
            total: 0,
          },
          {
            year: (currentYear - 2).toString(),
            total: 0,
          },
          {
            year: (currentYear - 1).toString(),
            total: 0,
          },
        ],
        monthCards: [
          {
            year: '3M',
            total: 0,
          },
          {
            year: '6M',
            total: 0,
          },
          {
            year: '9M',
            total: 0,
          },
          {
            year: '12M',
            total: 0,
          },
          {
            year: 'YTD',
            total: 0,
          },
        ],
      },
      briefcase: {
        title: '',
        visibility: true,
        data: [
          {
            color: '#0066cc',
            graphicValue: '',
            name: '',
          },
        ],
      },
      content: {
        features: {
          title: '',
          visibility: true,
          data: [
            {
              title: '',
              description: '',
              habitat: '',
            },
          ],
        },
        commissions: {
          title: '',
          visibility: true,
          data: [
            {
              title: '',
              description: '',
              habitat: '',
            },
          ],
        },
        disclaimer: '',
        disclaimerTitle: '',
        disclaimerVisibility: true,
        document: '',
        publicity: {
          title: '',
          visibility: true,
        },
      },
      results: {
        title: '',
        data: [
          {
            total: '',
            description: '',
          },
        ],
      },
    },
    document: '',
  })

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e?: any) => {
    e && e.preventDefault()
    let stopRun = false

    if (formData.structure.description.trim() === '') {
      errorAcc['description'] = 'Descripción es requerida'
    }
    if (formData.structure.performance.description.trim() === '') {
      errorAcc['performanceDescription'] = 'Descripción es requerida'
    }
    if (formData.structure.investmentStrategy.legalInformation.trim() === '') {
      errorAcc['legalInformation'] = 'Información legal es requerida'
    }
    if (formData.structure.content.features.title.trim() === '') {
      errorAcc['featuresContentTitle'] = 'Título de características es requerido'
    }
    if (formData.structure.results.title.trim() === '') {
      errorAcc['resultTitle'] = 'Título de resultados es requerido'
    }
    if (formData.structure.content.commissions.title.trim() === '') {
      errorAcc['comissionsContentTitle'] = 'Título de comisiones es requerido'
    }
    if (formData.structure.content.disclaimerTitle.trim() === '') {
      errorAcc['disclaimerTitle'] = 'Título del disclaimer es requerido'
    }
    if (formData.structure.briefcase.title.trim() === '') {
      errorAcc['briefcaseTitle'] = 'Título del portafolio es requerido'
    }
    if (formData.structure.performance.title.trim() === '') {
      errorAcc['performanceAnualizedTitle'] = 'Título de rentabilidad anualizada es requerido'
    }
    if (formData.structure.performance.monthTitle.trim() === '') {
      errorAcc['performanceMonthTitle'] = 'Título de rentabilidad mensualizada es requerido'
    }
    if (formData.structure.investmentStrategy.strategyTitle.trim() === '') {
      errorAcc['strategyTitle'] = 'Título de estrategia de inversión es requerido'
    }
    if (formData.structure.investmentStrategy.graphicTitle.trim() === '') {
      errorAcc['graphicTitle'] = 'Título de posicionamiento de gráficos es requerido'
    }
    if (formData.structure.riskLevel.informationTitle.trim() === '') {
      errorAcc['informationTitle'] = 'Título de información y riesgo es requerido'
    }
    if (formData.structure.sub_description.trim() === '') {
      errorAcc['sub_description'] = 'Sub descripción es requerida'
    }
    if (formData.structure.content.document) {
      if (
        !formData.structure.content.document.match(
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
        )
      ) {
        errorAcc['contentDocument'] = 'Documento debe ser un enlace válido'
      }
    }

    if (formData.structure.investmentStrategy.data.map((e) => e.name).includes('')) {
      let indexes: any = []
      formData.structure.investmentStrategy.data.forEach(
        (e, index) => e.name.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['investmentTitle' + e] = 'Título es requerido'
      })
    }
    if (formData.structure.performance.data.map((e) => e.year).includes('')) {
      let indexes: any = []
      formData.structure.performance.data.forEach(
        (e, index) => e.year.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['year' + e] = 'Año es requerido'
      })
    }

    if (formData.structure.results.data) {
      if (formData.structure.results.data.map((e) => e.description).includes('')) {
        let indexes: any = []
        formData.structure.results.data.forEach(
          (e, index) => e.description.trim() === '' && indexes.push(index)
        )
        indexes.forEach((e: any) => {
          errorAcc['result-title' + e] = 'Título es requerido'
        })
      }
      if (formData.structure.results.data.map((e) => e.total).includes('')) {
        let indexes: any = []
        formData.structure.results.data.forEach(
          (e, index) => e.total.trim() === '' && indexes.push(index)
        )
        indexes.forEach((e: any) => {
          errorAcc['result-description' + e] = 'Description es requerido'
        })
      }
    } else {
      errorAcc['result-title0'] = 'Título es requerido'
      errorAcc['result-description0'] = 'Description es requerido'
    }
    if (formData.structure.performance.monthCards.map((e) => e.year).includes('')) {
      let indexes: any = []
      formData.structure.performance.monthCards.forEach(
        (e, index) => e.year.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['range-year' + e] = 'Rango es requerido'
      })
    }
    if (formData.structure.performance.monthCards.map((e) => e.total.toString()).includes('')) {
      let indexes: any = []
      formData.structure.performance.monthCards
        .map((e) => e.total.toString())
        .forEach((e, index) => e.trim() === '' && indexes.push(index))
      indexes.forEach((e: any) => {
        errorAcc['range-total-' + e] = 'Valor es requerido'
      })
    }
    if (formData.structure.investmentStrategy.data.map((e) => e.graphicValue).includes('')) {
      let indexes: any = []
      formData.structure.investmentStrategy.data.forEach(
        (e, index) => e.graphicValue.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['investmentValue' + e] = 'Valor es requerido'
      })
    }
    if (formData.structure.briefcase.data.map((e) => e.name).includes('')) {
      let indexes: any = []
      formData.structure.briefcase.data.forEach(
        (e, index) => e.name.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['briefcaseName' + e] = 'Título es requerido'
      })
    }
    if (formData.structure.briefcase.data.map((e) => e.graphicValue).includes('')) {
      let indexes: any = []
      formData.structure.briefcase.data.forEach(
        (e, index) => e.graphicValue.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['briefcaseValue' + e] = 'Valor es requerido'
      })
    }
    if (formData.structure.content.features.data.map((e) => e.title).includes('')) {
      let indexes: any = []
      formData.structure.content.features.data.forEach(
        (e, index) => e.title.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['featuresTitle' + e] = 'Título es requerido'
      })
    }
    if (formData.structure.content.features.data.map((e) => e.description).includes('')) {
      let indexes: any = []
      formData.structure.content.features.data.forEach(
        (e, index) => e.description.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['featuresValue' + e] = 'Valor es requerido'
      })
    }

    if (formData.structure.content.features.data.map((e) => e.habitat).includes('')) {
      let indexes: any = []
      formData.structure.content.features.data.forEach(
        (e, index) => e.habitat.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['featuresHabitat' + e] = 'Valor es requerido'
      })
    }
    if (formData.structure.content.commissions.data.map((e) => e.title).includes('')) {
      let indexes: any = []
      formData.structure.content.commissions.data.forEach(
        (e, index) => e.title.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['comissionsTitle' + e] = 'Título es requerido'
      })
    }
    if (formData.structure.content.commissions.data.map((e) => e.description).includes('')) {
      let indexes: any = []
      formData.structure.content.commissions.data.forEach(
        (e, index) => e.description.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['comissionsValue' + e] = 'Valor es requerido'
      })
    }
    if (formData.structure.content.commissions.data.map((e) => e.habitat).includes('')) {
      let indexes: any = []
      formData.structure.content.commissions.data.forEach(
        (e, index) => e.habitat.trim() === '' && indexes.push(index)
      )
      indexes.forEach((e: any) => {
        errorAcc['comissionsHabitat' + e] = 'Valor es requerido'
      })
    }
    let myArray: any = []

    if (Object.keys(errorAcc).length > 0) {
      stopRun = true
    }

    if (stopRun) {
      setErrors(errorAcc)
      errorAcc = {}
      return
    }

    FundService().setFundAmountInversion({
      cod_fund: fundId as string,
      fund_amount_feature: itemCaracter.filter((e) =>
        featuresDisabled.includes(e.title as FundAmountEnum)
      ) as IFundAmountFeature[],
    })

    setErrors({})
    apiUrl
      .put(`/fund/register-funds-structure/${fundId}`, formData.structure)
      .then(() =>
        customSwal.fire({
          title: 'Se ha guardado correctamente la información ',
          icon: 'success',
        })
      )
      .catch((error) => {
        const errorStatus = error.response.status
        const textToShow =
          errorStatus === 403
            ? 'No tienes permisos para realizar esta acción'
            : 'Ha ocurrido un error al guardar la información intente nuevamente'
        customSwal.fire({
          title: textToShow,
          icon: 'error',
        })
      })
    // apiUrl
    //   .post('/app/customer/register-funds', formData)
    // Aquí puedes realizar alguna acción con los datos del formulario
  }

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = (e: any) => {
    e.preventDefault()
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = (e: any) => {
    e.preventDefault()
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }

  function addItem(e: any) {
    e.preventDefault()
    setItems([...items, newItems])
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        investmentStrategy: {
          ...formData.structure.investmentStrategy,
          data: [...formData.structure.investmentStrategy.data, newItems],
        },
      },
    })
  }
  function addCaracther(e: any) {
    e.preventDefault()
    setItemCaracter([...itemCaracter, ContentValue])
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        content: {
          ...formData.structure.content,
          features: {
            ...formData.structure.content.features,
            data: [...formData.structure.content.features.data, ContentValue],
          },
        },
      },
    })
  }
  function addComission(e: any) {
    e.preventDefault()
    setItemComission([...itemComission, ContentValue])
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        content: {
          ...formData.structure.content,
          commissions: {
            ...formData.structure.content.commissions,
            data: [...formData.structure.content.commissions.data, ContentValue],
          },
        },
      },
    })
  }

  function removeItem(e: any, index: number) {
    e.preventDefault()
    const newItems = [...items]
    newItems.splice(index, 1)
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        investmentStrategy: {
          ...formData.structure.investmentStrategy,
          data: newItems,
        },
      },
    })
    setItems(newItems)
  }
  function removeItemCaracters(e: any, index: number) {
    e.preventDefault()
    const newItems = [...itemCaracter]
    newItems.splice(index, 1)
    setItemCaracter(newItems)
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        content: {
          ...formData.structure.content,
          features: {
            ...formData.structure.content.features,
            data: newItems,
          },
        },
      },
    })
  }
  function removeItemComission(e: any, index: number) {
    e.preventDefault()
    const newItems = [...itemComission]
    newItems.splice(index, 1)
    setItemComission(newItems)
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        content: {
          ...formData.structure.content,
          commissions: {
            ...formData.structure.content.commissions,
            data: newItems,
          },
        },
      },
    })
  }

  function handleTextInputChange(index: number, value: any, setData: any, data: any, key: string) {
    const newItems = [...data]
    newItems[index].name = value
    setData(newItems)
  }

  function handleColorInputChange(index: number, value: any) {
    const newItems = [...items]
    newItems[index].color = value

    setItems(newItems)
  }
  function handleNumberValue(index: number, value: any, key: string) {
    const newItems = [...items]
    newItems[index].graphicValue = value
    setItems(newItems)
  }

  function handleHidePublicity(value: boolean) {
    setFormData({
      ...formData,
      structure: {
        ...formData.structure,
        content: {
          ...formData.structure.content,
          publicity: {
            ...formData.structure.content.publicity,
            visibility: !value,
          },
        },
      },
    })
  }

  return (
    <form>
      <Box sx={{ width: '100%' }}>
        <Stepper
          nonLinear
          className='d-flex flex-grow flex-wrap gap-5 mt-5'
          activeStep={activeStep}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re Finalizado
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Restablecer</Button>
              </Box>
            </React.Fragment>
          ) : (
            <div>
              <React.Fragment>
                <div>
                  {activeStep === 0 ? (
                    <div>
                      <div className='card mt-10 position-relative p-5'>
                        <span className='card-title text-center mt-2 mb-5  text-gray-800 fs-2'>
                          Información y riesgo
                        </span>

                        <div className='text-end updateConfigFund'>
                          <button
                            style={{ background: 'rgb(0, 123, 195)' }}
                            className='btn text-white cursor-pointer'
                            onClick={handleSubmit}
                            type='submit'
                          >
                            Actualizar
                          </button>
                        </div>
                      </div>
                      <div className='card mt-10 p-10'>
                        <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
                        <textarea
                          className='form-control'
                          name='description'
                          style={{
                            height: '50px',
                            resize: 'none',
                          }}
                          required
                          value={formData.structure.riskLevel.informationTitle}
                          onChange={(e) => {
                            setFormData(
                              {
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  riskLevel: {
                                    ...formData.structure.riskLevel,
                                    informationTitle: e.target.value,
                                  },
                                },
                              },
                              (bro) => {
                                
                              }
                            )
                          }}
                          placeholder='Título'
                        />
                        {errors['informationTitle'] && (
                          <span className='text-danger' role='alert'>
                            {errors['informationTitle']}
                          </span>
                        )}
                      </div>
                      <div className='card mt-10 p-10'>
                        <div className='mb-10'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Nombre</label>
                          <input
                            type='text'
                            name='title'
                            disabled
                            required
                            value={formData.title}
                            onChange={handleChange}
                            className='form-control'
                            placeholder='Titulo'
                          />
                        </div>
                        <div className='mb-10'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Descripción</label>
                          <textarea
                            className='form-control'
                            name='description'
                            style={{
                              height: '100px',
                              resize: 'none',
                            }}
                            required
                            value={formData.structure.description}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  description: e.target.value,
                                },
                              })
                            }
                            placeholder='Descripción'
                          />

                          {errors['description'] && (
                            <span className='text-danger' role='alert'>
                              {errors['description']}
                            </span>
                          )}
                        </div>
                        <div className='mb-10'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>
                            Sub descripción
                          </label>
                          <textarea
                            className='form-control'
                            name='sub_description'
                            style={{
                              height: '60px',
                              resize: 'none',
                            }}
                            required
                            value={formData.structure.sub_description}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  sub_description: e.target.value,
                                },
                              })
                            }
                            placeholder='Sub descripción'
                          />
                          {errors['sub_description'] && (
                            <span className='text-danger' role='alert'>
                              {errors['sub_description']}
                            </span>
                          )}
                        </div>
                        <div className='mb-10'>
                          <div className='row'>
                            <div className='col-lg-11 text-start'>
                              <label className='fw-bolder fs-5 text-gray-700 mb-3 text-start col'>
                                Nivel de riesgo
                              </label>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-4'>
                              <select
                                className='form-control'
                                value={formData.structure.riskLevel.riskData}
                                disabled={!formData.structure.riskLevel.visibility}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    structure: {
                                      ...formData.structure,
                                      riskLevel: {
                                        ...formData.structure.riskLevel,
                                        riskData: Number(e.target.value),
                                        name:
                                          'Riesgo ' +
                                          (e.target.value === '1'
                                            ? 'Bajo'
                                            : e.target.value === '2'
                                            ? 'Medio'
                                            : 'Alto'),
                                      },
                                    },
                                  })
                                }
                                name='riskData'
                              >
                                <option value='1'>Bajo</option>
                                <option value='2'>Medio</option>
                                <option value='3'>Alto</option>
                              </select>
                            </div>
                            <div className='col-lg-2'>
                              <input
                                type='color'
                                style={{ height: '44px' }}
                                className='form-control'
                                value={formData.structure.riskLevel.color}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    structure: {
                                      ...formData.structure,
                                      riskLevel: {
                                        ...formData.structure.riskLevel,
                                        color: e.target.value,
                                      },
                                    },
                                  })
                                }
                                disabled={!formData.structure.riskLevel.visibility}
                              />
                            </div>
                            <div className='col-lg-6 order-12 gap-3 justify-content-end align-items-center d-flex text-end'>
                              <label>Mostrar nivel de riesgo</label>
                              <div className='form-check form-switch text-end '>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='flexSwitchCheckDefault'
                                  checked={formData.structure.riskLevel.visibility}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      structure: {
                                        ...formData.structure,
                                        riskLevel: {
                                          ...formData.structure.riskLevel,
                                          visibility: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeStep === 1 ? (
                    <div>
                      <div className='card mt-10 p-5'>
                        <span className='card-title text-center mt-2 mb-5 text-gray-800 fs-2'>
                          Estrategia de inversión
                        </span>
                        <div className='text-end updateConfigFund'>
                          <button
                            id=''
                            style={{ background: 'rgb(0, 123, 195)' }}
                            className=' btn text-white cursor-pointer'
                            onClick={handleSubmit}
                            type='submit'
                          >
                            Actualizar
                          </button>
                        </div>
                        <div className='col showInfoFund'>
                          <div className='form-check form-switch d-flex align-items-center gap-4'>
                            <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
                              Mostrar información
                            </label>

                            <div className='form-check form-switch text-end '>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                id='flexSwitchCheckDefault'
                                checked={formData.structure.investmentStrategy.visibility}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    structure: {
                                      ...formData.structure,
                                      investmentStrategy: {
                                        ...formData.structure.investmentStrategy,
                                        visibility: e.target.checked,
                                      },
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                          {/* <SwitchVisibility formData={formData} setFormData={setFormData} /> */}
                        </div>
                      </div>
                      <div className='card mt-10 p-10'>
                        <label className='fw-bolder fs-5 text-gray-700 mb-3'>
                          Título de gráficos
                        </label>
                        <textarea
                          className='form-control'
                          name='descriptions'
                          style={{
                            height: '50px',
                            resize: 'none',
                          }}
                          required
                          value={formData.structure.investmentStrategy.graphicTitle}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              structure: {
                                ...formData.structure,
                                investmentStrategy: {
                                  ...formData.structure.investmentStrategy,
                                  graphicTitle: e.target.value,
                                },
                              },
                            })
                          }
                          placeholder='Título'
                        />
                        {errors['graphicTitle'] && (
                          <span className='text-danger' role='alert'>
                            {errors['graphicTitle']}
                          </span>
                        )}
                      </div>
                      <div className='card mt-10 p-10'>
                        <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
                        <textarea
                          className='form-control'
                          name='description'
                          style={{
                            height: '50px',
                            resize: 'none',
                          }}
                          required
                          value={formData.structure.investmentStrategy.strategyTitle}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              structure: {
                                ...formData.structure,
                                investmentStrategy: {
                                  ...formData.structure.investmentStrategy,
                                  strategyTitle: e.target.value,
                                },
                              },
                            })
                          }
                          placeholder='Título'
                        />
                        {errors['strategyTitle'] && (
                          <span className='text-danger' role='alert'>
                            {errors['strategyTitle']}
                          </span>
                        )}
                      </div>
                      <div className='card mt-10 p-10'>
                        <div className='w-full mb-4 d-flex justify-content-between align-items-center'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>
                            Información del gráfico
                          </label>
                          <button
                            style={{
                              color: 'white',
                              marginRight: '2px',
                              background: 'rgb(0, 123, 195)',
                            }}
                            className='btn mr-3 '
                            type='button'
                            onClick={(e) => addItem(e)}
                            disabled={!formData.structure.investmentStrategy.visibility}
                          >
                            <i style={{ color: 'white' }} className='bi bi-plus mr-2 fs-1'></i>
                            <span>Agregar campo</span>
                          </button>
                        </div>

                        <table className='table table-row-dashed table-row-gray-300 gy-7'>
                          <thead>
                            <tr className='fw-bolder fs-6 text-gray-700'>
                              <th></th>
                              <th>TÍTULO</th>
                              <th>VALOR</th>
                              <th>COLOR</th>
                              <th className='text-center'>ACCIONES</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <div className='mt-5'>{index + 1}</div>
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Ingrese título'
                                      type='text'
                                      value={item.name}
                                      onChange={(e) =>
                                        handleTextInputChange(
                                          index,
                                          e.target.value,
                                          setItems,
                                          items,
                                          'title'
                                        )
                                      }
                                      disabled={!formData.structure.investmentStrategy.visibility}
                                    />
                                    {errors['investmentTitle' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['investmentTitle' + index]}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    {' '}
                                    <input
                                      type='number'
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Valor'
                                      value={item.graphicValue}
                                      onChange={(e) =>
                                        handleNumberValue(index, e.target.value, 'value')
                                      }
                                      disabled={!formData.structure.investmentStrategy.visibility}
                                    />
                                    {errors['investmentValue' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['investmentValue' + index]}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    {' '}
                                    <input
                                      type='color'
                                      style={{ height: '44px' }}
                                      className='form-control'
                                      value={item.color}
                                      onChange={(e) =>
                                        handleColorInputChange(index, e.target.value)
                                      }
                                      disabled={!formData.structure.investmentStrategy.visibility}
                                    />
                                  </td>
                                  <td className='text-center'>
                                    <button
                                      disabled={!formData.structure.investmentStrategy.visibility}
                                      style={{
                                        background: 'transparent',
                                        color: 'white',
                                        border: '1px solid #ccc',
                                      }}
                                      className='btn btn-icon ml-3'
                                      onClick={(e) => removeItem(e, index)}
                                    >
                                      {' '}
                                      <i className='bi bi-trash text-muted fs-3'></i>
                                    </button>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <div className='text-end'>
                          {items.length === 0 ? (
                            <button
                              disabled={!formData.structure.investmentStrategy.visibility}
                              style={{ background: '#0066cc', color: 'white', marginRight: '2px' }}
                              className='btn  mr-3 '
                              onClick={addItem}
                            >
                              {' '}
                              <i style={{ color: 'white' }} className='bi bi-plus fs-1'></i> Agregar
                              fila
                            </button>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='mb-10'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Legales</label>
                          <textarea
                            disabled={!formData.structure.investmentStrategy.visibility}
                            className='form-control'
                            placeholder='Legales'
                            value={formData.structure.investmentStrategy.legalInformation}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  investmentStrategy: {
                                    ...formData.structure.investmentStrategy,
                                    legalInformation: e.target.value,
                                  },
                                },
                              })
                            }
                          />
                          {errors['legalInformation'] && (
                            <span className='text-danger' role='alert'>
                              {errors['legalInformation']}
                            </span>
                          )}
                        </div>

                        {/* <button onClick={handleSubmit}>
                        guardar</button> */}
                      </div>
                    </div>
                  ) : activeStep === 2 ? (
                    <div>
                      <TableResults
                        onPerformanceData={handlePerformanceData}
                        handleSubmit={handleSubmit}
                        setFormData={setFormData}
                        formData={formData}
                        errors={errors}
                        title='Resultados de fondo mutuo'
                      />
                    </div>
                  ) : activeStep === 3 ? (
                    <div>
                      <TableBenchmark
                        onPerformanceData={handlePerformanceData}
                        handleSubmit={handleSubmit}
                        setFormData={setFormData}
                        formData={formData}
                        errors={errors}
                        title='Desempeño'
                      />
                    </div>
                  ) : activeStep === 4 ? (
                    <div>
                      <React.Fragment>
                        <div className='card mt-10 p-5'>
                          <span className='card-title text-center mt-2 mb-5  text-gray-800 fs-2'>
                            Portafolio
                          </span>
                          <div className='text-end updateConfigFund'>
                            <button
                              style={{ background: 'rgb(0, 123, 195)' }}
                              className=' btn text-white cursor-pointer'
                              onClick={handleSubmit}
                              type='submit'
                            >
                              Actualizar
                            </button>
                          </div>
                          <div className='col showInfoFund'>
                            <div className='form-check form-switch d-flex align-items-center gap-4'>
                              <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
                                Mostrar información
                              </label>

                              <div className='form-check form-switch text-end '>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='flexSwitchCheckDefault'
                                  checked={formData.structure.briefcase.visibility}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      structure: {
                                        ...formData.structure,
                                        briefcase: {
                                          ...formData.structure.briefcase,
                                          visibility: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card mt-10 p-10'>
                          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
                          <textarea
                            className='form-control'
                            name='description'
                            style={{
                              height: '50px',
                              resize: 'none',
                            }}
                            required
                            value={formData.structure.briefcase.title}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  briefcase: {
                                    ...formData.structure.briefcase,
                                    title: e.target.value,
                                  },
                                },
                              })
                            }
                            placeholder='Título'
                          />
                          {errors['briefcaseTitle'] && (
                            <span className='text-danger' role='alert'>
                              {errors['briefcaseTitle']}
                            </span>
                          )}
                        </div>

                        <div className='card mt-10 p-10'>
                          <div className='w-full mb-4 d-flex justify-content-between align-items-center'>
                            <label className='fw-bolder fs-5 text-gray-700 mb-3'>
                              Información del gráfico
                            </label>
                            <button
                              style={{
                                color: 'white',
                                marginRight: '2px',
                                background: 'rgb(0, 123, 195)',
                              }}
                              className='btn mr-3 '
                              onClick={addItemBrief}
                              disabled={!formData.structure.briefcase.visibility}
                            >
                              <i style={{ color: 'white' }} className='bi bi-plus mr-2 fs-1'></i>
                              <span>Agregar campo</span>
                            </button>
                          </div>
                          <table className='table table-row-dashed table-row-gray-300 gy-7'>
                            <thead>
                              <tr className='fw-bolder fs-6 text-gray-700'>
                                <th></th>
                                <th>TÍTULO</th>
                                <th>VALOR</th>
                                <th>COLOR</th>
                                <th className='text-center'>ACCIONES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {briefcase.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className='mt-5'>{index + 1}</div>
                                    </td>
                                    <td>
                                      {' '}
                                      <input
                                        className='form-control mb-2 mb-md-0'
                                        placeholder='Ingrese título'
                                        type='text'
                                        value={item.name}
                                        onChange={(e) =>
                                          handleTextInputChangeBrief(index, e.target.value)
                                        }
                                        disabled={!formData.structure.briefcase.visibility}
                                      />
                                      {errors['briefcaseName' + index] && (
                                        <span className='text-danger' role='alert'>
                                          {errors['briefcaseName' + index]}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        type='number'
                                        className='form-control mb-2 mb-md-0'
                                        placeholder='Valor'
                                        value={item.graphicValue}
                                        onChange={(e) =>
                                          handleNumberValueBrief(index, e.target.value)
                                        }
                                        disabled={!formData.structure.briefcase.visibility}
                                      />
                                      {errors['briefcaseValue' + index] && (
                                        <span className='text-danger' role='alert'>
                                          {errors['briefcaseValue' + index]}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      <input
                                        type='color'
                                        style={{ height: '44px' }}
                                        className='form-control'
                                        value={item.color}
                                        onChange={(e) =>
                                          handleColorInputChangeBrief(index, e.target.value)
                                        }
                                        disabled={!formData.structure.briefcase.visibility}
                                      />
                                    </td>
                                    <td className='text-center'>
                                      <button
                                        disabled={!formData.structure.briefcase.visibility}
                                        style={{
                                          background: 'transparent',
                                          color: 'white',
                                          border: '1px solid #ccc',
                                        }}
                                        className='btn btn-icon ml-3'
                                        onClick={(e) => removeItemBrief(index, e)}
                                      >
                                        {' '}
                                        <i className='bi bi-trash text-muted fs-3'></i>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                          <div className='text-end'>
                            {briefcase.length === 0 ? (
                              <button
                                style={{
                                  background: '#0066cc',
                                  color: 'white',
                                  marginRight: '2px',
                                }}
                                className='btn  mr-3 '
                                onClick={addItemBrief}
                              >
                                {' '}
                                <i style={{ color: 'white' }} className='bi bi-plus fs-1'></i>{' '}
                                Agregar fila
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                      {/* <TableFundsItems
                          title='Portafolio'
                          /> */}
                    </div>
                  ) : (
                    activeStep === 5 && (
                      <div>
                        <div className='card mt-10 p-5'>
                          <span className='card-title text-center mt-2 mb-5  text-gray-800 fs-2'>
                            Contenido
                          </span>
                          <div className='text-end updateConfigFund'>
                            <button
                              id=''
                              style={{ background: 'rgb(0, 123, 195)' }}
                              className='btn text-white cursor-pointer'
                              onClick={handleSubmit}
                              type='submit'
                            >
                              Actualizar
                            </button>
                          </div>
                        </div>
                        <div className='card  mt-10 p-10 pb-5'>
                          <div className='d-flex align-items-center flex-md-row flex-column  justify-content-between'>
                            <span className='card-title text-start text-gray-800 fs-2'>
                              Características
                            </span>

                            <div className='d-flex flex-row gap-5 align-items-center'>
                              <div className='form-check form-switch'>
                                <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                                  Mostrar información
                                </label>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='flexSwitchCheckDefault'
                                  checked={formData.structure.content.features.visibility}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      structure: {
                                        ...formData.structure,
                                        content: {
                                          ...formData.structure.content,
                                          features: {
                                            ...formData.structure.content.features,
                                            visibility: e.target.checked,
                                          },
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                              <button
                                style={{
                                  color: 'white',
                                  marginRight: '2px',
                                  background: 'rgb(0, 123, 195)',
                                }}
                                className='btn mr-3 '
                                onClick={(e) => addCaracther(e)}
                                disabled={!formData.structure.content.features.visibility}
                              >
                                <i style={{ color: 'white' }} className='bi bi-plus mr-2 fs-1'></i>
                                <span>Agregar campo</span>
                              </button>
                            </div>
                          </div>
                          <div className='mt-5 mb-3'>
                            <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                              TÍTULO
                            </label>
                            <input
                              className='form-control mb-2 mb-md-0'
                              placeholder='Título'
                              type='text'
                              value={formData.structure.content.features.title}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  structure: {
                                    ...formData.structure,
                                    content: {
                                      ...formData.structure.content,
                                      features: {
                                        ...formData.structure.content.features,
                                        title: e.target.value,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                            {errors['featuresContentTitle'] && (
                              <span className='text-danger' role='alert'>
                                {errors['featuresContentTitle']}
                              </span>
                            )}
                          </div>
                          <table className='table table-row-dashed table-row-gray-300 gy-7'>
                            <thead>
                              <tr className='fw-bolder fs-6 text-gray-700'>
                                <th></th>
                                <th>NOMBRE</th>
                                <th>PUBLICO GENERAL</th>
                                <th>GRUPO HABITAT</th>
                                <th className='text-center'>ACCIONES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemCaracter.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='mt-5'>{index + 1}</div>
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Nombre'
                                      type='text'
                                      value={item.title}
                                      onChange={(e) =>
                                        handleTitleInputCaracter(index, e.target.value)
                                      }
                                      disabled={
                                        featuresDisabled.includes(item.title as FundAmountEnum) ??
                                        !formData.structure.content.features.visibility
                                      }
                                    />
                                    {errors['featuresTitle' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['featuresTitle' + index]}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    {' '}
                                    <input
                                      type='text'
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Descripción '
                                      value={item.description}
                                      onChange={(e) => {
                                        const transformed = e.target.value
                                        // if (featuresDisabled.includes(item.title as FundAmountEnum)) {
                                        //   // regex for this format: US$ 5,000.00 or S/. 5,000.00 or S/. 5,000 or US$ 5.00 it can have as many zeros
                                        //   if (
                                        //     !e.target.value.match(
                                        //       /^([A-Z]{2}\$|S\/\.)\s\d{1,3}(,\d{3})*(\.\d{2})?$/
                                        //     )
                                        //   ) {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresValue' + index]:
                                        //         'Formato incorrecto: US$ 5,000.00',
                                        //     })
                                        //   } else {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresValue' + index]: '',
                                        //     })
                                        //   }
                                        // }
                                        // if (item.title === FundAmountEnum.MIN_TIME_PERMANENCE) {
                                        //   // regex for text
                                        //   if (
                                        //     !e.target.value.match(/^[a-zA-Z\s]*\(\d+\)[a-zA-Z\s]*/)
                                        //   ) {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresValue' + index]:
                                        //         'Formato incorrecto: Incluir días entre paréntesis',
                                        //     })
                                        //   } else {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresValue' + index]: '',
                                        //     })
                                        //   }
                                        // }
                                        handledescriptionInputChangeCaracter(index, transformed)
                                      }}
                                      disabled={!formData.structure.content.features.visibility}
                                    />
                                    {errors['featuresValue' + index] && (
                                      <span className='text-danger position-absolute' role='alert'>
                                        {errors['featuresValue' + index]}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type='text'
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Descripción '
                                      value={item.habitat}
                                      onChange={(e) => {
                                        const transformed = e.target.value
                                        // if (
                                        //   featuresDisabled.includes(item.title as FundAmountEnum) &&
                                        //   item.title !== FundAmountEnum.MIN_TIME_PERMANENCE
                                        // ) {
                                        //   if (
                                        //     !e.target.value.match(
                                        //       /^([A-Z]{2}\$|S\/\.)\s\d{1,3}(,\d{3})*(\.\d{2})?$/
                                        //     )
                                        //   ) {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresHabitat' + index]:
                                        //         'Formato incorrecto: US$ 5,000.00',
                                        //     })
                                        //   } else {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresHabitat' + index]: '',
                                        //     })
                                        //   }
                                        // }
                                        // if (item.title === FundAmountEnum.MIN_TIME_PERMANENCE) {
                                        //   // regex for text
                                        //   if (
                                        //     !e.target.value.match(/^[a-zA-Z\s]*\(\d+\)[a-zA-Z\s]*/)
                                        //   ) {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresHabitat' + index]:
                                        //         'Formato incorrecto: Incluir días entre paréntesis',
                                        //     })
                                        //   } else {
                                        //     setErrors({
                                        //       ...errors,
                                        //       ['featuresHabitat' + index]: '',
                                        //     })
                                        //   }
                                        // }

                                        handleHabitatInputChangeCaracter(index, transformed)
                                      }}
                                      disabled={!formData.structure.content.features.visibility}
                                    />
                                    {errors['featuresHabitat' + index] && (
                                      <span className='text-danger position-absolute' role='alert'>
                                        {errors['featuresHabitat' + index]}
                                      </span>
                                    )}
                                  </td>

                                  <td className='text-center'>
                                    <button
                                      disabled={!formData.structure.content.features.visibility}
                                      style={{
                                        background: 'transparent',
                                        color: 'white',
                                        border: '1px solid #ccc',
                                      }}
                                      className='btn btn-icon ml-3'
                                      onClick={(e) => removeItemCaracters(e, index)}
                                    >
                                      {' '}
                                      <i className='bi bi-trash text-muted fs-3'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className='text-end'>
                            {itemCaracter.length === 0 ? (
                              <button
                                disabled={!formData.structure.content.features.visibility}
                                style={{
                                  background: '#0066cc',
                                  color: 'white',
                                  marginRight: '2px',
                                }}
                                className='btn  mr-3 '
                                onClick={addCaracther}
                              >
                                <i style={{ color: 'white' }} className='bi bi-plus fs-1'></i>{' '}
                                Agregar fila
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <div className='card  mt-10 p-10 pb-5'>
                          <span className='card-title text-start mt-2 mb-5 text-gray-800 fs-2'>
                            Publicidad
                          </span>
                          <div className='mt-5 mb-3'>
                            <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                              TÍTULO
                            </label>
                            <input
                              className='form-control mb-2 mb-md-0'
                              placeholder='Título'
                              type='text'
                              value={formData.structure.content.publicity.title}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  structure: {
                                    ...formData.structure,
                                    content: {
                                      ...formData.structure.content,
                                      publicity: {
                                        ...formData.structure.content.publicity,
                                        title: e.target.value,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                            {errors['comissionsContentTitle'] && (
                              <span className='text-danger' role='alert'>
                                {errors['comissionsContentTitle']}
                              </span>
                            )}
                          </div>
                          <UploadFilesPub
                            codFund={fundId!}
                            handleHidePublicity={handleHidePublicity}
                            value={formData.structure.content.publicity.visibility}
                          />
                        </div>
                        <div className='card mt-10 p-10 pb-5'>
                          <div className='d-flex align-items-center flex-md-row flex-column justify-content-between'>
                            <span className='card-title text-start mt-2 mb-5 text-gray-800 fs-2'>
                              Comisiones
                            </span>
                            <div className='d-flex flex-row gap-5 align-items-center'>
                              <div className='form-check form-switch '>
                                <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                                  Mostrar información
                                </label>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='flexSwitchCheckDefault'
                                  checked={formData.structure.content.commissions.visibility}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      structure: {
                                        ...formData.structure,
                                        content: {
                                          ...formData.structure.content,
                                          commissions: {
                                            ...formData.structure.content.commissions,
                                            visibility: e.target.checked,
                                          },
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                              <button
                                style={{
                                  color: 'white',
                                  marginRight: '2px',
                                  background: 'rgb(0, 123, 195)',
                                }}
                                className='btn mr-3 '
                                onClick={(e) => addComission(e)}
                                disabled={!formData.structure.content.commissions.visibility}
                              >
                                <i style={{ color: 'white' }} className='bi bi-plus mr-2 fs-1'></i>
                                <span>Agregar campo</span>
                              </button>
                            </div>
                          </div>
                          <div className='mt-5 mb-3'>
                            <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                              TÍTULO
                            </label>
                            <input
                              className='form-control mb-2 mb-md-0'
                              placeholder='Título'
                              type='text'
                              value={formData.structure.content.commissions.title}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  structure: {
                                    ...formData.structure,
                                    content: {
                                      ...formData.structure.content,
                                      commissions: {
                                        ...formData.structure.content.commissions,
                                        title: e.target.value,
                                      },
                                    },
                                  },
                                })
                              }
                            />
                            {errors['comissionsContentTitle'] && (
                              <span className='text-danger' role='alert'>
                                {errors['comissionsContentTitle']}
                              </span>
                            )}
                          </div>
                          <table className='table table-row-dashed table-row-gray-300 gy-7'>
                            <thead>
                              <tr className='fw-bolder fs-6 text-gray-700'>
                                <th></th>
                                <th>NOMBRE</th>
                                <th>PUBLICO GENERAL</th>
                                <th>GRUPO HABITAT</th>
                                <th className='text-center'>ACCIONES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {itemComission.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='mt-5'>{index + 1}</div>
                                  </td>
                                  <td>
                                    {' '}
                                    <input
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Nombre'
                                      type='text'
                                      value={item.title}
                                      onChange={(e) => titleComission(index, e.target.value)}
                                      disabled={!formData.structure.content.commissions.visibility}
                                    />
                                    {errors['comissionsTitle' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['comissionsTitle' + index]}
                                      </span>
                                    )}
                                  </td>

                                  <td>
                                    {' '}
                                    <input
                                      type='text'
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Descripción '
                                      value={item.description}
                                      onChange={(e) => changeDescription(index, e.target.value)}
                                      disabled={!formData.structure.content.commissions.visibility}
                                    />
                                    {errors['comissionsValue' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['comissionsValue' + index]}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type='text'
                                      className='form-control mb-2 mb-md-0'
                                      placeholder='Descripción '
                                      value={item.habitat}
                                      onChange={(e) => changeHabitat(index, e.target.value)}
                                      disabled={!formData.structure.content.commissions.visibility}
                                    />
                                    {errors['comissionsHabitat' + index] && (
                                      <span className='text-danger' role='alert'>
                                        {errors['comissionsHabitat' + index]}
                                      </span>
                                    )}
                                  </td>
                                  <td className='text-center'>
                                    <button
                                      disabled={!formData.structure.content.commissions.visibility}
                                      style={{
                                        background: 'transparent',
                                        color: 'white',
                                        border: '1px solid #ccc',
                                      }}
                                      className='btn btn-icon ml-3'
                                      onClick={(e) => removeItemComission(e, index)}
                                    >
                                      {' '}
                                      <i className='bi bi-trash text-muted fs-3'></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className='text-end'>
                            {itemComission.length === 0 ? (
                              <button
                                disabled={!formData.structure.content.commissions.visibility}
                                style={{
                                  background: '#0066cc',
                                  color: 'white',
                                  marginRight: '2px',
                                }}
                                className='btn  mr-3 '
                                onClick={addComission}
                              >
                                {' '}
                                <i style={{ color: 'white' }} className='bi bi-plus fs-1'></i>{' '}
                                Agregar fila
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div className='card mt-10 p-10'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='card-title text-start mt-2 mb-5 text-gray-800 fs-2'>
                              Documento
                            </span>
                          </div>
                          <textarea
                            className='form-control'
                            placeholder='Ingrese el documento'
                            style={{ height: '50px', resize: 'none' }}
                            value={formData.structure.content.document}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  content: {
                                    ...formData.structure.content,
                                    document: e.target.value,
                                  },
                                },
                              })
                            }
                          ></textarea>
                          {errors['contentDocument'] && (
                            <span className='text-danger' role='alert'>
                              {errors['contentDocument']}
                            </span>
                          )}
                        </div>
                        <UploadFiles codFund={fundId!} />
                        <div className='card mt-10 p-10'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='card-title text-start mt-2 mb-5 text-gray-800 fs-2'>
                              Disclaimer
                            </span>
                            <div className='form-check form-switch d-flex align-items-center gap-4'>
                              <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
                                Mostrar información
                              </label>

                              <div className='form-check form-switch text-end '>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  role='switch'
                                  id='flexSwitchCheckDefault'
                                  checked={formData.structure.content.disclaimerVisibility}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      structure: {
                                        ...formData.structure,
                                        content: {
                                          ...formData.structure.content,
                                          disclaimerVisibility: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className='mt-5 mb-3'>
                            <label htmlFor='Diabled' className='fw-bolder fs-7 text-gray-700'>
                              TÍTULO
                            </label>
                            <input
                              className='form-control mb-2 mb-md-0'
                              placeholder='Título'
                              type='text'
                              value={formData.structure.content.disclaimerTitle}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  structure: {
                                    ...formData.structure,
                                    content: {
                                      ...formData.structure.content,
                                      disclaimerTitle: e.target.value,
                                    },
                                  },
                                })
                              }
                            />
                            {errors['disclaimerTitle'] && (
                              <span className='text-danger' role='alert'>
                                {errors['disclaimerTitle']}
                              </span>
                            )}
                          </div>
                          <textarea
                            className='form-control'
                            placeholder='Ingrese el disclaimer'
                            style={{ height: '100px', resize: 'none' }}
                            value={formData.structure.content.disclaimer}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                structure: {
                                  ...formData.structure,
                                  content: {
                                    ...formData.structure.content,
                                    disclaimer: e.target.value,
                                  },
                                },
                              })
                            }
                          ></textarea>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={(e) => handleBack(e)}
                    sx={{ mr: 1 }}
                    type='submit'
                  >
                    Anterior
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep !== 5 && (
                    <Button onClick={(e) => handleNext(e)} sx={{ mr: 1 }} type='submit'>
                      Siguiente
                    </Button>
                  )}
                  {activeStep === 5 ? (
                    <Button
                      sx={{ mr: 1 }}
                      onClick={handleSubmit}
                      style={{ background: 'rgb(0, 123, 195)' }}
                      className='btn text-white cursor-pointer'
                      type='submit'
                    >
                      Guardar
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </React.Fragment>
            </div>
          )}
        </div>
      </Box>
    </form>
  )
}
