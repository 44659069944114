import { useFormik } from 'formik'
import { FC, useEffect } from 'react'
import * as Yup from 'yup'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useClients } from '../../modules/clients/hooks/useClients'
import { useStateCallback } from '../hooks/useStateCallback'

const upsertPasswordSchema = Yup.object().shape({
  new_password: Yup.string().trim().required('Nueva contraseña es requerida.'),

  confirmation_password: Yup.string()
    .trim()
    .required('Confirmar contraseña es requerida.')
    .oneOf([Yup.ref('new_password')], 'Ambas contraseñas deben coincidir.'),
})

interface IProps {
  selectedUser: any
  setChangedProspect: any
  setSelectedUser: any
  prospect?: boolean
}

export const UpdatePasswordModal: FC<IProps> = ({
  selectedUser,
  setChangedProspect,
  setSelectedUser,
  prospect,
}) => {
  const [passwordValidations, setPasswordValidations] = useStateCallback({
    hasLength: false,
    hasDigit: false,
    hasLowercase: false,
    hasUppercase: false,
    hasSymbol: false,
  })

  const {
    mutations: { upsertPasswordMutation },
  } = useClients()

  const upsertPasswordForm = useFormik({
    initialValues: { new_password: '', confirmation_password: '' },
    validationSchema: upsertPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      if (Object.values(passwordValidations).every((validation) => !!validation)) {
        upsertPasswordMutation.mutate({ ...values, customer_id: selectedUser.id })
        setChangedProspect((prevState: number[]) => [...prevState, selectedUser.id])
        resetForm()
        $('#UpdatePassword').modal('toggle')
        setSelectedUser(null)
      }
    },
  })

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault() // Prevent input of space character
    }
  }

  useEffect(() => {
    const { new_password } = upsertPasswordForm.values
    setPasswordValidations({
      hasLength: new_password.length >= 8,
      hasDigit: /[0-9]/.test(new_password),
      hasLowercase: /[a-z]/.test(new_password),
      hasUppercase: /[A-Z]/.test(new_password),
      hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(new_password),
    })
  }, [setPasswordValidations, upsertPasswordForm.values, upsertPasswordForm.values.new_password])

  return (
    <div className='modal fade p-5 rounded-4' tabIndex={-1} id='UpdatePassword' role='dialog'>
      <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <form onSubmit={upsertPasswordForm.handleSubmit}>
            <div className='modal-header py-5 mt-2 fw-bolder'>
              <h1 className='text-alert'>{prospect ? 'Generar' : 'Actualizar'} Contraseña</h1>
            </div>

            <div className='modal-body'>
              <div className='text-alert fs-6 mb-8'>
                <label>Crea una contraseña que cumpla con los siguientes criterios:</label>
                <div className='mt-5'>
                  <>
                    <div className='d-flex align-items-center'>
                      {passwordValidations.hasLength ? (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/checked.svg')} alt='logo' />
                      ) : (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/unchecked.svg')} alt='logo' />
                      )}
                      <span style={{ marginLeft: 8 }}>Mínimo 8 caracteres</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      {passwordValidations.hasUppercase ? (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/checked.svg')} alt='logo' />
                      ) : (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/unchecked.svg')} alt='logo' />
                      )}
                      <span style={{ marginLeft: 8 }}>Al menos una mayúscula</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      {passwordValidations.hasLowercase ? (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/checked.svg')} alt='logo' />
                      ) : (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/unchecked.svg')} alt='logo' />
                      )}
                      <span style={{ marginLeft: 8 }}>Al menos una minúscula</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      {passwordValidations.hasDigit ? (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/checked.svg')} alt='logo' />
                      ) : (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/unchecked.svg')} alt='logo' />
                      )}
                      <span style={{ marginLeft: 8 }}>Al menos un número</span>
                    </div>
                    <div className='d-flex align-items-center'>
                      {passwordValidations.hasSymbol ? (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/checked.svg')} alt='logo' />
                      ) : (
                        <img src={toAbsoluteUrl('/✅ Web - Mm/unchecked.svg')} alt='logo' />
                      )}
                      <span style={{ marginLeft: 8 }}>Al menos un caracter especial </span>
                    </div>
                  </>
                </div>
              </div>

              <div className='mb-6'>
                <label className='text-muted fs-6 mb-2'>Nueva Contraseña</label>
                <input
                  className='form-control form-control-solid'
                  type='password'
                  placeholder='Nueva Contraseña'
                  onKeyDown={handleKeyDown}
                  {...upsertPasswordForm.getFieldProps('new_password')}
                />
                {upsertPasswordForm.touched.new_password && upsertPasswordForm.errors.new_password && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger' role='alert'>
                      {upsertPasswordForm.errors.new_password}
                    </span>
                  </div>
                )}
              </div>
              <div className='mb-15'>
                <label className='text-muted fs-6 mb-2'>Confirmar Contraseña</label>
                <input
                  className='form-control form-control-solid'
                  type='password'
                  placeholder='Confirmar Contraseña'
                  onKeyDown={handleKeyDown}
                  {...upsertPasswordForm.getFieldProps('confirmation_password')}
                />
                {upsertPasswordForm.touched.confirmation_password &&
                  upsertPasswordForm.errors.confirmation_password && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger' role='alert'>
                        {upsertPasswordForm.errors.confirmation_password}
                      </span>
                    </div>
                  )}
              </div>
            </div>
            <div className='modal-footer py-2'>
              <div className='text-end'>
                <button
                  style={{ backgroundColor: '#eee', color: '#969696' }}
                  type='button'
                  id='cancelButton'
                  className='btn btn col m-3'
                  data-bs-dismiss='modal'
                  onClick={() => upsertPasswordForm.resetForm()}
                >
                  Cancelar
                </button>
                <button
                  style={{
                    background: 'rgb(0, 123, 195)',
                    color: 'white',
                  }}
                  disabled={
                    upsertPasswordMutation.isLoading ||
                    !Object.values(passwordValidations).every((validation) => !!validation)
                  }
                  type='submit'
                  className='btn btn col m-3 fw'
                >
                  <div className='d-flex gap-3 align-items-center'>
                    {upsertPasswordMutation.isLoading && (
                      <div className='text-center align-items-center d-flex'>
                        <div
                          className='spinner-border'
                          style={{ width: 17, height: 17 }}
                          role='status'
                        ></div>
                      </div>
                    )}
                    {upsertPasswordMutation.isLoading
                      ? prospect
                        ? 'Generando'
                        : 'Actualizando'
                      : prospect
                      ? 'Generar'
                      : 'Actualizar'}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
