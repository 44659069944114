import { configureStore } from '@reduxjs/toolkit'
import notificationsSlice from './notificationSlice'

export const store = configureStore({
  reducer: {
    notification: notificationsSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
