import { useQuery } from '@tanstack/react-query'
import { ErrorMessage, Field, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { Option } from 'react-multi-select-component'
import { useStateCallback } from '../../../../../common/hooks/useStateCallback'
import { IRoleCard, IRoleList } from '../../../../../common/interfaces/role.interface'
import { ICreateUser } from '../../../../../common/interfaces/user.interface'
import { apiUrl } from '../../../../../config/api'

interface IProps {
  formProps: FormikProps<ICreateUser>
}

export const AddUserForm = ({ formProps }: IProps) => {
  const [selectedRoles, setSelectedRoles] = useStateCallback<Option[]>([])
  const {
    isLoading,
    isFetching,
    isError,
    data: roles,
  } = useQuery<IRoleList>(['role-list-dropdown'], () => listRoles(), {
    keepPreviousData: true,
  })

  const listRoles = (page = 1, limit = 100) =>
    apiUrl.get(`/capability/role/list?page=${page}&limit=${limit}`).then((res) => res.data)

  useEffect(() => {
    formProps.setFieldValue(
      'roles',
      selectedRoles.map((role: any) => role.value)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoles])

  return (
    <React.Fragment>
      <div className='card-header border-0 '>
        <div className='card-title align-items-start flex-column'>
          <div className='d-flex flex-column'>
            <span style={{ color: '#98a2b3', fontSize: '16px' }} className='mt-1 mb-0 fw-bold'>
              Nuevo usuario
            </span>
            <span
              style={{ color: '#344054', letterSpacing: '0.5px', fontSize: '24px' }}
              className='card-label fw-bolder mb-1 '
            >
              Datos Generales
            </span>
          </div>
        </div>
      </div>

      <div className='mt-10'>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Nombres</label>

                <Field
                  placeholder='Ingrese nombres completos'
                  name='name'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='name' />
                </div>
              </div>

              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Apellidos</label>

                <Field
                  placeholder='Ingrese apellidos completos'
                  name='lastname'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='lastname' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Alias</label>

                <Field
                  placeholder='Ingrese un alias'
                  name='username'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='username' />
                </div>
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Correo electrónico</label>

                <Field
                  placeholder='@ejemplo.com'
                  name='email'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='email' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Número de teléfono</label>

                <Field
                  placeholder='+519********'
                  name='phone_number'
                  validate={(value: any) => {
                    if (!/^\+?519[0-9]{8}$/.test(value)) {
                      return 'Formato de teléfono invalido'
                    }
                  }}
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='phone_number' />
                </div>
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Contraseña</label>

                <Field
                  placeholder='Ingrese contraseña'
                  name='password'
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='password' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Repetir Contraseña</label>

                <Field
                  placeholder='Ingrese nuevamente contraseña'
                  name='passwordConfirmation'
                  className='form-control form-control-lg form-control-solid'
                  type='password'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='passwordConfirmation' />
                </div>
              </div>
              <div className='col-lg fv-row position-relative'>
                <label className='form-label required'>Rol</label>

                {(!isLoading || !isFetching) && !isError && (
                  <select
                    className='selectForm'
                    value={selectedRoles[0]?.value ?? null}
                    onChange={(e) =>
                      setSelectedRoles([{ value: e.target.value, label: e.target.name }])
                    }
                  >
                    <option value=''>Seleccionar</option>
                    {roles?.data.map((e: IRoleCard) => (
                      <option key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </select>
                  // <MultiSelect
                  //   labelledBy='Select'
                  //   value={selectedRoles}
                  //   className='multiSelect'
                  //   hasSelectAll={false}
                  //   options={
                  //     roles?.data.map((e: IRoleCard) => {
                  //       return { label: e.name, value: e.id }
                  //     }) as unknown as Option[]
                  //   }
                  //   onChange={setSelectedRoles}
                  // ></MultiSelect>
                )}

                <div className='text-danger mt-2'>
                  <ErrorMessage name='roles' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
