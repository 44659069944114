import { apiUrl } from '../config/api'

const questionnaire = 'Preguntas para determinar la tolerancia al riesgo'

export const QuestionnaireService = () => {
  const showQuestionnaire = () =>
    apiUrl.post(`app/customer/show-questionnaire/${questionnaire}`).then((res) => res.data)
  return {
    showQuestionnaire,
  }
}
