import { useMutation } from '@tanstack/react-query'
import { saveAs } from 'file-saver'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { SolicitudeStatusEnum } from '../../../common/interfaces/payment.interface'
import { customSwal } from '../../../config/swal'
import { PaymentService } from '../../../services/PaymentsService'

export const usePayments = (refetchSuscriptions: any, refetchPayments: any) => {
  const [selectedPayment, setSelectedPayment] = useStateCallback<number>(0)
  const [downloadLoading, setIsLoading] = useStateCallback<boolean>(false)

  const changeStatusMutation = useMutation({
    mutationFn: (status: SolicitudeStatusEnum) =>
      PaymentService().changeStatusPayment(status, selectedPayment),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: `Estado actualizado para el pago seleccionado.`,
      })
      refetchPayments()
      refetchSuscriptions()
    },
  })
  const downloadVoucher = async (voucher: any) => {
    setIsLoading(true)

    if (!voucher) {
      customSwal.fire({
        icon: 'warning',
        title: 'No se encontraron adjuntos para este pago.',
      })
      return
    }
    setTimeout(() => {
      saveAs(voucher, voucher.slice(voucher.lastIndexOf('/') + 1, voucher.length))
    }, 1000)
    setIsLoading(false)
  }

  return {
    downloadVoucher,
    setSelectedPayment,
    selectedPayment,
    downloadLoading,
    changeStatusMutation,
  }
}
