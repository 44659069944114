import React, { useState } from 'react'
import { customSwal } from '../../../config/swal'

interface PropsI {
  title: string
  onPerformanceData: any
  handleSubmit: (e: any) => void
  formData: any
  setFormData: any
  errors: any
}

interface Card {
  description: number | string
  total: string
}
export const TableResults = ({ title, handleSubmit, formData, setFormData, errors }: PropsI) => {
  const [results, setResults] = useState<Card[]>(
    formData.structure.results.data || [
      {
        description: '',
        total: '',
      },
    ]
  )
  const [resultError, setResultError] = useState(
    results.map((result) => Number(result.total) > 100 && Number(result.total) < -100)
  )

  const handleAddResult = (cardIndex: number) => {
    setResults((prevResults) => {
      prevResults.splice(cardIndex + 1, 0, { description: '', total: '' })

      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          results: { ...formData.structure.results, data: prevResults },
        },
      })
      return prevResults
    })
  }

  const handleResultChange = (e: any, cardIndex: any) => {
    const { name, value } = e.target

    if (name === 'total') {
      if (Number(value) > 100 || Number(value) < -100) {
        setResultError([
          ...resultError.slice(0, cardIndex),
          true,
          ...resultError.slice(cardIndex + 1, resultError.length),
        ])
      } else {
        setResultError([
          ...resultError.slice(0, cardIndex),
          false,
          ...resultError.slice(cardIndex + 1, resultError.length),
        ])
      }
      if (value.trim() === '') {
        setResultError([
          ...resultError.slice(0, cardIndex),
          true,
          ...resultError.slice(cardIndex + 1, resultError.length),
        ])
      } else {
        setResultError([
          ...resultError.slice(0, cardIndex),
          false,
          ...resultError.slice(cardIndex + 1, resultError.length),
        ])
      }
    }

    setResults((prevResults) => {
      const updatedCards = [...prevResults]
      const updatedCard = { ...updatedCards[cardIndex], [name]: value }
      updatedCards[cardIndex] = updatedCard

      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          results: { ...formData.structure.results, data: updatedCards },
        },
      })
      return updatedCards
    })
  }

  const handleError = () => {
    customSwal.fire({
      icon: 'error',
      title: 'No se puede ingresar un total mayor a 100 y menor a 0.',
    })
  }

  const handleResultDelete = (cardIndex: number) => {
    if (results.length === 2) {
      customSwal.fire({
        icon: 'error',
        title: 'Se tienen que ingresar al menos dos rangos.',
      })
      return
    }
    setResults((prevResults) => {
      const updatedCards = [...prevResults]
      updatedCards.splice(cardIndex, 1)
      setFormData({
        ...formData,
        structure: {
          ...formData.structure,
          results: { ...formData.structure.results, data: updatedCards },
        },
      })
      return updatedCards
    })
  }

  return (
    <React.Fragment>
      <div className='text-center card mt-10 p-5 pt-5'>
        <div className='col showInfoFund'>
          <div className='form-check form-switch d-flex align-items-center gap-4'>
            <label htmlFor='Diabled' className='fw-bolder fs-6 text-gray-700'>
              Mostrar información
            </label>

            <div className='form-check form-switch text-end '>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                id='flexSwitchCheckDefault'
                checked={formData.structure.results.visibility}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    structure: {
                      ...formData.structure,
                      results: {
                        ...formData.structure.results,
                        visibility: e.target.checked,
                      },
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
        <span className='card-title text-center mt-2 mb-5 text-gray-800 fs-2'>{title}</span>
        <div className='text-end updateConfigFund'>
          <button
            style={{ background: 'rgb(0, 123, 195)' }}
            className='btn text-white cursor-pointer'
            onClick={(e) => {
              e.preventDefault()
              resultError.includes(true) ? handleError() : handleSubmit(e)
            }}
            type='submit'
          >
            Actualizar
          </button>
        </div>
      </div>
      <div className='card mt-10 p-10 pb-5'>
        <div>
          <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
          <textarea
            className='form-control'
            name='description'
            style={{
              height: '50px',
              resize: 'none',
            }}
            required
            value={formData.structure.results.title}
            onChange={(e) =>
              setFormData({
                ...formData,
                structure: {
                  ...formData.structure,
                  results: {
                    ...formData.structure.results,
                    title: e.target.value,
                  },
                },
              })
            }
            placeholder='Título'
          />
          {errors['resultTitle'] && (
            <span className='text-danger' role='alert'>
              {errors['resultTitle']}
            </span>
          )}
        </div>
        <div className='row mt-10 g-3'>
          <div className='col-sm-6'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Título</label>
            {results.map((card, cardIndex) => (
              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control mb-5 col'
                  name='description'
                  value={card.description}
                  placeholder='Título'
                  onChange={(e) => handleResultChange(e, cardIndex)}
                />
                {errors['result-title' + cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    {errors['result-title' + cardIndex]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className='col-sm-4'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Descripción</label>
            {results.map((card, cardIndex) => (
              <div className='position-relative'>
                <input
                  className='form-control mb-5 col'
                  type='number'
                  min={-100}
                  max={100}
                  placeholder='Descripción'
                  name='total'
                  value={card.total}
                  onChange={(e) => handleResultChange(e, cardIndex)}
                />
                {resultError[cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    Rentabilidad es requerido y entre 0 a 100
                  </span>
                )}
                {errors['result-description' + cardIndex] && (
                  <span
                    className='text-danger position-absolute'
                    style={{ bottom: -15, fontSize: 10 }}
                    role='alert'
                  >
                    {errors['result-description' + cardIndex]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className='col-sm-2 text-center'>
            <label className='fw-bolder fs-5 text-gray-700 mb-3'>Acciones</label>
            <br />
            {results.map((card, cardIndex) => (
              <>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                    marginRight: '20px',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleAddResult(cardIndex)}
                >
                  <i className='bi bi-plus-lg text-muted fs-3'></i>
                </button>
                <button
                  style={{
                    background: 'transparent',
                    color: 'white',
                    border: '1px solid #ccc',
                  }}
                  type='button'
                  className='btn btn-icon mb-5'
                  onClick={(e) => handleResultDelete(cardIndex)}
                >
                  <i className='bi bi-trash text-muted fs-3'></i>
                </button>
                <br />
              </>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
