import clsx from 'clsx'
import React, { FC } from 'react'

interface IProps {
  formattedRisk: 1 | 2 | 3 | 'none'
  size: 'small' | 'medium'
}

export const RiskBadge: FC<IProps> = ({ formattedRisk, size }) => {
  return (
    <>
      {formattedRisk === 1 ? (
        <span
          className={clsx('badge', size === 'medium' && 'p-3')}
          style={{
            color: '#5A9A3D',
            background: '#EEF5EB',
          }}
        >
          Riesgo bajo
        </span>
      ) : formattedRisk === 2 ? (
        <span
          className={clsx('badge', size === 'medium' && 'p-3')}
          style={{
            color: '#E66C20',
            background: '#FCF0E8',
          }}
        >
          Riesgo medio
        </span>
      ) : formattedRisk === 3 ? (
        <span
          className={clsx('badge', size === 'medium' && 'p-3')}
          style={{
            color: '#D53943',
            background: '#FBEBEC',
          }}
        >
          Riesgo alto
        </span>
      ) : (
        <span className='badge badge-light'>
          {formattedRisk === 'none' ? 'Por definir' : formattedRisk}
        </span>
      )}
    </>
  )
}
