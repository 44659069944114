export interface IClientList {
  id: number
  type_client: string
  name: string
  state: number
  lastname: string
  createdAt: string
  document: string | number
  //   updatedAt:  string;
  //   deletedAt:  string | null;
}

export interface IUpdateClient {
  username: string
  password: string
  passwordConfirmation: string
  email: string
  phone_number: string
  number_document: string
}

export interface IUpdatePassword {
  customer_id: string | string
  new_password: string
  confirmation_password: string
}
export interface ICustomerDetailRequest {
  numIdentidad: number | string
  tipoIdentidad: string
  origin: string
}

export interface ICustomerDetail {
  id: number
  name: string
  lastname: string
  middlename: string
  surname: string
  email: string
  username: string
  code_unique_spectrum: string
  email_verified: boolean
  phone_number: string
  document_type: string
  number_document: string
  securityCodeRetries: number
  send_email_password_bo: boolean
  status: number
  customer_user_type: string
  profile: Profile
  home: Home
  place_birth: Department
  activity: Activity
  spouse: Spouse
  bank_accounts: BankAccount[]
  movements: Movement[]
  charge_public: any
  contracts: Contract[]
  questionsAnswers: any
  customerRisks: CustomerRisk[]
  funds: Fund[]
  accept_data_treatment: boolean
}

export interface Contract {
  path: string
  filename: string
  date?: string
}

export interface Profile {
  id: number
  name: string
  lastname: string
  customer_id: string
  gender_id: number
  civil_status_id: number
  birthdate: string
  place_birth: number
  instruction_grade_id: number
  nationality_id: number
  document_type_id: number
  number_document: string
  created_at: string
  updated_at: string
  nationality: Nationality
  instruction_grade: InstructionGrade
  gender: Gender
  civil_status: CivilStatus
  document_type: DocumentType
}

export interface Movement {
  id: number
  fund_id: number
  tipoSolicitud: string
  fechaSolicitud: string
  fechaConstancia: string
  descripFondo: string
  descripFondoSerie: string
  descripParametro: string
  moneda: string
  terms_and_conditions: boolean
  origin_conditions: boolean
  fund_origin: string
  montoNetoSolicitud: number
  constancy_url: string | null
  origin: string
}

export interface Nationality {
  id: number
  description: string
  code: any
  status: number
}

export interface InstructionGrade {
  id: number
  description: string
  status: number
}

export interface Gender {
  id: number
  description: string
  code: string
  status: number
}

export interface CivilStatus {
  id: string
  description: string
  code: string
  status: number
}

export interface DocumentType {
  id: number
  description: string
  code: string
  status: number
}

export interface Home {
  id: number
  customer_id: string
  department_id: number
  province_id: number
  city: string
  district_id: number
  district_text: string
  address: string
  dpto: string
  urb: string
  created_at: string
  updated_at: string
  department: Department
  province: Province
  district: District
}

export interface Department {
  id: number
  description: string
  code: string
  status: number
}

export interface Province {
  id: number
  description: string
  department_id: number
  code: any
  status: number
}

export interface District {
  id: number
  description: string
  province_id: number
  code: any
  status: number
}

export interface Activity {
  id: number
  customer_id: string
  type_worker_id: number
  entry_date_id: number
  occupation: string
  monthly_income: string
  business_name: string
  position: string
  admision_date: string
  created_at: string
  updated_at: string
  type_worker: TypeWorker
  entry_date: EntryDate
}

export interface EntryDate {
  id: number
  description: string
  code: any
  status: number
}

export interface TypeWorker {
  id: number
  description: string
  code: any
  status: number
}

export interface BankAccount {
  id: number
  customer_id: string
  bank_id: number
  money_type: string
  money_type_id: number
  account_type_id: number
  account_type: AccountType
  account_number: string
  previous_account_number: any
  cci: string
  is_main: boolean
  status: number
  created_at: string
  updated_at: string
  bank: Bank
}

export interface AccountType {
  id: number
  description: string
  code: string
  status: number
}

export interface Bank {
  id: number
  description: string
  code: string
  status: number
}

export interface CustomerRisk {
  id: number
  customer_id: string
  risk_profile_id: number
  qestionnaire_id: number
  total_score: number
  customer_risk_profile: string
  status: number
  constancy_url: string
  constancy_date: string
}

export interface Fund {
  codFondo: string
  codAdministradora: string
  descripFondo: string
  codFondoSerie: string
  descripFondoSerie: string
  numCertificado: string
  fechaSuscripcion: string
  valorCuota: number
  cantCuotas: number
  valorCuotaActual: number
  moneda: string
  fechaCuotaActual: string
  montoInversionInicial: number
  montoRescate: number
  beneficioDeInversion: number
  estado: string
  numCertificadoEliminado: string
}

export interface Spouse {
  id: number
  customer_id: string
  regime_type_id: number
  name: string
  middlename: string
  surname: string
  lastname: string
  fullname: string
  birth_date: string
  place_of_birth_id: number
  nationality_id: number
  country_id: number
  instruction_grade_id: number
  type_document_id: number
  document_number: string
  regime_type: RegimeType
  created_at: string
  updated_at: string
  nationality: Nationality
  country: Country
  instruction_grade: InstructionGrade
  documentType: DocumentType
}

export interface RegimeType {
  id: number
  description: string
  status: number
}

export interface Nationality {
  id: number
  description: string
  code: any
  status: number
}

export interface Country {
  id: number
  description: string
  code: any
  status: number
}

export interface InstructionGrade {
  id: number
  description: string
  status: number
}

export interface DocumentType {
  id: number
  description: string
  code: string
  status: number
}

export enum ClientStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
}