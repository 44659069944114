import React from 'react'
import { getDDMMYYYYFormat } from '../../../../common/helpers/DateHandlers'
import { ICustomerDetail } from '../../../../common/interfaces'

interface IProps {
  customerDetail: ICustomerDetail
}

export const NaturalPersonTable = ({ customerDetail }: IProps) => {
  return (
    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
      <thead>
        <tr className='fw-normal text-muted'>
          <th className='min-w-150px'>CAMPO</th>
          <th className='min-w-150px'>VALOR</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>Nº TELEFONO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>{customerDetail.phone_number ?? '--'}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>SEXO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.profile
                ? !!customerDetail?.profile.gender
                  ? customerDetail?.profile.gender.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>ESTADO CIVIL</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.profile
                ? !!customerDetail?.profile.civil_status
                  ? customerDetail?.profile.civil_status.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>FECHA DE NACIMIENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.profile
                ? getDDMMYYYYFormat(customerDetail?.profile.birthdate!)
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>LUGAR DE NACIMIENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.place_birth ? customerDetail?.place_birth.description : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>NACIONALIDAD</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.profile
                ? !!customerDetail?.profile.nationality
                  ? customerDetail?.profile.nationality.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>GRADO DE INSTRUCCIÓN</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.profile
                ? !!customerDetail?.profile.instruction_grade
                  ? customerDetail?.profile.instruction_grade.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>TIPO DE DOCUMENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>{customerDetail?.document_type}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>NÚMERO DE DOCUMENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>{customerDetail?.number_document}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>TIPO DE TRABAJO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.activity
                ? !!customerDetail?.activity.type_worker
                  ? customerDetail?.activity.type_worker.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>OCUPACIÓN</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.activity
                ? !!customerDetail?.activity.occupation
                  ? customerDetail?.activity.occupation
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>RAZÓN SOCIAL</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.activity ? customerDetail?.activity.business_name ?? '--' : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>PUESTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.activity ? customerDetail?.activity.position ?? '--' : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>{'Antiguedad'.toUpperCase()}</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.activity
                ? !!customerDetail?.activity.entry_date
                  ? customerDetail?.activity.entry_date.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>DEPARTAMENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home
                ? !!customerDetail?.home.department
                  ? customerDetail?.home.department.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>PROVINCIA</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home
                ? !!customerDetail?.home.province
                  ? customerDetail?.home.province.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>DISTRITO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home
                ? !!customerDetail?.home.district
                  ? customerDetail?.home.district.description
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>DIRECCIÓN</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home ? customerDetail?.home.address ?? '--' : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>N° DE DEPARTAMENTO</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home
                ? customerDetail?.home.dpto
                  ? !!customerDetail?.home.dpto.trim()
                    ? customerDetail?.home.dpto
                    : '--'
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>URBANIZACIÓN</span>
          </td>

          <td>
            <span className='text-muted d-block fs-5'>
              {customerDetail?.home
                ? !!customerDetail?.home.urb
                  ? customerDetail?.home.urb
                  : '--'
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>TRATAMIENTO DE DATOS</span>
          </td>

          <td>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                style={{
                  width: '24px',
                  height: '24px',
                  cursor: 'pointer',
                }}
                className='form-check-input'
                type='checkbox'
                id='flexCheckDefault'
                disabled
                checked={customerDetail?.accept_data_treatment}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
