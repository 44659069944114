import { IGeneralConfigItem } from '../common/interfaces/config.interface'
import { apiUrl } from '../config/api'

export const ConfigurationService = () => {
  const configurationList = async () => (await apiUrl.get('/configuration/list')).data

  const updateConfiguration = async (data: IGeneralConfigItem) =>
    (await apiUrl.patch(`/configuration/update/${data.id}`, data)).data

  return {
    configurationList,
    updateConfiguration,
  }
}
