/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import { useState } from 'react'
import * as Yup from 'yup'
import { useAuth } from '../core/Auth'
import { login } from '../core/_requests'
import { useGoogleRecaptcha } from '../hooks/useGoogleRecaptcha'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de correo invalido')
    .required('Correo electrónico es requerido'),
  password: Yup.string().required('Contraseña es requerida'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser, savePermissions } = useAuth()
  const { handleReCaptchaVerify } = useGoogleRecaptcha()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await handleReCaptchaVerify()
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        setCurrentUser(auth.user)
        savePermissions(auth.user.roles)
      } catch (error: any) {
        console.error(error)
        setStatus('Correo y/o contraseña inválida.')
        saveAuth(undefined)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-6'>
        <img src='/media/logos/prudencialsafblue.svg' alt='logo' />
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10 mt-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Correo electrónico</label>
        <input
          placeholder='Correo electrónico'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger' role='alert'>
              {formik.errors.email}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Contraseña</label>
            {/* end::Label */}
            {/* begin::Link */}

            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          placeholder='Contraseña'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          style={{ background: '#007BC3', color: 'white' }}
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  btn-dark w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Iniciar sesión</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Espera un momento...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {/* <Link
          to='/auth/forgot-password'
          className=' fs-6 fw-bolder'
          style={{ marginLeft: '5px', color: '#007BC3' }}
        >
          Olvidé mi contraseña
        </Link> */}
      </div>
      {/* end::Action */}
    </form>
  )
}
