/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { AsideMenu } from './AsideMenu'

const AsideDefault: FC = () => {
  const { classes } = useLayout()
  return (
    <div
      style={{
        background: ' linear-gradient(#007BC3 , #001F45)',
      }}
      id='kt_aside'
      className={clsx('aside pb-5 pt-5 pt-lg-0 ', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'80px', '300px': '100px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div
        style={{ background: ' linear-gradient(#007BC3,#0071b6)' }}
        className='aside-logo  py-8'
        id='kt_aside_logo'
      >
        <Link to='/' className='d-flex align-items-center'>
          <img
            src={toAbsoluteUrl('/media/logos/prudentialLogo.svg')}
            alt='logo'
            className='h-60px logo'
          />
        </Link>
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid' id='kt_aside_menu'>
        {/* begin::Nav */}
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
        {/* end::Nav */}

        {/* end::Aside menu */}
      </div>
    </div>
  )
}

export { AsideDefault }
