import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from '@tanstack/react-query'
import { IToggleSignDocument, IUpsertDocument } from '../../../common/interfaces'
import { customSwal } from '../../../config/swal'
import { FundService } from '../../../services/FundService'

interface IProps {
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<any, unknown>>
}

export const useFunds = ({ refetch }: IProps) => {
  const changeStatusFund = useMutation({
    mutationFn: (fundId: number) => FundService().changeStatus(fundId),
    onSuccess: () => {
      refetch()
    },
  })

  const setRecommendedFunds = useMutation({
    mutationFn: (id: string | number) => FundService().setRecommendedFunds(id),
    onSuccess: () => {
      refetch()
    },
  })

  const toggleSignDocument = useMutation({
    mutationFn: (data: IToggleSignDocument) => FundService().toggleSignDocument(data),
    onSuccess: () => {
      refetch()
    },
  })
  const upsertFundDocument = useMutation({
    mutationFn: (data: IUpsertDocument) => FundService().upsertFundDocument(data),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: 'Nombre del documento guardado correctamente',
      })
      refetch()
    },
  })

  const mutationSync = useMutation(() => FundService().spectrumSync(), {
    onSuccess: () => {
      refetch()
    },
  })

  return {
    changeStatusFund,
    mutationSync,
    setRecommendedFunds,
    toggleSignDocument,
    upsertFundDocument,
  }
}
