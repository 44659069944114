import { toast } from 'react-toastify'
import { IViewUserRole } from '../../../common/interfaces'
import { ILoginResponse } from '../../../common/interfaces/auth.interface'
import { customSwal } from '../../../config/swal'

const AUTH_LOCAL_STORAGE_KEY = 'auth-react-storage'
const getAuth = (): ILoginResponse | undefined => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: ILoginResponse = JSON.parse(lsValue) as ILoginResponse
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: ILoginResponse) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const getAuthPermissions = () => {
  const auth = getAuth()
  return (
    auth?.user?.roles
      ?.map((role) =>
        role.rolePermissions
          .filter((permission) => permission.machine && permission.module)
          .map((permission) => permission.machine)
      )
      .flat() || []
  )
}

const setAuthPermissions = (roles: IViewUserRole[] | undefined) => {
  return roles
    ? roles
        .map((role) =>
          role.rolePermissions
            .filter((permission) => permission.machine && permission.module)
            .map((permission) => permission.machine)
        )
        .flat()
    : []
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (error.response.status === 401) {
        // Clear the expired token and redirect to the login page
        customSwal.fire({
          icon: 'warning',
          title: 'Sesión expirada.',
          text: 'Indetifíquese nuevamente.',
        })
        setTimeout(() => {
          localStorage.removeItem('token')
          localStorage.removeItem('auth-react-storage')
          localStorage.removeItem('selectedUsers')
          window.location.href = '/auth/login'
        }, 1000)
      }
      if (error.response.status === 403) {
        customSwal.fire({
          icon: 'error',
          title: 'No tienes permisos para realizar esta acción',
          text: 'Consulte con su administrador.',
        })
        // toast.error('Acceso denegado. No tiene los permisos para acceder a esta página.', {
        //   position: 'top-right',
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // })
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }

      return Promise.reject(error)
    }
  )
}

export {
  getAuth,
  setAuth,
  removeAuth,
  getAuthPermissions,
  setAuthPermissions,
  AUTH_LOCAL_STORAGE_KEY,
}
