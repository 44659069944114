/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Accordion, TabContent } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import { KTSVG } from '../../../_metronic/helpers'
import { ILogData, LogEventKeyEnum } from '../../common/interfaces'
import { useLogger } from './hooks/useLogger'

export const ListLogs = () => {
  const {
    paginator: {
      paginationComponent,
      query: { data: logs, isLoading, isFetching, isError },
    },
    selectedTab,
    setSelectedTab,
    logIsError,
    logCurrentStatus,
    logQuantity,
    cleanupRequest,
  } = useLogger()

  return (
    <React.Fragment>
      <div className='p-10 pt-5 m-0' style={{ background: 'white', borderRadius: '6px' }}>
        <div className='card-header row'>
          {!((isLoading || isFetching) && !isError) && (
            <div className=' ml-0 pl-0 text-end'>
              <span
                style={{
                  margin: ' 5px',
                  fontSize: '14px',
                  border: '1PX SOLID #DBEFF8',
                  background: '#DBEFF8',
                  color: '#0066CC',
                  padding: '10px 15px 10px 15px',
                }}
                className='badge gap-3  m-2'
              >
                Success: {logQuantity().success}
              </span>
              <span
                style={{
                  margin: ' 2px',
                  fontSize: '14px',
                  background: '#FFEAEF',
                  color: '#CA082C',
                  padding: '10px 15px 10px 15px',
                  border: '1px solid #FFEAEF',
                }}
                className='badge gap-3 badge-danger m-2'
              >
                Error: {logQuantity().error}
              </span>
            </div>
          )}
        </div>
        <div className='mt-1'>
          <Tab.Container
            id='left-tabs'
            activeKey={selectedTab}
            onSelect={(e: any) => setSelectedTab(e)}
          >
            <Row>
              <Col md={3}>
                <div className='col-2 ml-0 pl-0'>
                  <span
                    style={{
                      color: '#8d8d8d',
                      fontSize: 18,
                    }}
                    className='m-2 mb-4 p-2'
                  >
                    Servicios
                  </span>
                </div>
                <Nav variant='pills' className='flex-column mt-4'>
                  {Object.values(LogEventKeyEnum)
                    .filter((value) => typeof value === 'string')
                    .map((value) => {
                      return (
                        <Nav.Item>
                          <Nav.Link eventKey={value} style={{ color: '#373538' }}>
                            {value}
                          </Nav.Link>
                        </Nav.Item>
                      )
                    })}
                </Nav>
              </Col>

              <Col md={9}>
                <div className='card-header row mt-5'>
                  <div className='col-3 ml-0 pl-0'>
                    <span
                      style={{
                        color: '#8d8d8d',
                      }}
                      className=' m-2 p-2'
                    >
                      Estado
                    </span>
                  </div>
                  <div className='col-3 p-0 text-center'>
                    <span
                      style={{
                        color: '#8d8d8d',
                      }}
                    >
                      Fecha
                    </span>
                  </div>
                  <div className='col-5 ml-0 text-left pl-0'>
                    <span
                      style={{
                        color: '#8d8d8d',
                      }}
                      className='m-2 p-2'
                    >
                      Endpoint
                    </span>
                  </div>
                </div>

                {(isLoading || isFetching) && !isError ? (
                  <TabContent>
                    <div className='text-center m-5 p-10'>
                      <div className='spinner-border' role='status'></div>
                    </div>
                  </TabContent>
                ) : (
                  <Tab.Content>
                    {Object.values(LogEventKeyEnum)
                      .filter((value) => typeof value === 'string')
                      .map((value) => {
                        return (
                          <Tab.Pane
                            eventKey={value}
                            style={{
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            {logs?.data ? (
                              <Accordion
                                alwaysOpen
                                style={{
                                  margin: '0px',
                                  padding: '0px',
                                }}
                              >
                                <div className='m-1'>
                                  {logs?.data.map((item: ILogData, i: number) => {
                                    let logError = logIsError(item)
                                    let currentStatus = logCurrentStatus(item)

                                    return (
                                      <div key={i}>
                                        <Accordion.Item
                                          style={{ cursor: 'default' }}
                                          eventKey={i.toString()}
                                        >
                                          <Accordion.Header
                                            style={{ cursor: 'default' }}
                                            className='row'
                                          >
                                            <KTSVG
                                              className='svg-icon svg-icon-4 mr-3'
                                              path={
                                                logError
                                                  ? '/media/icons/duotune/general/gen040copy.svg'
                                                  : '/media/icons/duotune/general/gen0455copy.svg'
                                              }
                                            />

                                            <span
                                              style={{
                                                color: logError ? 'rgb(255, 131, 131)' : '#50cd89',
                                                marginLeft: '15px ',
                                                fontWeight: 'bolder',
                                              }}
                                              className=' ml-5 col-3 '
                                            >
                                              {logError
                                                ? 'Error ' + currentStatus
                                                : 'Success ' +
                                                  (currentStatus ? currentStatus : item.status)}
                                            </span>
                                            <span className='text-muted  ml-5 col-3 text-start'>
                                              {FormattedDate(item.emittedDate)}
                                            </span>
                                            <span className='text-muted ml-5 col-5 text-left'>
                                              {item.url}
                                            </span>
                                          </Accordion.Header>
                                        </Accordion.Item>
                                      </div>
                                    )
                                  })}
                                </div>
                              </Accordion>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: '30px',
                                }}
                              >
                                <span className='text-center text-muted p-10'>
                                  No se han encontrado resultados
                                  <a
                                    className='link'
                                    style={{
                                      marginLeft: 5,
                                      cursor: 'pointer',
                                    }}
                                    onClick={cleanupRequest}
                                  >
                                    mostrar todos los logs
                                  </a>
                                </span>
                              </div>
                            )}
                          </Tab.Pane>
                        )
                      })}
                  </Tab.Content>
                )}
              </Col>
            </Row>
          </Tab.Container>
          {paginationComponent()}
        </div>
      </div>
    </React.Fragment>
  )
}

const FormattedDate = (dateLogs: any) => {
  const fecha = new Date(dateLogs)
  const fechaFormateada = fecha.toLocaleDateString() + ' ' + fecha.toLocaleTimeString()
  return fechaFormateada
}
