import { useMutation } from '@tanstack/react-query'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { customSwal } from '../../../config/swal'
import { RescueService } from '../../../services/RescueService'
import { IFilterStatus } from '../components/tabs/RescueTab'

export const useRescues = () => {
  const [selectedRescue, setSelectedRescue] = useStateCallback<number>(0)
  const [selectedRescues, setSelectedRescues] = useStateCallback<any>([])
  const [comment, setComment] = useStateCallback<string>('')

  const changeStatusMutation = useMutation({
    mutationFn: (status: IFilterStatus) =>
      RescueService().changeStatusRescue(status, selectedRescues, comment),
    onSuccess: () => {
      customSwal.fire({
        icon: 'success',
        title: `Estado actualizado para ${selectedRescues.length} ${
          selectedRescues.length > 1 ? 'rescates' : 'rescate'
        }.`,
      })
      setSelectedRescues([])
    },
  })

  const previewImage = async (voucher: any, elementId: string) => {
    const res = await fetch(voucher)
    const blob = await res.blob()

    const img = new Image()
    img.src = URL.createObjectURL(blob)

    img.width = 400
    img.height = 800
    document.getElementById(elementId)!.append(img)
    await img.decode()

    URL.revokeObjectURL(img.src)
  }

  return {
    changeStatusMutation,
    selectedRescue,
    setSelectedRescue,
    selectedRescues,
    setSelectedRescues,
    previewImage,
    comment,
    setComment,
  }
}
