import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useAuth } from '../auth'

const Home = () => {
  const { currentUser } = useAuth()

  return (
    <div
      className='home_wrapper'
      style={{
        backgroundImage: `url('${toAbsoluteUrl('/media/home/Prudential_bienvenida.jpg')}')`,
      }}
    >
      <div className='body-wrapper'>
        <h2>{`Hola ${currentUser?.name},`}</h2>
        <h1>¡Bienvenido al Backoffice de Prudential SAF!</h1>
        <p>
          Este portal está diseñado exclusivamente para nuestro equipo, proporcionando todas las
          herramientas y recursos necesarios para gestionar y optimizar nuestras operaciones
          internas.
        </p>
      </div>
    </div>
  )
}

export default Home
