import { FC } from 'react'

interface IProps {
  search: string
  setSearch: (state: string, cb?: ((state: string) => void) | undefined) => void
  placeholder: string
}

export const SearchInput: FC<IProps> = ({ search, setSearch, placeholder }) => {
  return (
    <div className='text-center'>
      <input
        className='form-control'
        type='text'
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        placeholder={placeholder}
        style={{
          width: '100%',
          minWidth: '320px',
          backgroundColor: '#f9f9f9',
          border: 0,
          height: '40px',
        }}
        name='searchName'
      />
    </div>
  )
}
