/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import FsLightbox from 'fslightbox-react'
import * as React from 'react'
import { FC, useEffect } from 'react'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { formatDateTimeUTC, formatTimeToHHMMSS } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import {
  ISubscription,
  MoneyTypeEnum,
  SuscriptionStatusEnum,
} from '../../../../common/interfaces/movement.interface'
import { SuscriptionService } from '../../../../services/SuscriptionService'
import { formatCustomerFulllname } from '../../../clients/helpers/customerFormatHelper'
import { useSuscriptions } from '../../hooks/useSuscriptions'
import { PaymentsModal } from '../modals/PaymentsModal'
import { IFilters } from '../tabs/SuscriptionTab'

interface IProps {
  search: string
  setSearch: any
  selectedSuscriptions: number[]
  setSelectedSuscriptions: any
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
  mutationSuccess: boolean
}

const pageSizes = [10, 20, 40, 100]

export const SuscriptionTable: FC<IProps> = ({
  search,
  setSearch,
  selectedSuscriptions,
  setSelectedSuscriptions,
  filters,
  setFilters,
  mutationSuccess,
}) => {
  const [openPreview, setOpenPreview] = useStateCallback<boolean>(false)
  const { hasPermission } = usePermissions()
  const { selectedSuscription, setSelectedSuscription, downloadMutation, previewImage } =
    useSuscriptions()
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: {
      data: subscriptions,
      refetch,
      isRefetching,
      isLoading: isLoadingSuscriptions,
      isFetching,
      isError,
    },
  } = usePaginator({
    initPage: 1,
    initLimit: 10,
    search,
    pageSizes,
    paginatorOptions: {
      queryKey: ['subscription-list'],
      fetch: SuscriptionService().listSubscription,
    },
    queryOptions: {
      retry: false,
    },
    filters,
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), mutationSuccess, search])

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters(
      {
        start_date: '',
        end_date: '',
        sended_spectrum: true,
      },
      () => {
        refetch()
      }
    )
  }

  const handleClickCheckbox = (id: number) => {
    setSelectedSuscriptions((prev: number[]) => {
      const index = prev.indexOf(id)
      if (index === -1) {
        return [...prev, id]
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1)]
    })
  }

  return (
    <React.Fragment>
      <div className='card-body'>
        <div className='table-responsive'>
          {((isLoadingSuscriptions || isFetching) && !isError) || isRefetching ? (
            <div className='text-center m-5 p-10'>
              <div className='spinner-border' role='status'></div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-75px'></th>
                  <th className='w-150px'>FECHA Y HORA</th>
                  <th className='min-w-120px'>CLIENTE</th>
                  <th className='min-w-100px'>FONDO</th>
                  <th className='min-w-150px'>MONTO</th>
                  <th className='min-w-120px'>ESTADO</th>
                  <th className='min-w-120px'>BANCO</th>
                  <th className='w-150px'>N° DE OPERACIÓN</th>
                  {hasPermission(Permissions.SUBSCRIPTION_SHOW) && (
                    <th className='min-w-100px text-center'>ADJUNTO</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {subscriptions?.data &&
                  subscriptions
                    ?.data!.sort((a: ISubscription, b: ISubscription) =>
                      a.constancy_date && b.constancy_date
                        ? new Date(b.constancy_date).getTime() -
                          new Date(a.constancy_date).getTime()
                        : new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                    )
                    .map((subscription: ISubscription, index: number) => {
                      const newDate = subscription.constancy_date? new Date(subscription.constancy_date): new Date(subscription.created_at)
                      return (
                        <tr key={index}>
                          <td>
                            {subscription.status === SuscriptionStatusEnum.INPROGRESS && (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: '15px',
                                  paddingBottom: '15px',
                                }}
                                className='form-check form-check-custom form-check-solid'
                              >
                                <input
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                  }}
                                  className='form-check-input'
                                  type='checkbox'
                                  id='flexCheckDefault'
                                  checked={selectedSuscriptions.includes(subscription.id)}
                                  onChange={() => handleClickCheckbox(subscription.id)}
                                />
                              </div>
                            )}
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatDateTimeUTC(newDate.toDateString())}
                              <span className='text-muted d-block fs-5'>
                                {formatTimeToHHMMSS(newDate)}
                              </span>
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatCustomerFulllname(subscription.customer)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.fund.title + ' - ' + subscription.serie.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.moneda === MoneyTypeEnum.USD
                                ? '$'
                                : subscription.moneda === MoneyTypeEnum.PEN && 'S/.'}
                              {subscription.amount.toFixed(4)}
                            </span>
                          </td>
                          <td>
                            {subscription.status === SuscriptionStatusEnum.INPROGRESS ? (
                              <span className='badge badge-light-primary'>En proceso</span>
                            ) : subscription.status === SuscriptionStatusEnum.APPROVED ? (
                              <span className='badge badge-light-success'>Aprobado</span>
                            ) : subscription.status === SuscriptionStatusEnum.PENDING ? (
                              <span className='badge badge-light-warning'>Pendiente</span>
                            ) : (
                              subscription.status === SuscriptionStatusEnum.ANNULLED && (
                                <span className='badge badge-light-danger'>Anulado</span>
                              )
                            )}
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {subscription.collector_account_prudential.bank.description}
                            </span>
                          </td>

                          <td>
                            {subscription.payments.length ? (
                              <>
                                {!!subscription.payments.at(0)?.operation_number ? (
                                  hasPermission(Permissions.SUBSCRIPTION_SHOW) ? (
                                    <div
                                      data-bs-toggle='modal'
                                      data-bs-target='#modal-payments'
                                      className='d-flex align-items-center gap-3'
                                      onClick={() => setSelectedSuscription(subscription.id)}
                                    >
                                      <span
                                        style={{
                                          borderBottom: '1px inset #a1a5b7',
                                        }}
                                        className='text-muted d-block cursor-pointer fs-5'
                                      >
                                        {subscription.payments.at(0)?.operation_number}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className='d-flex align-items-center gap-3'>
                                      <span className='text-muted d-block fs-5'>
                                        {subscription.payments.at(0)?.operation_number}
                                      </span>
                                    </div>
                                  )
                                ) : (
                                  <span className='text-muted  d-block fs-5'>--</span>
                                )}
                              </>
                            ) : (
                              <span className='text-muted  d-block fs-5'>--</span>
                            )}
                          </td>
                          {hasPermission(Permissions.SUBSCRIPTION_SHOW) && (
                            <td className={'text-center'}>
                              {subscription.payments && subscription.payments.at(0)?.voucher ? (
                                <div className='d-flex align-items-center'>
                                  <div
                                    onClick={() => {
                                      setSelectedSuscription(subscription.id)
                                      downloadMutation.mutate(subscription.id)
                                    }}
                                    className='d-flex align-items-center iconButton p-1 justify-content-center cursor-pointer'
                                  >
                                    {downloadMutation.isLoading &&
                                    selectedSuscription === subscription.id ? (
                                      <div className='text-center text-muted'>
                                        <div
                                          className='spinner-border m-2'
                                          style={{ width: 18, height: 18 }}
                                          role='status'
                                        ></div>
                                      </div>
                                    ) : (
                                      <>
                                        <i className='bi bi-download fs-3 m-2'></i>
                                      </>
                                    )}
                                  </div>
                                  <div
                                    className='iconButton'
                                    onClick={() => {
                                      previewImage(
                                        subscription.payments &&
                                          subscription.payments.at(0)?.voucher,
                                        'currentLightbox'
                                      )
                                      setOpenPreview(!openPreview)
                                    }}
                                  >
                                    {' '}
                                    <i className='bi bi-card-image fs-3 m-2'></i>
                                  </div>
                                </div>
                              ) : (
                                <span className='text-muted text-center'>--</span>
                              )}
                            </td>
                          )}
                        </tr>
                      )
                    })}
              </tbody>
            </table>
          )}
          {subscriptions?.data.length === 0 &&
            !(((isLoadingSuscriptions || isFetching) && !isError) || isRefetching) && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
                No se encontraron resultados{' '}
                <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  refrescar la tabla
                </a>
              </span>
            )}
        </div>
        <img src='' alt='' />
        <FsLightbox toggler={openPreview} sources={[<div id='currentLightbox'></div>]} />
        {paginationComponent()}
        <PaymentsModal refetchSuscriptions={refetch} selectedSuscriptionId={selectedSuscription} />
      </div>
    </React.Fragment>
  )
}
