import { Route, Routes } from 'react-router-dom'
import Home from '../../modules/home/Home'
import { SuspensedView } from '../PrivateRoutes'

export const HomeRouting = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />
    </Routes>
  )
}
