import { apiUrl } from '../config/api'

export const RoleService = () => {
  const listRoles = async (page: number, limit: number, search?: any, filters?: any, extra?: any) =>
    await apiUrl
      .get(`/capability/role/list`, {
        params: {
          page,
          limit,
          ...filters,
        },
      })
      .then((res) => res.data)

  const showRole = async (id: number | string) =>
    await apiUrl.get(`/capability/role/show/${id}`).then((res) => res.data)

  return {
    listRoles,
    showRole,
  }
}
