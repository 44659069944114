import { apiUrl } from '../config/api'

export const LogService = () => {
  const listLogs = async (
    page: number,
    limit: number,
    search: string,
    filters: string,
    extra: string
  ) => {
    return (await apiUrl.get(`/logger/list/${extra}?limit=${limit}&page=${page}`)).data
  }

  return {
    listLogs,
  }
}
