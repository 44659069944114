/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query'
import { FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { PermissionService } from '../../services'
import { allChildrenChecked, getPermissions, getPermissionsSelected } from '../helpers/Permission'
import { ICreateRoleV2, IRoleListDropdown } from '../interfaces'

interface IProps {
  formProps?: FormikProps<ICreateRoleV2>
  mode: 'view' | 'create' | 'edit'
  defaultData?: IRoleListDropdown[]
}

const NAME_LISTAR = 'Listar'

export const usePermissionCheckboxV2 = ({ formProps, mode = 'view', defaultData }: IProps) => {
  const [permissionList, setPermissionList] = useState<IRoleListDropdown[]>([])
  const [permissionsSelected, setPermissionsSelected] = useState<number[]>([])

  const { data, isLoading, isFetching } = useQuery(
    ['permission-group'],
    () => PermissionService().getPermissionsBackoffice({ isGrouped: true }),
    {
      select: (data) => getPermissions(data.permissions),
      enabled: mode === 'create' && !defaultData,
    }
  )

  const handlePermissionsId = (selected: boolean, id: number) => {
    setPermissionsSelected((prevSelected: number[]) => {
      if (!selected) {
        return prevSelected.filter((o) => o !== id)
      }
      if (!prevSelected.includes(id)) {
        return [...prevSelected, id]
      }
      return prevSelected
    })
  }

  const updateChildrenCheckedStatus = (
    children: IRoleListDropdown[],
    checked: boolean
  ): IRoleListDropdown[] => {
    return children.map((child) => {
      if (child.id && !isNaN(Number(child.id))) handlePermissionsId(checked, child.id)
      return {
        ...child,
        selected: checked,
        children: child.children ? updateChildrenCheckedStatus(child.children, checked) : undefined,
      }
    })
  }

  const updateListarSiblingCheckedStatus = (
    items: IRoleListDropdown[],
    itemId: number,
    checked: boolean
  ) => {
    const listarIndex = items.findIndex((sibling) => sibling.name === NAME_LISTAR)
    if (listarIndex === -1) return

    const listar = items[listarIndex]
    const hasASiblingChecked = items.some((item) => item.id !== itemId && item.selected)
    const shouldUpdateListar = checked || hasASiblingChecked

    if (listar.id && shouldUpdateListar !== listar.selected) {
      handlePermissionsId(shouldUpdateListar, listar.id)
      items[listarIndex] = { ...listar, selected: shouldUpdateListar }
    }
  }

  const updateCheckedStatus = (
    items: IRoleListDropdown[],
    id: number | string,
    checked: boolean
  ): IRoleListDropdown[] => {
    let selectedItem = { found: false, id: 0 }

    const updatedItems = items.map((item) => {
      const matchesId = !isNaN(Number(id)) && item.id === Number(id)
      const matchesName = typeof id === 'string' && item.name === id

      if (matchesId) {
        const isListarItem = item.name === NAME_LISTAR
        if (!isListarItem) {
          selectedItem = { found: true, id: item.id! }
        }

        const hasASiblingChecked = items.some(
          (sibling) => sibling.id !== item.id && sibling.selected
        )
        if (hasASiblingChecked && isListarItem) {
          return { ...item, selected: true }
        }
        handlePermissionsId(checked, item.id!)
        return { ...item, selected: checked }
      }

      if (matchesName) {
        return {
          ...item,
          children: item.children ? updateChildrenCheckedStatus(item.children, checked) : undefined,
        }
      }

      if (item.children) {
        return { ...item, children: updateCheckedStatus(item.children, id, checked) }
      }

      return item
    })

    if (selectedItem.found && selectedItem.id) {
      updateListarSiblingCheckedStatus(updatedItems, selectedItem.id, checked)
    }
    return updatedItems
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target
    setPermissionList((prevPermissions) =>
      updateCheckedStatus(prevPermissions, value || name, checked)
    )
  }

  useEffect(() => {
    if (data && data.length > 0) {
      setPermissionList(data)
    }
  }, [data])

  useEffect(() => {
    formProps?.setFieldValue('permissions', permissionsSelected)
  }, [permissionsSelected])

  useEffect(() => {
    if (defaultData && (mode === 'edit' || mode === 'view')) {
      setPermissionList(defaultData)
      setPermissionsSelected(getPermissionsSelected(defaultData))
    }
  }, [defaultData, mode])

  const renderRoles = (role: IRoleListDropdown, keyId: string, hasHeader: boolean) => {
    const { id, name, children } = role
    return (
      <>
        {hasHeader && (
          <div className='d-flex justify-content-between'>
            <label
              className='btn border-0 form-check form-check-custom form-check-solid gap-2 p-0'
              id={`head${keyId}`}
            >
              <input
                className='form-check-input rounded-1'
                type='checkbox'
                name={name}
                value={id}
                checked={allChildrenChecked(children ?? [])}
                onChange={handleCheckboxChange}
                disabled={mode === 'view'}
              />
              <span className='form-check-label fs-5 fw-bold text-start'>{name}</span>
            </label>
            <button
              type='button'
              className='accordion-button w-auto p-0 cursor-pointer'
              data-bs-toggle='collapse'
              data-bs-target={`#${keyId}`}
              aria-expanded='true'
              aria-controls={keyId}
            ></button>
          </div>
        )}
        <div
          id={keyId}
          className={`${hasHeader ? 'ms-8 accordion-collapse collapse show' : ''}`}
          aria-labelledby={`head${keyId}`}
          data-bs-parent='accordionPermissions'
        >
          {children &&
            children.length > 0 &&
            children.map((childRole, childIndex) => {
              const {
                id: childId,
                name: childName,
                selected: childSelected,
                nameFormatted: childNameFormatted,
              } = childRole
              const keyIdChildren = `${keyId}-${childNameFormatted}-${childIndex}`
              const hasHeaderChildren = children?.length !== 1
              return (
                <div key={keyIdChildren}>
                  {children.every((o) => o.id) ? (
                    <>
                      <label className='form-check form-check-custom form-check-solid gap-2 cursor-pointer mb-3'>
                        <input
                          className='form-check-input rounded-1'
                          type='checkbox'
                          name={childName}
                          value={childId}
                          checked={childSelected}
                          onChange={handleCheckboxChange}
                          disabled={mode === 'view'}
                        />
                        <span className='form-check-label'>{childName}</span>
                      </label>
                    </>
                  ) : (
                    renderRoles(childRole, keyIdChildren, hasHeaderChildren)
                  )}
                </div>
              )
            })}
        </div>
      </>
    )
  }

  const permissionTable = () => (
    <>
      {(isLoading || isFetching) && !defaultData ? (
        <div className='text-center m-5 p-10'>
          <div className='spinner-border' role='status'></div>
        </div>
      ) : (
        <table className='table borderless'>
          <thead>
            <tr className='text-muted fw-bolder fs-3'>
              {permissionList?.length > 0 &&
                permissionList.map((permission, index) => {
                  const { id, name, nameFormatted, children } = permission
                  const keyIdHead = `head-${nameFormatted}-${index}`
                  return (
                    <th key={keyIdHead} className='min-w-300px'>
                      <label className='form-check form-check-custom form-check-solid gap-2 cursor-pointer'>
                        <input
                          className='form-check-input rounded-1'
                          type='checkbox'
                          name={name}
                          value={id}
                          checked={allChildrenChecked(children ?? [])}
                          onChange={handleCheckboxChange}
                          disabled={mode === 'view'}
                        />
                        <span className='form-check-label text-start'>{name}</span>
                      </label>
                    </th>
                  )
                })}
            </tr>
          </thead>
          <tbody className='borderless'>
            <tr>
              {permissionList?.length > 0 &&
                permissionList.map((permission, index) => (
                  <td key={`${permission.nameFormatted}-${index}`} className='pe-10'>
                    <div className='d-flex flex-column gap-8 accordion' id='accordionPermissions'>
                      {permission.children?.map((child, childIndex) => {
                        const keyId = `${child.nameFormatted}-${index}-${childIndex}`
                        const hasHeader = permission.children?.length !== 1
                        return (
                          <div
                            className='d-flex flex-column ms-8 gap-3 accordion-item border-0'
                            key={keyId}
                          >
                            {renderRoles(child, keyId, hasHeader)}
                          </div>
                        )
                      })}
                    </div>
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
      )}
    </>
  )

  return {
    setPermissionList,
    permissionTable,
  }
}
