import React, { useState } from 'react'
import { UpdatePasswordModal } from '../../common/components/UpdatePasswordModal'
import { useDebounce } from '../../common/hooks/useDebounce'
import { useStateCallback } from '../../common/hooks/useStateCallback'
import { ClientsTable } from '../clients/components/ClientsTable'
import { ModalConfirm } from '../clients/components/ModalConfirm'

export const ListProspects = () => {
  const [search, setSearch] = useStateCallback('')
  const debouncedSearch = useDebounce(search, 500)
  const [selectedUser, setSelectedUser] = useStateCallback<any>({})
  const [changedProspect, setChangedProspect] = useState([])
  const [changedUser, setChangedUser] = useStateCallback(false)

  return (
    <React.Fragment>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 align-items-center'>
          <div className='text-center position-relative mt-4'>
            <input
              className='form-control'
              type='text'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder='Buscador'
              style={{
                width: '100%',
                minWidth: '320px',
                backgroundColor: '#f9f9f9',
                border: 0,
                height: '40px',
                paddingLeft: 40,
              }}
              name='searchName'
            />
            <i
              className='bi bi-search'
              style={{
                position: 'absolute',
                top: '50%',
                left: 15,
                transform: 'translateY(-50%)',
              }}
            ></i>
          </div>
        </div>

        {selectedUser && (
          <UpdatePasswordModal
            setChangedProspect={setChangedProspect}
            prospect
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
        )}
        <ModalConfirm
          id='Active'
          title='¿Está seguro que desea activar el cliente?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, activar'
          confirmationId='#ActiveDelete'
          type='action'
          selectedValue={selectedUser}
          setChangedValue={setChangedUser}
          changedValue={changedUser}
          confirmation={true}
          confirmationTitle='activar'
          confirmationAnswer='Aceptar'
          updateStatusUrl='/user/app/change-status/'
        />

        <ModalConfirm
          id='Inactive'
          title='¿Está seguro que desea activar el cliente?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, inactivar'
          confirmationId='#InactiveDelete'
          type='action'
          selectedValue={selectedUser}
          setChangedValue={setChangedUser}
          changedValue={changedUser}
          confirmation={true}
          confirmationTitle='inactivar'
          confirmationAnswer='Aceptar'
          updateStatusUrl='/user/app/change-status/'
        />
        {/* <FundsModal
          title='Fondos invertidos'
          UserId={selectedUser.id}
          id='Funds' /> */}
        <ClientsTable
          setSelectedUser={setSelectedUser}
          changedUser={changedUser}
          setSearch={setSearch}
          search={search.length > 2 ? debouncedSearch! : ''}
          changedProspect={changedProspect}
          urlEndpoint='/user/app/list'
          prospect
        />
      </div>
    </React.Fragment>
  )
}
