/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { MultiSelect, Option } from 'react-multi-select-component'
import { dateRangePickerDefault } from '../../../../common/helpers/DateHandlers'
import { StatusEnum } from '../../../../common/interfaces'
import { PermissionService } from '../../../../services'
import { IFilters } from '../ListRole'

interface IProps {
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
}

export const FilterRoles = ({ filters, setFilters }: IProps) => {
  const [localFilters, setLocalFilters] = useState<IFilters>({})

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setLocalFilters((prev) => ({ ...prev, [name]: value }))
  }

  const handleMultiSelect = (dataRaw: Option[], name: string) => {
    const data = dataRaw.map((o) => o.value)
    setLocalFilters((prev) => ({ ...prev, [name]: data }))
  }

  const handleConfirmar = () => {
    setFilters(localFilters)
  }

  const {
    data: permissions,
    isLoading,
    isFetching,
  } = useQuery(['permission-list'], () => PermissionService().getPermissionsBackoffice(), {
    keepPreviousData: true,
    select: (res) => getPermissions(res),
  })

  useEffect(() => {
    setLocalFilters(filters)
    if (filters.updated_at_start === undefined && filters.updated_at_end === undefined) {
      $('input[name="daterange"]').trigger('cancel.daterangepicker')
    }
  }, [JSON.stringify(filters)])

  useEffect(() => {
    //@ts-ignore
    $(function () {
      //@ts-ignore
      $('input[name="daterange"]').daterangepicker({ ...dateRangePickerDefault, drops: 'up' })
      $('input[name="daterange"]').on('apply.daterangepicker', function (_, picker) {
        $(this).val(
          picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY')
        )
        setLocalFilters((prevState) => ({
          ...prevState,
          updated_at_start: moment(picker.startDate).format('YYYY-MM-DD'),
          updated_at_end: moment(picker.endDate).format('YYYY-MM-DD'),
        }))
      })

      $('input[name="daterange"]').on('cancel.daterangepicker', function () {
        $(this).val('')
        $('input[name="daterange"]').data('daterangepicker').setStartDate(moment())
        $('input[name="daterange"]').data('daterangepicker').setEndDate(moment())
        setLocalFilters((prevState) => {
          const { updated_at_start, updated_at_end, ...prev } = prevState
          return { ...prev }
        })
      })
    })
  }, [])

  return (
    <div
      className='modal modal_outer right_modal fade scroll'
      id='filter-roles'
      role='dialog'
      aria-labelledby='modalRoleLabelled'
    >
      <div className='modal-dialog w-75 w-sm-50 w-md-25' role='document'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h2 className='text-alert mb-0'>Filtros</h2>
            <i className='bi bi-x-lg fs-5 cursor-pointer' data-bs-dismiss='modal' />
          </div>
          <div className='modal-body d-flex flex-column gap-10'>
            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Nombre del rol</label>
              <input
                type='text'
                className='form-control form-control-solid'
                placeholder='Ingrese nombre del rol'
                name='name'
                value={localFilters.name ?? ''}
                onChange={handleChange}
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Permisos</label>
              <MultiSelect
                labelledBy='Seleccionar'
                value={
                  permissions
                    ? permissions?.filter((o: Option) =>
                        localFilters.permissions?.includes(o.value)
                      )
                    : []
                }
                className='multiselect'
                hasSelectAll={false}
                options={permissions ?? []}
                onChange={(e: Option[]) => handleMultiSelect(e, 'permissions')}
                overrideStrings={SPANISH_STRING}
                isLoading={isLoading || isFetching}
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Estado</label>
              <MultiSelect
                labelledBy='Seleccionar'
                value={STATUS_OPTIONS.filter((o: Option) => localFilters.status?.includes(o.value))}
                className='multiselect'
                hasSelectAll={false}
                options={STATUS_OPTIONS}
                onChange={(e: Option[]) => handleMultiSelect(e, 'status')}
                overrideStrings={SPANISH_STRING}
              />
            </div>

            <div className='form-group'>
              <label className='text-muted fs-6 mb-2'>Fecha de último estado</label>
              <input
                type='text'
                className='form-control form-control-solid'
                name='daterange'
                placeholder='Selecciona una fecha'
                style={{ zIndex: 99999 }}
                autoComplete='off'
              />
            </div>
          </div>
          <div className='modal-footer py-2'>
            <div className='text-end'>
              <button
                style={{ backgroundColor: '#eee', color: '#969696' }}
                type='button'
                className='btn col m-3'
                data-bs-dismiss='modal'
              >
                Cancelar
              </button>
              <button
                style={{
                  background: 'rgb(0, 123, 195)',
                  color: 'white',
                }}
                className='btn col m-3'
                data-bs-dismiss='modal'
                aria-label='Close'
                type='button'
                onClick={handleConfirmar}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const STATUS_OPTIONS = [
  { value: StatusEnum.ACTIVATED, label: 'Activo' },
  { value: StatusEnum.DISABLED, label: 'Inactivo' },
]

const DEFAULT_OPTION = [{ value: '', label: 'Cargando ...' }]

const SPANISH_STRING = {
  allItemsAreSelected: 'Todos seleccionados',
  clearSearch: 'Limpiar búsqueda',
  clearSelected: 'Limpiar búsqueda',
  noOptions: 'Sin opciones',
  search: 'Buscar',
  selectAll: 'Seleccionar todo',
  selectAllFiltered: 'Seleccionar todo (Filtrado)',
  selectSomeItems: 'Seleccionar',
  create: 'Crear',
}

const getPermissions = (res: any) => {
  return res
    ? res.permissions?.map((o: { name: string; id: number }) => ({ label: o.name, value: o.id }))
    : []
}
