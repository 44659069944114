import { useQuery } from '@tanstack/react-query'
import Dropzone from 'dropzone'
import { FC, useLayoutEffect, useState } from 'react'
import { DocumentTagEnum } from '../../../common/interfaces'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
import { FundService } from '../../../services'
import { uploadFiles } from '../helpers/files.helper'

interface IProps {
  codFund: string
}

Dropzone.autoDiscover = false

export const UploadFiles: FC<IProps> = ({ codFund }) => {
  const [files, setFiles] = useState(uploadFiles)
  const documentQuery = useQuery({
    queryKey: ['documents', codFund],
    queryFn: () => FundService().listFundDocuments(codFund),
    onSuccess: (data) => {
      const newFiles = uploadFiles.map((uploadFile) => {
        const matchedItem = data.find((item: any) => item.document_tag === uploadFile.document_tag)

        if (matchedItem) {
          return {
            ...uploadFile,
            constancy_filename: matchedItem.constancy_filename,
            constancy_url: matchedItem.constancy_url,
            sign: matchedItem.sign,
          }
        }

        return uploadFile // If no match is found, return the original object
      })
      setFiles(newFiles)
    },
    enabled: !!codFund,
  })

  useLayoutEffect(() => {
    files.forEach((e) => {
      const id = '#dropzone_files' + e.document_tag

      var myDropzone = new Dropzone(id, {
        // Make the whole body a dropzone
        url: process.env.REACT_APP_BACK_API_URL + '/fund/bo/upsert-document', // Set the url for your upload script location
        parallelUploads: 1,
        maxFilesize: 20, // Max filesize in MB
        maxFiles: 1,
        clickable: id + ' .dropzone-select', // Define the element that should be used as click trigger to select files.
        createThumbnail: true,
        dictDefaultMessage: '',
        dictFileTooBig: '',
        dictInvalidFileType: '',
      })

      myDropzone.on('addedfile', function (file: any) {
        const formData = new FormData()

        if (!file.name.match(/[À-ÿ]/u)) {
          formData.append('file', file, file.name.replaceAll(' ', ''))

          formData.append('codFund', codFund)
          formData.append('document_tag', e.document_tag)

          apiUrl
            .post(process.env.REACT_APP_BACK_API_URL + '/fund/bo/upsert-document', formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then((e) => {
              document.querySelector('.dz-preview')?.remove()
              documentQuery.refetch()
              customSwal.fire({
                icon: 'success',
                title: '¡El archivo se subió con éxito!',
              })
            })
        } else {
          document.querySelector('.dz-preview')?.remove()
          customSwal.fire({
            icon: 'error',
            title: 'El archivo no es válido, asegúrate que no contenga caracteres inválidos.',
          })
        }
      })

      // Hide the total progress bar when nothing"s uploading anymore
      myDropzone.on('complete', function (file: any) {
        if (file.status === 'success') {
          customSwal.fire({
            icon: 'success',
            title: 'Se adjuntó el archivo con éxito',
          })
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='card mt-10 p-10'>
      <div className='d-flex align-items-center justify-content-between'>
        <span className='card-title text-start mt-2 mb-5 text-gray-800 fs-2'>Adjuntos</span>
      </div>
      <table className='table table-row-dashed table-row-gray-300 gy-7'>
        <thead>
          <tr className='fw-bolder fs-6 text-gray-700'>
            <th>DOCUMENTO</th>
            <th className='text-center'>ACCIÓN</th>
            <th className='text-center'>FIRMA</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file, index) => {
            return (
              <>
                <tr key={index}>
                  <td>
                    <span className='text-muted' style={{ marginBottom: '5px' }}>
                      {file.placeholder}
                    </span>

                    <input
                      type='text'
                      className='form-control mb-2 mb-md-0'
                      title={file.placeholder}
                      placeholder={file.placeholder}
                      value={file.constancy_filename}
                      disabled
                    />
                  </td>
                  <td className='text-center' id={'dropzone_files' + file.document_tag}>
                    <button
                      style={{
                        background: 'transparent',
                        color: 'white',
                        border: '1px solid #e1e3ea',
                        marginTop: '15px',
                      }}
                      type='button'
                      className='dropzone-select btn btn-icon ml-3'
                    >
                      <i className='bi bi-upload text-muted fs-3'></i>
                    </button>
                  </td>

                  <td className='text-center'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '25px',
                        paddingBottom: '15px',
                      }}
                      className='form-check form-check-custom form-check-solid'
                    >
                      <input
                        style={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                        }}
                        disabled
                        checked={file.document_tag === DocumentTagEnum.CONTRATO_ADMINISTRACION}
                        className='form-check-input'
                        type='checkbox'
                      />
                    </div>
                  </td>
                </tr>
              </>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
