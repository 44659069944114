import { useQuery } from '@tanstack/react-query'
import { ErrorMessage, Field, FormikProps } from 'formik'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { IUpdateClient } from '../../../common/interfaces/client.interface'
import { IClientShow } from '../../../common/interfaces/user.interface'
import { apiUrl } from '../../../config/api'

interface IProps {
  formProps: FormikProps<IUpdateClient>
}

export const EditClientForm = ({ formProps }: IProps) => {
  const { id } = useParams()
  const { isLoading: isLoadingClient, data: client } = useQuery<IClientShow>(
    ['client-show', id],
    () => showClient()
  )

  useEffect(() => {
    if (!isLoadingClient && client) {
      formProps.setFieldValue('username', client.data.username)
      formProps.setFieldValue('email', client.data.email)
      formProps.setFieldValue('phone_number', client.data.phone_number)
      formProps.setFieldValue('number_document', client.data.number_document)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingClient, client])

  const showClient = () => apiUrl.get(`/user/app/show/${id}`).then((res) => res.data)

  return (
    <React.Fragment>
      <div className='card-header border-0 '>
        <div className='card-title align-items-start flex-column'>
          <div className='d-flex flex-column'>
            <span style={{ color: '#98a2b3', fontSize: '16px' }} className='mt-1 mb-0 fw-bold'>
              Editar Cliente
            </span>
            <span
              style={{ color: '#344054', letterSpacing: '0.5px', fontSize: '24px' }}
              className='card-label fw-bolder mb-1 '
            >
              Datos Generales
            </span>
          </div>
        </div>
      </div>
      <div className='mt-10'>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Nombre de usuario</label>

                <Field
                  placeholder='Ingrese nombre de usuario'
                  name='username'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='username' />
                </div>
              </div>

              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Correo electrónico</label>

                <Field
                  placeholder='example@example.com'
                  name='email'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='email' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Número de teléfono</label>

                <Field
                  placeholder='Ingrese número de teléfono'
                  name='phone_number'
                  validate={(value: any) => {
                    if (!/^\+?519[0-9]{8}$/.test(value)) {
                      return 'Formato incorrecto'
                    }
                  }}
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='phone_number' />
                </div>
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Número de documento</label>

                <Field
                  placeholder='Ingrese número de documento'
                  name='number_document'
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='number_document' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <div className='col-lg-12'>
            <div className='row'>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Contraseña</label>

                <Field
                  placeholder='Ingrese una contraseña'
                  name='password'
                  type='password'
                  validate={(value: any) => {
                    if (Number.isNaN(Number(value))) {
                      return 'Debe ser solo numérico'
                    }
                  }}
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='password' />
                </div>
              </div>
              <div className='col-lg-6 fv-row'>
                <label className='form-label required'>Confirmar Contraseña</label>

                <Field
                  placeholder='Confirme la contraseña'
                  name='passwordConfirmation'
                  type='password'
                  validate={(value: any) => {
                    if (Number.isNaN(Number(value))) {
                      return 'Debe ser solo numérico'
                    }
                  }}
                  className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='passwordConfirmation' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
