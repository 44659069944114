import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { ICustomerDetail } from '../../../../common/interfaces'
import { apiUrl } from '../../../../config/api'
import { FileService } from '../../../../services/FileService'
import { RiskProfileDocumentTable } from '../tables/RiskProfileDocumentTable'

interface IProps {
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  customerDetail: ICustomerDetail | undefined
}

function getFileNameFromBucketPath(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.length)
}

export const ProfileTab: React.FC<IProps> = ({
  customerDetail,
  isError,
  isFetching,
  isLoading,
}) => {
  const [isLoadingDownload, setIsLoading] = useStateCallback(false)
  const [fileUrl, setFileUrl] = useStateCallback('')

  const downloadMutation = useMutation({
    mutationFn: (fileUrl2: string) => FileService().downloadFromAzure(fileUrl2),
    onSuccess: async (data) => {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileNameFromBucketPath(fileUrl))

      document.body.appendChild(link)
      link.click()

      await new Promise((resolve) => setTimeout(resolve, 1000))

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
      setIsLoading(false)
    },
  })
  const downloadDocument = () => {
    setIsLoading(true)
    apiUrl
      .post('/app/customer/download-customer-pdr', {
        customer_id: customerDetail?.id,
      })
      .then((res) => {
        setFileUrl(res.data, (newFileUrl) => {
          downloadMutation.mutate(newFileUrl)
        })
      })
  }
  return (
    <>
      <div className='d-flex justify-content-between gap-5'>
        <div className='card col-sm-12 p-10'>
          <div className='d-flex justify-content-between'>
            <h6 className='text-muted fs-4' style={{ color: 'rgb(52, 64, 84)' }}>
              Documentos
            </h6>
            {customerDetail?.questionsAnswers.questions && (
              <button
                style={{ background: 'rgb(0, 123, 195)' }}
                className='btn text-white cursor-pointer'
                data-bs-toggle='modal'
                data-bs-target='#QuestionAnswers'
              >
                Ver respuestas
              </button>
            )}
          </div>
          <div className='table-responsive mt-5'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-20 p-20'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <RiskProfileDocumentTable customerDetail={customerDetail!} />
            )}
          </div>
        </div>
      </div>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id='QuestionAnswers' role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header py-5 mt-2 fw-bolder'>
              <h1 className='text-alert'>Respuestas PDR</h1>
              <button
                type='button'
                className='close border-0 top-0'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <img
                  src={`${toAbsoluteUrl('/✅ Web - Mm/Close.png')}`}
                  alt='Close icon'
                  aria-hidden='true'
                  style={{
                    width: 10,
                    height: 10,
                  }}
                />
              </button>
            </div>

            <div className='modal-body'>
              <div>
                {customerDetail?.questionsAnswers.questions &&
                  customerDetail?.questionsAnswers.questions.map((e: any, i: number) => {
                    return (
                      <div key={i} className='pt-2'>
                        <h6 className='text-alert fs-4'>
                          {i + 1} {e.title}
                        </h6>
                        <p>
                          {
                            customerDetail?.questionsAnswers.options.find(
                              (option: any) => option.questionsId === e.id
                            ).title
                          }
                        </p>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className='modal-footer'>
              <button
                style={{ background: 'rgb(0, 123, 195)' }}
                className='btn text-white cursor-pointer'
                onClick={() => downloadDocument()}
                disabled={isLoadingDownload}
              >
                {isLoadingDownload ? 'Descargando...' : 'Descargar respuestas'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
