/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { IUser } from '../../../../common/interfaces/user.interface'
import { apiUrl } from '../../../../config/api'

interface IProps {
  search: string
  setSelectedUser: React.Dispatch<React.SetStateAction<any>>
  changedUser: boolean
  setSearch: any
}

const pageSizes = [10, 20, 40, 100]

const listUsers = (page: number | string, limit: number | string, search: string) =>
  apiUrl
    .post(`/user/person/list?page=${page}&limit=${limit}`, `&search=${search}`)
    .then((res) => res.data)

export const UserTable = ({ search, setSelectedUser, changedUser, setSearch }: IProps) => {
  const { hasPermission } = usePermissions()
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: { data: users, isLoading, isFetching, refetch, isError },
  } = usePaginator({
    initPage: 1,
    initLimit: 10,
    paginatorOptions: {
      queryKey: ['user-list'],
      fetch: listUsers,
    },
    search,
    pageSizes,
    queryOptions: {
      retry: false,
    },
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, changedUser, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('', () => {
      refetch()
    })
  }

  return (
    <div className='card-body'>
      <div className='table-responsive'>
        {(isLoading || isFetching) && !isError ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                {hasPermission([
                  Permissions.USER_PERSON_SHOW,
                  Permissions.USER_PERSON_UPDATE,
                  Permissions.USER_PERSON_DELETE,
                ]) && <th className='w-25px'>ACCIONES</th>}

                <th className='min-w-150px'>NOMBRES Y APELLIDOS</th>
                <th className='min-w-150px'>CORREO</th>
                <th className='min-w-120px'>VERIFICADO</th>
                <th className='min-w-120px'>TELÉFONO</th>
                <th className='min-w-100px'>ESTADO</th>
              </tr>
            </thead>

            <tbody>
              {users?.data.map((user: IUser, index: number) => {
                return (
                  <tr key={index}>
                    {hasPermission([
                      Permissions.USER_PERSON_SHOW,
                      Permissions.USER_PERSON_UPDATE,
                      Permissions.USER_PERSON_DELETE,
                    ]) && (
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <div className='dropdown'>
                            <button
                              className='btn btn-light'
                              type='button'
                              id='dropdownMenuButton'
                              data-bs-toggle='dropdown'
                              aria-haspopup='true'
                              aria-expanded='false'
                            >
                              <i className='fas p-0 fa-ellipsis-v'></i>
                            </button>
                            <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                              {hasPermission(Permissions.USER_PERSON_SHOW) && (
                                <Link to={`/user/show/${user.id}`} className='dropdown-item'>
                                  <i className='bi bi-eye m-2'></i>Ver detalle
                                </Link>
                              )}
                              {hasPermission(Permissions.USER_PERSON_UPDATE) && (
                                <Link to={`/user/edit/${user.id}`} className='dropdown-item'>
                                  <i className='bi bi-pencil m-2'></i> Editar
                                </Link>
                              )}
                              {hasPermission(Permissions.USER_PERSON_UPDATE) && (
                                <>
                                  {user.status === 1 ? (
                                    <button
                                      type='button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#Inactive'
                                      className='dropdown-item'
                                      onClick={() => setSelectedUser(user)}
                                    >
                                      <i className='bi bi-lock m-2'></i>Inactivar
                                    </button>
                                  ) : (
                                    <button
                                      type='button'
                                      data-bs-toggle='modal'
                                      data-bs-target='#Active'
                                      className='dropdown-item'
                                      onClick={() => setSelectedUser(user)}
                                    >
                                      <i className='bi bi-unlock m-2'></i>Activar
                                    </button>
                                  )}
                                </>
                              )}
                              {/* <button
                                type='button'
                                data-bs-toggle='modal'
                                data-bs-target='#Archive'
                                className='dropdown-item'
                                onClick={() => setSelectedUser(user)}
                              >
                                <img
                                  alt='arch'
                                  className='m-2'
                                  src={toAbsoluteUrl('/✅ Web - Mm/archivar.png')}
                                />
                                Archivar
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </td>
                    )}
                    <td>
                      {hasPermission(Permissions.USER_PERSON_SHOW) ? (
                        <Link
                          to={`/user/show/${user.id}`}
                          className='text-dark text-hover-primary fs-6'
                        >
                          {user.name}
                        </Link>
                      ) : (
                        user.name
                      )}
                      <span className='text-muted d-block fs-5'>{user.lastname}</span>
                    </td>

                    <td>
                      <span className='text-muted d-block fs-5'>{user.email}</span>
                    </td>
                    <td>
                      <span className='text-muted d-block fs-5'>
                        {user.email_verified ? 'Verificado' : 'Sin Verificar'}
                      </span>
                    </td>
                    <td>
                      <span className='text-muted d-block fs-5'>{user.phone_number || '--'}</span>
                    </td>
                    <td>
                      {user.status === 0 ? (
                        <span className='badge badge-light-danger'>Inactivo</span>
                      ) : user.status === 1 ? (
                        <span className='badge badge-light-success'>Activo</span>
                      ) : (
                        <span className='badge badge-light-warning'>Archivado</span>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
        {isError && (
          <span className='notFound text-muted text-muted fs-5 text-center mt-5'>
            No se encontraron resultados
            <a
              className='link'
              style={{
                marginLeft: 5,
                cursor: 'pointer',
              }}
              onClick={cleanupRequest}
            >
              refrescar la tabla
            </a>
          </span>
        )}
        {users?.data.length === 0 && !isLoading && (
          <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
            No se encontraron resultados
            <a
              className='link'
              style={{
                marginLeft: 5,
                cursor: 'pointer',
              }}
              onClick={cleanupRequest}
            >
              mostrar todos los usuarios
            </a>
          </span>
        )}
      </div>
      {paginationComponent()}
    </div>
  )
}
