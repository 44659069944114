import React from 'react'
import { ShowForms } from './ShowForms'

export const FormsList = () => {
  return (
    <React.Fragment>
      {/* <div className='row g-5'>
        <div className='col-lg-3'>
          <Link
            to='/configuration/forms/create'
            className='card card-stretch-50 card-bordered mb-5'
            style={{ height: '200px' }}
          >
            <div className='card text-center' style={{ height: '100%' }}>
              <div className='text-center' style={{ margin: 'auto' }}>
                <img src={toAbsoluteUrl('/✅ Web - Mm/ícono.svg')} alt='fef3' />

                <div
                  className='card-title fw-bold col text-center fs-1 mt-3'
                  style={{ color: '#007BC3' }}>
                  Agregar formulario
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className='col-lg-3'>
          <div
            className='card p-5 m-1'
            style={{
              boxShadow: '0px 0px 20px rgba(56, 71, 109, 0.03)',
              height: '200px',
            }}
          >
            <div>
              <div
                style={{
                  justifyContent: 'space-between',
                }}
                className='d-flex align-items-center  mt-3'
              >
                <h3
                  style={{
                    color: '#344054',
                    fontSize: '20px',
                  }}
                >
                  nombre
                </h3>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <div className='dropdown'>
                    <button
                      className='btn btn-light d-flex justify-content-center align-items-center'
                      style={{
                        width: '32px',
                        height: '32px',
                        padding: 0,
                      }}
                      type='button'
                      id='dropdownMenuButton'
                      data-bs-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <i style={{ color: '#0047ad' }} className='fas fa-ellipsis-v'></i>
                    </button>

                    <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                      <Link
                        to={''}
                        style={{ color: '#728097' }}
                        className='dropdown-item'
                      >
                        <i className='bi bi-eye m-2'></i>Ver detalle
                      </Link>
                      <Link
                        to={'/configuration/forms/create'}
                        style={{ color: '#728097' }}
                        className='dropdown-item'
                      >
                        <i className='bi bi-pencil m-2'></i> Editar
                      </Link>
                      <button

                        style={{ color: '#728097' }}
                        className='dropdown-item'
                      >
                        <i className='bi bi-lock m-2'></i> Inactivar
                      </button>

                    </div>
                  </div>
                </div>

                <div
                  className='border-top-0 card-footer'
                  style={{
                    width: '100%',
                    paddingLeft: '0px',
                    position: 'absolute',
                    display: 'flex',
                    bottom: '0px',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className=' m-0 p-0'>
                    <span
                      style={{
                        color: ' #5e6278',
                        backgroundColor: '#f8f8f8',
                        fontSize: '14px',
                      }}
                      className='badge text-start'
                    >
                      24/06/23
                    </span>
                  </div>
                  <div>

                    <span className='badge badge-light-danger'>Inactivo</span>

                    <span className='badge badge-light-success'>Activo</span>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> */}
      <ShowForms />
    </React.Fragment>
  )
}
