/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { getDDMMYYYYFormat, getHHMMFormat } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import {
  IRescue,
  MoneyTypeEnum,
  RescueStatusEnum,
} from '../../../../common/interfaces/movement.interface'
import { RescueService } from '../../../../services/RescueService'
import { formatCustomerFulllname } from '../../../clients/helpers/customerFormatHelper'
import { RescueDetailCollapsible } from '../collapsible/RescueDetailCollapsible'
import { IFilters } from '../tabs/RescueTab'

interface IProps {
  search: string
  setSearch: any
  selectedRescues: number[]
  setSelectedRescues: any
  filters: IFilters
  setFilters: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
  mutationSuccess: boolean
}

const pageSizes = [10, 20, 40, 100]

function compareArray(sourceArray: any[], targetArray: any[]): boolean {
  if (sourceArray.length === 0) {
    return false
  }

  return sourceArray.some((item) => targetArray.includes(item))
}

export const RescueTable: FC<IProps> = ({
  search,
  setSearch,
  selectedRescues,
  setSelectedRescues,
  filters,
  setFilters,
  mutationSuccess,
}) => {
  const [currentRescue, setCurrentRescue] = useStateCallback<number>(0)
  const [activesRescues, setActivesRescues] = useStateCallback<any>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCollapsible, setSelectedCollapsible] = useStateCallback<any>([])
  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: {
      data: rescues,
      refetch,
      isRefetching,
      isLoading: isLoadingRescues,
      isError,
      isFetching,
    },
  } = usePaginator({
    initPage: 1,
    initLimit: 10,
    search,
    pageSizes,
    paginatorOptions: {
      queryKey: ['rescues-list'],
      fetch: RescueService().listRescues,
    },
    queryOptions: {
      retry: false,
    },
    filters,
  })

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), mutationSuccess, search])

  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters(
      {
        start_date: '',
        end_date: '',
      },
      () => {
        refetch()
      }
    )
  }

  const handleClickCheckbox = (id: number) => {
    setSelectedRescues((prev: number[]) => {
      const index = prev.indexOf(id)
      if (index === -1) {
        return [...prev, id]
      }
      return [...prev.slice(0, index), ...prev.slice(index + 1)]
    })
  }
  useEffect(() => {
    $(function () {
      $('[data-bs-toggle="tooltip"]').tooltip()
    })
  }, [])

  return (
    <div className='card-body'>
      <div className='table-responsive'>
        {((isLoadingRescues || isFetching) && !isError) || isRefetching ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status'></div>
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                <th className='w-75px'></th>

                <th style={{ width: 130 }}>FECHA Y HORA</th>
                <th className='min-w-120px'>CLIENTE</th>
                <th className='min-w-100px'>FONDO</th>
                <th className='min-w-120px'>MONTO</th>
                <th className='min-w-150px'>CUENTA DESTINO</th>
                <th className='min-w-150px new-tooltip'>
                  <span className='tooltiptext'>Código de cuenta interbancario</span>
                  CCI
                </th>

                <th className='min-w-150px'>BANCO DESTINO</th>
                <th className='min-w-120px'>ESTADO</th>
                <th className='w-75px'></th>
              </tr>
            </thead>

            <tbody>
              {rescues?.data &&
                rescues
                  ?.data!.sort(
                    (a: IRescue, b: IRescue) =>
                      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                  )
                  .map((rescue: IRescue, index: number) => {
                    let currentDate = new Date(rescue.created_at)

                    let day =
                      currentDate.getDate() > 9
                        ? currentDate.getDate()
                        : '0' + currentDate.getDate()
                    let month =
                      currentDate.getMonth() > 8
                        ? currentDate.getMonth() + 1
                        : '0' + (currentDate.getMonth() + 1)

                    return (
                      <>
                        <tr
                          className='collapsible cursor-pointer '
                          onClick={() => {
                            setActivesRescues((prev: number[]) => {
                              const index = prev.indexOf(rescue.id)
                              if (index === -1) {
                                return [...prev, rescue.id]
                              }
                              return [...prev.slice(0, index), ...prev.slice(index + 1)]
                            })
                            setCurrentRescue(rescue.id)
                            setSelectedCollapsible((prev: number[]) => {
                              const index = prev.indexOf(rescue.id)
                              if (index === -1) {
                                return [...prev, rescue.id]
                              }
                              return [...prev.slice(0, index), ...prev.slice(index + 1)]
                            })
                          }}
                          key={index}
                        >
                          <td className='text-center'>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                visibility:
                                  rescue.status === RescueStatusEnum.INPROGRESS &&
                                  rescue.details_ids.length
                                    ? 'visible'
                                    : 'hidden',
                              }}
                              className='form-check form-check-custom form-check-solid'
                            >
                              <input
                                style={{
                                  width: '24px',
                                  height: '24px',
                                  cursor: 'pointer',
                                }}
                                className='form-check-input'
                                type='checkbox'
                                id='flexCheckDefault'
                                checked={compareArray(selectedRescues, rescue.details_ids)}
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                onChange={(e) => {
                                  e.stopPropagation()
                                  for (const detail_id of rescue.details_ids) {
                                    handleClickCheckbox(detail_id)
                                  }
                                }}
                              />
                            </div>
                          </td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {getDDMMYYYYFormat(rescue.created_at, true)}
                            </span>
                            <span className='text-muted d-block fs-5'>
                              {getHHMMFormat(rescue.created_at, true)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {formatCustomerFulllname(rescue.customer)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.fund.title} - {rescue.serie.name}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5 monto-completo'>
                              {rescue.subtotal
                                ? rescue.money_type === MoneyTypeEnum.USD
                                  ? '$'
                                  : rescue.money_type === MoneyTypeEnum.PEN && 'S/.'
                                : '-'}
                              {rescue.subtotal?.toFixed(4)}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.bank_account.account_number ??
                                rescue.bank_account.account_number}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.bank_account.cci ?? rescue.bank_account.cci}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {rescue.user_account_json.bank_account.bank_name}
                            </span>
                          </td>
                          <td>
                            {rescue.status === RescueStatusEnum.INPROGRESS ? (
                              <span className='badge badge-light-primary'>En proceso</span>
                            ) : rescue.status === RescueStatusEnum.APPROVED ? (
                              <span className='badge badge-light-success'>Aprobado</span>
                            ) : rescue.status === RescueStatusEnum.PENDING ? (
                              <span className='badge badge-light-warning'>Pendiente</span>
                            ) : (
                              rescue.status === RescueStatusEnum.ANNULLED && (
                                <span className='badge badge-light-danger'>Anulado</span>
                              )
                            )}
                          </td>
                          <td className='text-center'>
                            {activesRescues.includes(rescue.id) ? (
                              <i className={clsx('bi bi-chevron-up fs-5')}></i>
                            ) : (
                              <i className={clsx('bi bi-chevron-down fs-5')}></i>
                            )}
                          </td>
                        </tr>
                        <tr className='text-center px-20'>
                          {activesRescues.includes(rescue.id) && (
                            <RescueDetailCollapsible
                              selectedRescues={selectedRescues}
                              setSelectedRescues={setSelectedRescues}
                              rescue_id={rescue.id}
                              current_rescue={currentRescue}
                              money_type={
                                rescue.money_type === MoneyTypeEnum.USD
                                  ? '$'
                                  : rescue.money_type === MoneyTypeEnum.PEN && 'S/.'
                              }
                              mutationSuccess={mutationSuccess}
                            />
                          )}
                        </tr>
                      </>
                    )
                  })}
            </tbody>
          </table>
        )}
        {rescues?.data.length === 0 &&
          !(((isLoadingRescues || isFetching) && !isError) || isRefetching) && (
            <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
              No se encontraron resultados{' '}
              <a
                className='link'
                style={{
                  marginLeft: 5,
                  cursor: 'pointer',
                }}
                onClick={cleanupRequest}
              >
                refrescar la tabla
              </a>
            </span>
          )}
      </div>
      {paginationComponent()}
    </div>
  )
}
