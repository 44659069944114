import { AxiosError } from 'axios'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG } from '../../../../_metronic/helpers'
import { toAbsoluteUrl } from '../../../../_metronic/helpers/AssetHelpers'
import { IUpdateUser } from '../../../common/interfaces/user.interface'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
import { EditUserForm } from './components/form/EditUserForm'

const editUserSchema = Yup.object().shape({
  name: Yup.string().required('Nombre es requerido'),
  lastname: Yup.string().required('Apellido es requerido'),
  email: Yup.string()
    .email('Formato de correo invalido')
    .required('Correo electrónico es requerido'),
  phone_number: Yup.string()
    .max(12, 'Debe tener menos de 12 caracteres')
    .required('Número de télefono es requerido'),
  roles: Yup.array().of(Yup.number()),
})

const inits: IUpdateUser = {
  name: '',
  lastname: '',
  email: '',
  phone_number: '',
  roles: [1, 2],
  status: null,
}
export const EditUser = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <React.Fragment>
      <div className='position-relative'>
        <Link to='/user/list'>
          <img
            src={toAbsoluteUrl('/✅ Web - Mm/aroow.png')}
            className='position-absolute cursor-pointer'
            style={{
              top: '80px',
              left: '100px',
            }}
            alt=''
          />
        </Link>
        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <Formik
            validationSchema={editUserSchema}
            initialValues={inits}
            onSubmit={async (values) => {
              try {
                delete values.status
                await apiUrl.put('/user/person/update/' + id, values)

                customSwal
                  .fire({
                    title: 'Usuario editado satisfactoriamente',
                    icon: 'success',
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    result.isConfirmed && navigate('/user/list')
                  })
              } catch (err: any) {
                const errors = err as AxiosError
                if ((errors.response?.data as any).message.includes('email')) {
                  customSwal.fire({
                    icon: 'error',
                    title: 'El correo se encuentra en uso.',
                    text: 'Intente nuevamente.',
                  })
                  return
                }
                if ((errors.response?.data as any).message.includes('phone')) {
                  customSwal.fire({
                    icon: 'error',
                    title: 'El número de teléfono se encuentra en uso.',
                    text: 'Intente nuevamente.',
                  })
                  return
                }
                if (errors.response?.data) {
                  customSwal.fire({
                    icon: 'error',
                    title:
                      errors.response.status === 403
                        ? 'No tienes permisos para realizar esta acción'
                        : 'Ha ocurrido un error',
                    text: 'Intente nuevamente.',
                  })
                }
              }
            }}
          >
            {(props) => (
              <Form
                autoComplete='off'
                className='w-100 w-xl-700px mb-20 '
                onSubmit={props.handleSubmit}
                noValidate
                id='kt_create_account_form'
              >
                <div className='current' data-kt-stepper-element='content'>
                  <div className='mt-20 w-100'>
                    <EditUserForm formProps={props} />
                  </div>
                </div>

                <div className='d-flex flex-end pt-10'>
                  <button
                    type='submit'
                    style={{
                      backgroundColor: '#0066CC',
                      color: 'white',
                    }}
                    className='btn btn-lg'
                    disabled={props.isSubmitting}
                  >
                    <span className='indicator-label'>
                      {'Actualizar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 text-white ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}
