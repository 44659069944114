import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG } from '../../../../_metronic/helpers'
import { getPermissions } from '../../../common/helpers/Permission'
import { ICreateRoleV2, IRoleView } from '../../../common/interfaces'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
import { RoleService } from '../../../services'
import ModalAction from './components/modals/ModalAction'
import RoleForm from './form/RoleForm'

interface IProps {}

const updateRoleSchema = Yup.object().shape({
  name: Yup.string().max(140, 'Máximo 140 caracteres').required('Nombre es requerido'),
  status: Yup.number().required('Estado es requerido'),
  description: Yup.string().max(140, 'Máximo 140 caracteres').required('Descripción es requerido'),
  permissions: Yup.array().of(Yup.number()).min(1, 'Debe seleccionar al menos un permiso'),
})

const inits: ICreateRoleV2 = {
  name: '',
  status: undefined,
  description: '',
  permissions: [],
}

interface IPromise {
  resolve: (value?: any) => void
  reject: (reason?: any) => void
}

export const EditRole = (props: IProps) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [promise, setPromise] = React.useState<IPromise | null>(null)
  const [showModalCancel, setShowModalCancel] = React.useState(false)
  const [showModalConfirm, setShowModalConfirm] = React.useState(false)

  const {
    data: role,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['role-show', id],
    queryFn: () => RoleService().showRole(id!),
    select: ({ data }) => {
      const permissions = getPermissions(data.permissions)
      return {
        ...data,
        permissions,
      } as IRoleView
    },
    enabled: !!id,
  })

  const confirm = () => {
    setShowModalConfirm(true)
    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject })
    })
  }

  const handleSubmit = async (values: ICreateRoleV2, actions: FormikHelpers<ICreateRoleV2>) => {
    values.status = Number(values.status)
    try {
      await confirm()
      await apiUrl.put(`/capability/role/update/${id}`, values)
      customSwal
        .fire({
          title: 'Rol actualizado satisfactoriamente',
          icon: 'success',
          allowOutsideClick: false,
        })
        .then((result) => {
          actions.resetForm()
          result.isConfirmed && navigate('/role/list')
        })
    } catch (error) {
      const axiosError = error as AxiosError
      customSwal.fire({
        title: 'Error al crear rol',
        text:
          axiosError.response?.status === 403
            ? 'No tienes permisos para realizar esta acción'
            : 'Ha ocurrido un error al crear el rol',
        icon: 'error',
      })
    } finally {
      setPromise(null)
      setShowModalConfirm(false)
    }
  }

  const handleCancel = () => {
    navigate(-1)
    setShowModalCancel(false)
  }

  if (isLoading || isFetching)
    return (
      <div className='text-center m-5 p-10'>
        <div className='spinner-border' role='status' />
      </div>
    )

  return (
    <div className='card position-relative'>
      <Formik validationSchema={updateRoleSchema} initialValues={inits} onSubmit={handleSubmit}>
        {(props: FormikProps<ICreateRoleV2>) => (
          <Form
            autoComplete='off'
            noValidate
            id='kt_edit_account_form'
            className='w-100'
            onSubmit={props.handleSubmit}
          >
            <div className='current'>
              <div className='my-10 w-100'>
                <RoleForm formProps={props} mode='edit' data={role} />
              </div>
            </div>
            <div className='card-footer border-0 d-flex flex-end gap-6'>
              <button
                type='button'
                className='btn btn-lg'
                style={{ backgroundColor: '#F9F9F9', color: '#7F8BA0' }}
                onClick={() => setShowModalCancel(true)}
              >
                Cancelar
              </button>
              <button
                type='submit'
                className='btn btn-lg'
                style={{ backgroundColor: '#0066CC', color: 'white' }}
                disabled={!role?.editable}
              >
                <span className='indicator-label'>
                  Actualizar
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr64.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAction
        type='action'
        title='¿Seguro que deseas cancelar?'
        negativeAnswer='Cancelar'
        positiveAnswer='Aceptar'
        onSubmit={handleCancel}
        show={showModalCancel}
        onHide={() => setShowModalCancel(false)}
      />
      <ModalAction
        type='action'
        title='¿Está seguro que desea actualizar este rol?'
        negativeAnswer='No, regresar'
        positiveAnswer='Sí, actualizar'
        onSubmit={() => promise && promise.resolve()}
        show={showModalConfirm}
        onHide={() => setShowModalConfirm(false)}
      />
    </div>
  )
}
