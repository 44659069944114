import { useQuery } from '@tanstack/react-query'
import { IProfileFundListResponse } from '../../common/interfaces/fund.interface'
import { FundService } from '../../services/FundService'
import { useFunds } from './hooks/useFunds'
import { RiskBadge } from './RiskBadge'

export const RecommendedFunds = () => {
  const { data, isLoading, isFetching, isError, refetch } = useQuery<IProfileFundListResponse>(
    ['profile-fund'],
    () => FundService().listProfile(),
    {
      keepPreviousData: true,
    }
  )
  const { setRecommendedFunds } = useFunds({ refetch })

  return (
    <div className='card border-0 pt-5 border-radius-20px'>
      <div className='card-body'>
        <div className='table-responsive'>
          {(isLoading || isFetching) && !isError ? (
            <div className='text-center m-5 p-10'>
              <div className='spinner-border' role='status'></div>
            </div>
          ) : (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='w-300px'>FONDO</th>
                  <th className='w-150px'>NIVEL DE RIESGO</th>
                  <th>PERFILES DE TOLERANCIA</th>
                </tr>
              </thead>

              <tbody>
                {data?.data.funds?.map((fund: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span style={{ fontWeight: 600 }} className='text-muted d-block fs-5'>
                          {fund.title}
                        </span>
                        <span className='text-muted d-block fs-5'>{fund.codFund}</span>
                      </td>

                      <td>
                        <span className='text-muted d-block fs-5'>
                          <RiskBadge
                            size='medium'
                            formattedRisk={
                              fund.structure ? (fund.structure.riskLevel.riskData as any) : 'none'
                            }
                          />
                        </span>
                      </td>
                      <td className='d-flex gap-20 border-0'>
                        {data.data.profiles.map((profile, index) => {
                          return (
                            <div key={index} className='align-items-center gap-3 d-flex'>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: '15px',
                                  paddingBottom: '15px',
                                }}
                                className='form-check form-check-custom form-check-solid'
                              >
                                <input
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                  }}
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={
                                    data.data.profilesFunds.find(
                                      (e) => e.profileId === profile.id && e.fundId === fund.id
                                    )?.recommended
                                  }
                                  onChange={() =>
                                    setRecommendedFunds.mutate(
                                      data.data.profilesFunds.find(
                                        (e) => e.profileId === profile.id && e.fundId === fund.id
                                      )!.id
                                    )
                                  }
                                  id='flexCheckDefault'
                                />
                              </div>
                              <span className='text-muted d-block fs-5'>{profile.name}</span>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
          {data?.data.funds.length === 0 && !isLoading && !isFetching && (
            <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
              No se encontraron resultados
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
