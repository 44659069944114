/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Login } from './components/Login'
import { IdentityValidation } from './components/IdentityValidation'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])

  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div
        className='h-100 w-100'
        style={{
          // backgroundImage: `url(${toAbsoluteUrl('/media/phi.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'absolute',
          zIndex: -100,
        }}
      ></div>
      <div
        className='h-100 w-100'
        style={{
          backgroundColor: '#007BC3',
          position: 'absolute',
          zIndex: -100,
          opacity: 0.9,
        }}
      ></div>
      <div
        className='h-100 w-100'
        style={{
          backgroundColor: 'black',
          position: 'absolute',
          zIndex: -99,
          opacity: 0.2,
        }}
      ></div>
      {/* begin::Content */}
      <div className='d-flex w-100 h-100 justify-content-center align-items-center p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 '>
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='identity-validation/:email/:id' element={<IdentityValidation />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
