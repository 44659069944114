import { IRoleListDropdown } from '../interfaces'
import { isObject } from './ObjectHandlers'

const getDataFromObj = ([name, childrenRaw]: [
  name: string,
  childrenRaw: any
]): IRoleListDropdown => {
  const children = getPermissions(childrenRaw)
  const isSelected = children.every((o) => o.selected)
  return {
    name,
    selected: isSelected,
    children,
    module: name,
  }
}

const getDataFromArray = (data: IRoleListDropdown): IRoleListDropdown => {
  const { name, secondary_name, id, module, selected } = data
  return {
    name: secondary_name || name,
    id,
    selected: selected ?? false,
    nameFormatted: name.split(' ').join(''),
    module,
  }
}

export const getPermissions = (data: {} | []): IRoleListDropdown[] => {
  let dataUpdated: IRoleListDropdown[] = []

  if (isObject(data)) {
    dataUpdated = Object.entries(data).map(getDataFromObj)
  }
  if (Array.isArray(data)) {
    dataUpdated = data.map(getDataFromArray)
  }
  return dataUpdated
}

export const getPermissionsSelected = (data: IRoleListDropdown[]): number[] => {
  return data.reduce((acc: number[], item) => {
    if (item.selected && item.id) {
      acc.push(item.id)
    }
    if (item.children) {
      acc.push(...getPermissionsSelected(item.children))
    }
    return acc
  }, [])
}

export const allChildrenChecked = (children: IRoleListDropdown[]): boolean => {
  return children.every((child) => {
    if (child.id) {
      return child.selected
    }
    if (child.children) {
      return allChildrenChecked(child.children)
    }
    return true
  })
}
