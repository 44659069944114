import { Route, Routes } from 'react-router-dom'
import RequireRole from '../../common/components/RequireRole'
import { BackofficePermissionsEnum as Permissions } from '../../common/enums/backOfficePermissionsEnum'
import { EditClient } from '../../modules/clients/EditClient'
import { ExportClient } from '../../modules/clients/ExportClient'
import { ListClients } from '../../modules/clients/ListClients'
import { MassiveRiskProfile } from '../../modules/clients/MassiveRiskProfile'
import { ViewClient } from '../../modules/clients/ViewClient'
import { SuspensedView } from '../PrivateRoutes'

export const ClientRouting = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <RequireRole roles={Permissions.USER_CUSTOMER_LIST}>
            <SuspensedView>
              <ListClients />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/show/:id`}
        element={
          <RequireRole roles={Permissions.USER_CUSTOMER_SHOW}>
            <SuspensedView>
              <ViewClient />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path={`/edit/:id`}
        element={
          // <RequireRole roles={Permissions.USER_CUSTOMER_SHOW}>
          <SuspensedView>
            <EditClient />
          </SuspensedView>
          // </RequireRole>
        }
      />
      <Route
        path='import'
        element={
          <RequireRole roles={Permissions.USER_CUSTOMER_IMPORT}>
            <SuspensedView>
              <ExportClient />
            </SuspensedView>
          </RequireRole>
        }
      />
      <Route
        path='risk-profile'
        element={
          <SuspensedView>
            <MassiveRiskProfile />
          </SuspensedView>
        }
      />
    </Routes>
  )
}
