import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { utils, writeFileXLSX } from 'xlsx'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { apiUrl } from '../../config/api'
import { customSwal } from '../../config/swal'
import { HeaderExcel } from './utils'

let template: HeaderExcel[] = [
  {
    'Tipo Documento': 'DNI',
    'Nº De Documento': '72682408',
  },
]

export const ExportClient = () => {
  const [, setFile] = useState(null || '[]')
  const [loading, setLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [typeSerie, setTypeSerie] = useState('')

  const [dataJson, setDataJson] = useState([])
  const navigate = useNavigate()

  const handleChange = (e: any) => {
    setTypeSerie(e.target.value)
  }

  const handleSubmit = () => {
    setLoading(true)

    if (!Object.values(dataJson).length) {
      customSwal.fire({
        title: 'Formato incorrecto: Lista vacía',
        icon: 'error',
        allowOutsideClick: false,
      })
      setLoading(false)
      setFile('[]')
    }

    for (const data of Object.values(dataJson)) {
      if (Object.keys(data).length !== Object.keys(template[0]).length) {
        customSwal.fire({
          title: 'Formato incorrecto: Columnas faltantes',
          icon: 'error',
          allowOutsideClick: false,
        })
        setLoading(false)
        setFile('[]')
        return
      }
      if (
        Object.values(data).includes(undefined) ||
        Object.values(data).includes(null) ||
        Object.values(data).includes('')
      ) {
        customSwal.fire({
          title: 'Formato incorrecto: Campos vacíos',
          icon: 'error',
          allowOutsideClick: false,
        })
        setLoading(false)
        setFile('[]')
      }
    }

    const newData = {
      serie: typeSerie,
      data: dataJson.map((item: any) => ({
        document_type:
          item['Tipo Documento'].toLowerCase() === 'ce'
            ? 'CE'
            : item['Tipo Documento'].toLowerCase() === 'dni'
            ? 'DNI'
            : item['Tipo Documento'].toLowerCase() === 'pasaporte'
            ? 'PASSPORT'
            : item['Tipo Documento'].toLowerCase() === 'ruc'
            ? 'RUC'
            : item['Tipo Documento'].toLowerCase(),
        number_document:
          item['Nº De Documento'] || item['Nº De Documento']
            ? item['Nº De Documento'].toString()
            : '',
      })),
    }

    apiUrl
      .post('user/app/massive/collaborator', newData)
      .then((response) => {
        setLoading(false)
        customSwal
          .fire({
            title: 'Se ha cargado el archivo satisfactoriamente',
            icon: 'success',
            allowOutsideClick: false,
          })
          .then((result) => {
            result.isConfirmed && navigate('/client/list')
          })
      })
      .catch((error) => {
        setLoading(false)

        if (error.response.data.message.includes('Email')) {
          customSwal.fire({
            title:
              'Número de documento: ' +
              error.response.data.message.split(' ')[1] +
              ' se encuentra repetido.',
            icon: 'error',
            allowOutsideClick: false,
          })
          return
        }

        const status = error.response.status

        customSwal.fire({
          title:
            status === 403
              ? 'No tienes permisos para realizar esta acción'
              : 'Ha ocurrido un error al cargar el archivo',
          icon: 'error',
          allowOutsideClick: false,
        })
      })

    setFile('[]')
  }
  const handleExcelDownload = () => {
    const ws = utils.json_to_sheet(template)
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Listado de clientes')
    writeFileXLSX(wb, 'ListadoDeClientesPlantilla.xlsx')
  }

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    setFile(file)

    const reader = new FileReader()
    reader.onload = (e) => {
      const data = e.target!.result
      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const json = XLSX.utils.sheet_to_json(sheet)
      uploadJsonToBackend(json)
    }
    reader.readAsBinaryString(file)
  }

  const uploadJsonToBackend = (json: any) => {
    setShowButton(false)
    setDataJson(json)
  }

  return (
    <React.Fragment>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card card-custom card-border'>
          <div className='card-header d-block mb-0 pb-0'>
            <h3
              className='card-title fs-1'
              style={{
                color: '#373538',
                fontWeight: 600,
              }}
            >
              Agregar y actualizar clientes
            </h3>
          </div>

          <div className='card-body pt-0 mt-0'>
            <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'>
              <div className='d-flex flex-column pe-0 pe-sm-10'>
                <p
                  style={{
                    color: '#373538',
                  }}
                  className='text-start mb-3 fs-3'
                >
                  1. Descarga la plantilla adjunta e ingresa la información requerida.
                </p>
                <button
                  style={{
                    backgroundColor: '#007BC3',
                    color: 'white',
                    margin: '5px',
                    width: '180px',
                  }}
                  onClick={handleExcelDownload}
                  className='btn text-end cursor-pointer w-180px'
                >
                  <img
                    style={{
                      marginRight: '5px',
                    }}
                    src={toAbsoluteUrl('/✅ Web - Mm/donwload.svg')}
                    alt='plus'
                    className='w-15px  '
                  />
                  Descargar plantilla
                </button>
              </div>
            </div>
            <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'>
              <div className='d-flex flex-column pe-0 pe-sm-10'>
                <p
                  style={{
                    color: '#373538',
                  }}
                  className='text-start mb-3 fs-3'
                >
                  2. Seleccione el tipo de serie a la cual será asignada el listado de clientes.
                </p>
                <div className=' position-relative'>
                  <select
                    className='form-control w-300px'
                    value={typeSerie}
                    onChange={handleChange}
                  >
                    <option value='' disabled>
                      Selecciona una serie
                    </option>
                    <option value='Serie A'>Serie A</option>
                    <option value='Serie B'>Serie B</option>
                    {/* <option value="Serie B">Serie </option> */}
                  </select>
                  <div className='position-absolute' style={{ bottom: 10, left: 270 }}>
                    <i className='bi bi-chevron-down'></i>
                  </div>
                </div>
              </div>
            </div>
            <div className='alert alert-dismissible bg-light-primary p-5 mb-10'>
              <div className=''>
                <p
                  style={{
                    color: '#373538',
                  }}
                  className='text-start mb-3 fs-3'
                >
                  3. Carga el listado y presiona "Actualizar"
                </p>
                <div className='card-footer row' style={{ paddingLeft: ' 0px' }}>
                  <div className='col-8 ml-0 pl-0'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '5px',
                        }}
                      >
                        <label
                          htmlFor='excel-file'
                          className={typeSerie !== '' ? 'upload-label' : 'upload-disabled '}
                        >
                          <input
                            id='excel-file'
                            type='file'
                            accept='.xlsx'
                            disabled={typeSerie === ''}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                          />{' '}
                          <img
                            style={{
                              marginRight: '10px',
                            }}
                            src={toAbsoluteUrl('/✅ Web - Mm/upload.svg')}
                            alt='plus'
                            className='w-15px '
                          />
                          Cargar el archivo
                        </label>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <div className='col-4 text-end'>
                    <button
                      id='downloadAtachment'
                      disabled={showButton}
                      onClick={handleSubmit}
                      style={{
                        color: 'white',
                        backgroundColor: !showButton ? '#007BC3' : '#E2F4FF',
                      }}
                      className='btn text-end cursor-pointer w-120px'
                    >
                      {!loading && <span className='indicator-label'>Actualizar</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Espera un momento...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
