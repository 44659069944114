/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStateCallback } from '../../common/hooks/useStateCallback'
import { ICustomerDetail } from '../../common/interfaces'
import { ClientService } from '../../services/ClientService'
import { InfoClientTab } from './components/tabs/InfoClientTab'
import { MovementsTab } from './components/tabs/MovementsTab'
import { ProfileTab } from './components/tabs/ProfileTab'
export enum OriginTypeEnum {
  EECC = 'EECC',
  WEB = 'WEB',
  APP = 'APP',
}

export enum DocumentTypeEnum {
  DNI = 'DNI',
  RUC = 'RUC',
  CE = 'CE',
  PASSPORT = 'PASSPORT',
}

export const SpectrumDocumentType = {
  OTRO: '00',
  DNI: '01',
  MILITAR: '02',
  CE: '04',
  RUC: '05',
  PASSPORT: '07',
  NUM_PARTICIPE: '30',
}

export const ViewClient = () => {
  const { id } = useParams()
  const [selectedTab, setSelectedTab] = useStateCallback('info')

  const [customerInfo, setCustomerInfo] = useState('')

  const { data: user } = useQuery({
    queryKey: ['client-show', id],
    queryFn: () => ClientService().showClient(id!),
  })
  const {
    data: customerDetail,
    isLoading,
    isFetching,
    isError,
  } = useQuery<ICustomerDetail>(
    ['customer-details', id],
    () =>
      ClientService().customerDetail({
        numIdentidad: user.data.number_document,
        tipoIdentidad:
          SpectrumDocumentType[user.data.document_type as keyof typeof SpectrumDocumentType],
        origin: OriginTypeEnum.WEB,
      }),
    {
      enabled: !!user,
    }
  )

  useEffect(() => {
    const document = `${user?.data?.document_type} ${user?.data?.number_document} • `
    const civilStatus = customerDetail?.profile?.civil_status?.description
    const civilStatusToShow = civilStatus || 'Sin Perfil'
    const customerRiskProfile = customerDetail?.customerRisks.find(
      (e) => e.status === 1
    )?.customer_risk_profile
    const customerRiskToShow = customerRiskProfile ?? (civilStatus ? 'Sin Perfil' : '')
    const lastPoint = customerRiskToShow ? ' • ' : ''
    const customerInfo = document + civilStatusToShow + lastPoint + customerRiskToShow
    setCustomerInfo(customerInfo)
  }, [customerDetail, user])

  return (
    <React.Fragment>
      <ul className='nav nav-tabs position-relative nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        <li className='nav-item'>
          <a
            className='nav-link active'
            onClick={() => setSelectedTab('info')}
            data-bs-toggle='tab'
            href='#info'
          >
            Información
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link'
            onClick={() => setSelectedTab('profile')}
            data-bs-toggle='tab'
            href='#profile'
          >
            Perfil
          </a>
        </li>
        <li className='nav-item'>
          <a
            className='nav-link'
            data-bs-toggle='tab'
            onClick={() => setSelectedTab('movements')}
            href='#movements'
          >
            Movimientos
          </a>
        </li>
      </ul>
      <div>
        <div className='card col-12 px-10 py-7 mb-5'>
          <h6>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <div className='d-flex text-muted flex-column gap-5 justify-content-between'>
                <div className='align-items-center d-flex gap-3'>
                  <span className='fs-4'>
                    {(`${user.data.name.trim()} ` || '') +
                      (user.data.middlename ? `${user.data.middlename} ` : ' ') +
                      (user.data.surname ? `${user.data.surname} ` : ' ') +
                      ' ' +
                      user.data.lastname.trim()}
                  </span>

                  {user.data.status.toString() === '0' ? (
                    <span className='badge badge-light-danger'>Inactivo</span>
                  ) : user.data.status.toString() === '1' ? (
                    <span className='badge badge-light-success'>Activo</span>
                  ) : (
                    <span className='badge badge-light-warning'>Archivado</span>
                  )}
                </div>
                <div className='d-flex text-muted fw-normal align-items-end'>
                  {/* {user.data.document_type}{' '}
                  {user.data.number_document + ' • '}
                  {customerDetail?.profile?.civil_status
                    ? customerDetail?.profile?.civil_status?.description : 'Sin perfil'}
                  {customerDetail?.customerRisks ? ' • ' : ''}
                  {customerDetail?.customerRisks.find((e) => e.status === 1)?.customer_risk_profile} */}
                  {customerInfo}
                </div>
              </div>
            )}
          </h6>
        </div>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade show active' id='info' role='tabpanel'>
            {selectedTab === 'info' && (
              <InfoClientTab
                customerDetail={customerDetail}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className='tab-pane fade show active' id='info' role='tabpanel'>
            {selectedTab === 'profile' && (
              <ProfileTab
                customerDetail={customerDetail}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            )}
          </div>
          <div className='tab-pane fade show active' id='info' role='tabpanel'>
            {selectedTab === 'movements' && (
              <MovementsTab
                customerDetail={customerDetail}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
