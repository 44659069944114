import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { customSwal } from '../../../config/swal'
import { verifyAccount } from '../core/_requests'
import { Login } from './Login'
import { useAuth } from '../core/Auth'

export const IdentityValidation = () => {
  const { email, id } = useParams()
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()

  const handleGetUserVerified = async (email: string, id: string) => {
    if (currentUser) logout()
    try {
      await verifyAccount(email, id)
      customSwal.fire({
        title: 'Validación de usuario exitosa',
        icon: 'success',
        confirmButtonText: 'OK',
      })
    } catch (error) {
      customSwal.fire({
        title: 'Ha ocurrido un problema en la validación de usuario',
        icon: 'error',
      })
    } finally {
      navigate('/auth/login')
    }
  }

  useEffect(() => {
    if (email && id) handleGetUserVerified(email, id)
  }, [email, id])

  return <Login />
}
