import { isAxiosError } from 'axios'
import { SolicitudeStatusEnum } from '../common/interfaces/payment.interface'
import { apiUrl } from '../config/api'

export const PaymentService = () => {
  const paymentsBySuscriptions = async (suscription_id: number | string) => {
    return (await apiUrl.get(`/payment/suscription/${suscription_id}`)).data
  }

  const changeStatusPayment = (status: SolicitudeStatusEnum, selectedPayment: number) =>
    apiUrl
      .put(`/payment/change-status/${selectedPayment}`, {
        status,
      })
      .then((res: any) => res.data)
  const insertSolicitudes = (selectedSuscriptions: number[]) =>
    apiUrl
      .post(`/payment/send-spectrum-payment`, {
        ids: selectedSuscriptions,
      })
      .then((res: any) => res.data)
  const getBanks = (fund_id: number) =>
    apiUrl.post(`/suscription/list-collector-account/${fund_id}`).then((res: any) => res.data)

  const getMinAmountByFund = async ({
    fund_id,
    customer_id,
    serie_id,
  }: {
    fund_id: string
    customer_id: string
    serie_id: string
  }) => {
    try {
      return (
        await apiUrl.get(
          `/user/app/get-amount-min-inversion-customer/${fund_id}/${customer_id}/${serie_id}`
        )
      ).data
    } catch (error) {
      if (isAxiosError(error)) {
        return error.response?.data
      }
    }
  }

  return {
    paymentsBySuscriptions,
    changeStatusPayment,
    insertSolicitudes,
    getMinAmountByFund,
    getBanks,
  }
}
