import { useQuery } from '@tanstack/react-query'
import FsLightbox from 'fslightbox-react'
import { FC } from 'react'
import { getDDMMYYYYFormat, getHHMMFormat } from '../../../../common/helpers/DateHandlers'
import { usePagination } from '../../../../common/hooks/usePagination'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { IPayment } from '../../../../common/interfaces/payment.interface'
import { PaymentService } from '../../../../services/PaymentsService'
import { usePayments } from '../../hooks/usePayments'
import { useSuscriptions } from '../../hooks/useSuscriptions'

const pageSizes = [10, 20, 40, 100]

interface IProps {
  selectedSuscriptionId: number
  refetchSuscriptions: any
}

export const PaymentsModal: FC<IProps> = ({ selectedSuscriptionId, refetchSuscriptions }) => {
  const [openPreview, setOpenPreview] = useStateCallback<boolean>(false)
  const { previewImage } = useSuscriptions()
  const {
    isLoading,
    isFetching,
    isError,
    isRefetching,
    data: subscriptions,
    refetch,
  } = useQuery(
    ['payments-by-suscription', selectedSuscriptionId.toString()],
    () => PaymentService().paymentsBySuscriptions(selectedSuscriptionId),
    {
      enabled: selectedSuscriptionId !== 0,
    }
  )
  const { downloadVoucher, downloadLoading, selectedPayment, setSelectedPayment } = usePayments(
    refetchSuscriptions,
    refetch
  )

  const { paginationComponent } = usePagination({
    initPage: 1,
    initLimit: 10,
    list: subscriptions,
    pageSizes,
  })

  return (
    <div className='modal fade p-5  rounded-4' tabIndex={-1} id='modal-payments' role='dialog'>
      <div className='modal-dialog modal-md rounded-4 modal-dialog-centered' role='document'>
        <div className='modal-content'>
          <div className='modal-header py-5 mt-2 fw-bolder'>
            <h1 className='text-alert'>Relación de pagos</h1>
            <i className='bi bi-x-lg fs-5 cursor-pointer' data-bs-dismiss='modal'></i>
          </div>

          <div className='modal-body'>
            <div className='table-responsive px-3'>
              {((isLoading || isFetching) && !isError) || isRefetching ? (
                <div className='text-center m-5 p-10'>
                  <div className='spinner-border' role='status'></div>
                </div>
              ) : (
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='w-50px'>N°</th>
                      <th className='w-150px'>N° DE OPERACIÓN</th>
                      <th className='w-120px'>FECHA Y HORA</th>
                      <th className='min-w-100px '>ADJUNTO</th>
                    </tr>
                  </thead>

                  <tbody>
                    {subscriptions?.data!.reverse().map((payment: IPayment, index: number) => {
                      let currentDate = new Date(payment.created_at)

                      let day =
                        currentDate.getDate() > 9
                          ? currentDate.getDate()
                          : '0' + currentDate.getDate()
                      let month =
                        currentDate.getMonth() > 9
                          ? currentDate.getMonth() + 1
                          : '0' + (currentDate.getMonth() + 1)
                      return (
                        <tr className='text-muted' key={index}>
                          <td>{index + 1}</td>

                          <td>
                            <span className='text-muted d-block fs-5'>
                              {payment.operation_number}
                            </span>
                          </td>
                          <td>
                            <span className='text-muted d-block fs-5'>
                              {getDDMMYYYYFormat(payment.created_at, true)}
                            </span>
                            <span className='text-muted d-block fs-5'>
                              {getHHMMFormat(payment.created_at, true)}
                            </span>
                          </td>
                          <td>
                            {payment.voucher ? (
                              <div className='d-flex align-items-center'>
                                <div
                                  onClick={() => {
                                    setSelectedPayment(payment.id)
                                    downloadVoucher(payment.voucher)
                                  }}
                                  className='d-flex align-items-center iconButton p-1 justify-content-center cursor-pointer'
                                >
                                  {downloadLoading && selectedPayment === payment.id ? (
                                    <div className='text-center text-muted'>
                                      <div
                                        className='spinner-border m-2'
                                        style={{ width: 18, height: 18 }}
                                        role='status'
                                      ></div>
                                    </div>
                                  ) : (
                                    <>
                                      <i className='bi bi-download fs-3 m-2'></i>
                                    </>
                                  )}
                                </div>
                                <div
                                  className='iconButton'
                                  onClick={() => {
                                    previewImage(payment.voucher, 'currentLightbox')
                                    setOpenPreview(!openPreview)
                                  }}
                                >
                                  {' '}
                                  <i className='bi bi-card-image fs-3 m-2'></i>
                                </div>
                              </div>
                            ) : (
                              <span className='text-muted text-center'>--</span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
              {subscriptions?.data.length === 0 &&
                !(((isLoading || isFetching) && !isError) || isRefetching) && (
                  <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
                    No se encontraron resultados{' '}
                    <a
                      className='link'
                      style={{
                        marginLeft: 5,
                        cursor: 'pointer',
                      }}
                      href='##'
                    >
                      refrescar la tabla
                    </a>
                  </span>
                )}
            </div>
          </div>
          <div className='modal-footer py-4 d-block'>{paginationComponent()}</div>
        </div>
      </div>

      <FsLightbox toggler={openPreview} sources={[<div id='currentLightbox'></div>]}></FsLightbox>
    </div>
  )
}
