import React, { useState } from 'react'

/* 
    A reusable tabs component using metronic tabs

    ICON MUST BE A VALID BOOTSTRAP ICON STRING (https://icons.getbootstrap.com/)
    example: bi-journal-check
*/

export interface ITab {
  id: string
  title: string
  icon: string
  content: React.ReactNode
}

interface ITabsProps {
  tabs: ITab[]
}

const Tabs: React.FC<ITabsProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id)

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId)
  }

  return (
    <>
      <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        {tabs.map((tab) => (
          <li className='nav-item' key={tab.id}>
            <a
              className={`nav-link ${activeTab === tab.id ? 'active' : ''}`}
              data-bs-toggle='tab'
              href={`#${tab.id}`}
              onClick={() => handleTabClick(tab.id)}
            >
              <span className='nav-icon'>
                <i className={`${tab.icon} fs-3 m-2`}></i>
              </span>
              {tab.title}
            </a>
          </li>
        ))}
      </ul>
      <div className='tab-content' id='myTabContent'>
        {tabs.map((tab) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? 'show active' : ''}`}
            id={tab.id}
            role='tabpanel'
            key={tab.id}
          >
            {tab.id === activeTab && tab.content}
          </div>
        ))}
      </div>
    </>
  )
}

export default Tabs
