import React from 'react'
import { ICustomerDetail } from '../../../../common/interfaces'

interface IProps {
  customerDetail: ICustomerDetail
}

export const SpousePersonTable = ({ customerDetail }: IProps) => {
  return (
    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
      <thead>
        <tr className='fw-normal text-muted'>
          <th className='min-w-150px'>CAMPO</th>
          <th className='min-w-150px'>VALOR</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>NOMBRE COMPLETO</span>
          </td>

          <td>
            <span className='text-muted fw-normal d-block fs-5'>
              {customerDetail?.spouse
                ? `${customerDetail.spouse.fullname ? customerDetail.spouse.fullname.trim() : '--'}`
                : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>TIPO DE RÉGIMEN</span>
          </td>

          <td>
            <span className='text-muted fw-normal d-block fs-5'>
              {customerDetail?.spouse ? customerDetail.spouse.regime_type.description : '--'}
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <span className='text-muted d-block fs-5'>NACIONALIDAD</span>
          </td>

          <td>
            <span className='text-muted fw-normal d-block fs-5'>
              {customerDetail?.spouse ? customerDetail.spouse.nationality.description : '--'}
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <span className='text-muted d-block fs-5'>TIPO DE DOCUMENTO</span>
          </td>

          <td>
            <span className='text-muted fw-normal d-block fs-5'>
              {customerDetail?.spouse ? customerDetail.spouse.documentType.description : '--'}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <span className='text-muted d-block fs-5'>NÚMERO DE DOCUMENTO</span>
          </td>

          <td>
            <span className='text-muted fw-normal d-block fs-5'>
              {customerDetail?.spouse ? customerDetail.spouse.document_number : '--'}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
