import axios from 'axios'
import { ILoginResponse } from '../../../common/interfaces/auth.interface'
import { apiUrl } from '../../../config/api'
import { UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/user/person/generate-code-recovery-password`
export const IDENTITY_VALIDATION_URL = `${API_URL}/api/user/person/verify-account`

// Server should return AuthModel
export function login(email: string, password: string) {
  return apiUrl.post<ILoginResponse>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  fullname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: fullname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function verifyAccount(email: string, hash: string) {
  return axios.get(`${IDENTITY_VALIDATION_URL}/${email}/${hash}`)
}