export interface IPayment {
  id: number
  suscription_id: number
  operation_number: string
  voucher: any
  created_at: string
  status: string
}

export enum SolicitudeStatusEnum {
  INGRESADA = 'Ingresada',
  CONFIRMADA = 'Confirmada',
  ANULADA = 'Anulada',
  LIQUIDADA = 'Liquidada',
  PROCESADA = 'Procesada',
}

export enum IPaymentStatusEnum {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ANNULLED = 'ANNULLED',
}
