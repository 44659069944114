import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { getDDMMYYYYFormat, getHHMMFormat } from '../../../../common/helpers/DateHandlers'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { ICustomerDetail } from '../../../../common/interfaces'
import { FileService } from '../../../../services/FileService'

interface IProps {
  customerDetail: ICustomerDetail
}

function getFileNameFromBucketPath(path: string) {
  return path.substring(path.lastIndexOf('/') + 1, path.length)
}

export const RiskProfileDocumentTable = ({ customerDetail }: IProps) => {
  const [fileUrl, setFileUrl] = useStateCallback('')
  const downloadMutation = useMutation({
    mutationFn: () => FileService().downloadFromAzure(fileUrl),
    onSuccess: async (data) => {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', getFileNameFromBucketPath(fileUrl))

      document.body.appendChild(link)
      link.click()

      await new Promise((resolve) => setTimeout(resolve, 1000))

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
  })
  return (
    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
      {customerDetail.customerRisks.length ? (
        <>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th className='w-150px'>FECHA</th>
              <th className='w-200px'>PERFIL SELECCIONADO</th>
              <th>DOCUMENTO</th>
              <th className='text-center'>ADJUNTO</th>
            </tr>
          </thead>
          <tbody>
            {customerDetail.customerRisks
              .sort(
                (a, b) =>
                  new Date(b.constancy_date).getTime() - new Date(a.constancy_date).getTime()
              )
              .map((e, i) => {
                let currentDate = new Date(e.constancy_date)

                let day =
                  currentDate.getDate() > 9 ? currentDate.getDate() : '0' + currentDate.getDate()
                let month =
                  currentDate.getMonth() > 8
                    ? currentDate.getMonth() + 1
                    : '0' + (currentDate.getMonth() + 1)
                return (
                  <tr key={i}>
                    <td className='text-muted'>
                      {e.constancy_date ? (
                        <>
                          <span className='text-muted d-block fs-5'>
                            {getDDMMYYYYFormat(e.constancy_date)}
                          </span>
                          <span className='text-muted d-block fs-5'>
                            {getHHMMFormat(e.constancy_date)}
                          </span>
                        </>
                      ) : (
                        '--'
                      )}
                    </td>
                    <td className='text-muted fs-5'>{e.customer_risk_profile}</td>
                    <td className='text-muted fs-5'>
                      {e.constancy_url ? getFileNameFromBucketPath(e.constancy_url) : '--'}
                    </td>
                    <td className={'text-center'}>
                      {e.constancy_url ? (
                        <div className='d-flex align-items-center  p-1 justify-content-center cursor-pointer'>
                          {downloadMutation.isLoading && e.constancy_url === fileUrl ? (
                            <div className='text-center text-muted'>
                              <div
                                className='spinner-border'
                                style={{
                                  width: '18px',
                                  height: '18px',
                                }}
                                role='status'
                              ></div>
                            </div>
                          ) : (
                            <div
                              onClick={() => {
                                if (downloadMutation.isLoading) {
                                  return
                                }
                                setFileUrl(e.constancy_url)
                                downloadMutation.mutate()
                              }}
                              className='iconButton'
                            >
                              <i className='bi bi-download fs-3 m-2'></i>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span className='text-muted'>--</span>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </>
      ) : (
        <span className='notFound text-muted fs-5 text-center '>
          No se registró cambio de perfil
        </span>
      )}
    </table>
  )
}
