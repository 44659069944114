export const getDDMMYYYYHHMMFormat = (createdAt: string): string => {
  let currentDate = new Date(createdAt)

  let day = currentDate.getDate() > 9 ? currentDate.getDate() : '0' + currentDate.getDate()
  let month =
    currentDate.getMonth() > 9 ? currentDate.getMonth() + 1 : '0' + (currentDate.getMonth() + 1)

  return (
    day +
    '/' +
    month +
    '/' +
    currentDate.getFullYear() +
    ' ' +
    currentDate.toTimeString().split(' ')[0].slice(0, 5)
  )
}

export const getDDMMYYYYFormat = (utcDateString: string, timezoned: boolean = false): string => {
  const utcTime = new Date(utcDateString.replace('Z', ''))

  const utcYear = utcTime.getUTCFullYear()
  const utcMonth = utcTime.getUTCMonth()
  const utcDay = utcTime.getUTCDate()
  const utcHour = utcTime.getUTCHours()
  const utcMinute = utcTime.getUTCMinutes()
  const utcSecond = utcTime.getUTCSeconds()

  const gmtMinus5Time = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHour, utcMinute, utcSecond))
  if (timezoned) gmtMinus5Time.setUTCHours(gmtMinus5Time.getUTCHours() - 5) // force to GMT-5

  const day = gmtMinus5Time.getUTCDate()
  const month = gmtMinus5Time.getUTCMonth() + 1
  const year = gmtMinus5Time.getUTCFullYear()

  const formattedDay = day < 10 ? '0' + day : day
  const formattedMonth = month < 10 ? '0' + month : month

  return `${formattedDay}/${formattedMonth}/${year}`
}

export const getYYYYMMDDFormat = (utcDateString: string): string => {
  const utcTime = new Date(utcDateString.replace('Z', ''))

  const utcYear = utcTime.getUTCFullYear()
  const utcMonth = utcTime.getUTCMonth()
  const utcDay = utcTime.getUTCDate()
  const utcHour = utcTime.getUTCHours()
  const utcMinute = utcTime.getUTCMinutes()
  const utcSecond = utcTime.getUTCSeconds()

  const gmtMinus5Time = new Date(Date.UTC(utcYear, utcMonth, utcDay, utcHour, utcMinute, utcSecond))
  // gmtMinus5Time.setUTCHours(gmtMinus5Time.getUTCHours() - 5) // force to GMT-5

  const day = gmtMinus5Time.getUTCDate()
  const month = gmtMinus5Time.getUTCMonth() + 1
  const year = gmtMinus5Time.getUTCFullYear()

  const formattedDay = day < 10 ? '0' + day : day
  const formattedMonth = month < 10 ? '0' + month : month

  return `${year}-${formattedMonth}-${formattedDay}`
}

export const getHHMMFormat = (utcDateString: string, timezoned: boolean = false): string => {
  let date
  if (timezoned) {
    const zoneDate = new Date(utcDateString)
    zoneDate.setHours(zoneDate.getHours() - 5)
    date = zoneDate.toISOString().slice(11, 16)
  } else {
    date = new Date(utcDateString).toISOString().slice(11, 16)
  }

  return date
}

export const dateRangePickerDefault = {
  opens: 'left',
  locale: {
    applyLabel: 'Aplicar',
    cancelLabel: 'Cancelar',
    daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
    monthNames: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    format: 'DD/MM/YYYY',
  },
  autoUpdateInput: false,
}

export const formatDateTimeUTC = (dateString: string) => {
  const date = new Date(dateString)

  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const year = date.getUTCFullYear()

  const formattedDate = `${day}/${month}/${year}`
  // const formattedTime = `${hours}:${minutes}`;

  return formattedDate
}

export function formatTimeToHHMMSS(date: Date) {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  // const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${hours}:${minutes}`
}
