import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { NotificationPage } from '../../modules/notification/NotificationPage'
import { SuspensedView } from '../PrivateRoutes'

export const NotificationRouting = () => {
  return (
    <Routes>
      <Route
        path='page'
        element={
          <SuspensedView>
            <NotificationPage />
          </SuspensedView>
        }
      />
    </Routes>
  )
}
