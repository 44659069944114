import React from 'react'
import { useNotifications } from '../../../_metronic/partials/layout/hooks/useNotifications'
import { INotification } from '../../common/interfaces/user.interface'

const page = 1
const limit = 100

export const NotificationPage = () => {
  const {
    query: { notifications, notificationStatus },
  } = useNotifications(limit, page, true)

  if (!notificationStatus || !notifications) {
    return (
      <div className='text-center m-5 p-10'>
        <div className='spinner-border' role='status'></div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
        <div className='notif-container'>
          {notifications.data.map((notification: INotification, i: number) => (
            <div key={i} className='notif-card'>
              <div className='notif-icon'>
                <i className='far fa-bell'></i>
              </div>
              <div className='notif-info'>
                <div className='notif-nombre'>{notification.title}</div>
                <div className='notif-hora'>
                  {new Date(notification.createdAt).toLocaleString()}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}
