/* eslint-disable jsx-a11y/anchor-is-valid */
import react, { FC } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth()
  return (
    <react.Fragment>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.name} {currentUser?.lastname}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5 text-danger'>
            Cerrar sesión
          </a>
        </div>
      </div>
    </react.Fragment>
  )
}

export { HeaderUserMenu }
