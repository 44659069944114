export const indexableMenus = ['edit', 'show']

export const getPageTitle = (pathname: string): string => {
  const pathnameMap = {
    '/logs/list': 'Listado de logs',
    '/client/list': 'Listado de clientes',
    '/client/edit': 'Editar cliente',
    '/client/import': 'Importar clientes',
    '/client/risk-profile': 'Carga masiva perfil de riesgo',
    '/client/show': 'Detalle de cliente',
    '/user/list': 'Listado de usuarios',
    '/user/edit': 'Editar usuario',
    '/user/add': 'Crear usuario',
    '/user/show': 'Detalle de usuario',
    '/role/add': 'Crear rol',
    '/role/list': 'Roles y permisos',
    '/role/show': 'Detalle de rol',
    '/role/edit': 'Editar rol',
    '/funds/list': 'Listado de fondos',
    '/funds/recommended': 'Recomendados',
    '/funds/config': 'Configuración de fondos',
    '/orders/list': 'Listado de ordenes',
    '/movements/list': 'Listado de movimientos',
    '/prospect/list': 'Listado de prospectos',
    '/notification/page': 'Centro de notificaciones',
    '/configuration/general': 'Configuración general',
    '/configuration/leaflet': 'Listado de prospectos',
    '/configuration/forms': 'Formulario de Perfil de Tolerancia al Riesgo',
    '/configuration/forms/edit': 'Editar formulario',
    '/user/businessExecutive': 'Listado de asesores',
    '/': 'Inicio',
  }

  return (pathnameMap as any)[Object.keys(pathnameMap).find((e) => pathname.includes(e)) || '']!
}

export const getPageSubTitle = (pathname: string): string => {
  const pathnameMap = {
    '/client/list': 'Clientes/ Listado de clientes',
    '/client/import': 'Clientes/ Importar clientes',
    '/client/risk-profile': 'Clientes/ Carga masiva perfil de riesgo',
    '/client/edit': 'Clientes/ Detalle de cliente/ Editar cliente',
    '/client/show': 'Clientes/ Listado de clientes/ Detalle de cliente',
    '/user/add': 'Configuración/ Listado de usuarios/ Crear usuario',
    '/user/show': 'Configuración/ Listado de usuarios/ Detalle de usuario',
    '/user/edit': 'Configuración/ Listado de usuarios/  Editar usuario',
    '/role/list': 'Configuración/ Roles y permisos',
    '/user/list': 'Configuración/ Listado de usuarios',
    '/role/add': 'Configuración/ Roles y permisos/ Crear Rol',
    '/role/edit': 'Configuración/ Roles y permisos/ Editar rol',
    '/role/show': 'Configuración/ Roles y permisos/ Detalle de rol',
    '/funds/list': 'Fondos/ Listado de fondos',
    '/funds/config': 'Listado de fondos/ Configuración de fondos',
    '/funds/recommended': 'Fondos/ Recomendados',
    '/orders/list': 'Ordenes/ Listado de ordenes',
    '/movements/list': 'Movimientos/ Listado de movimientos',
    '/prospect/list': 'Configuración/ Listado de prospectos',
    '/config/leaflet': 'Configuración/ Listado de prospectos',
    '/configuration/general': 'Configuración/ General',
    '/configuration/leaflet': 'Configuración/ Listado de prospectos',
    '/configuration/forms/edit': 'Configuración/ Editar formulario',
    '/configuration/forms': 'Configuración/ Formulario',
    '/logs/list': 'Logger/ Listado de logs',
    '/user/businessExecutive': 'Configuración/ Listado de asesores',
    '/': 'Inicio',
  }

  return (pathnameMap as any)[Object.keys(pathnameMap).find((e) => pathname.includes(e)) || '']!
}
