import { apiUrl } from '../config/api'

export const FileService = () => {
  const downloadFromAzure = async (fileName: string) => {

    return (
      await apiUrl.post(
        `/files/download-azure-file`,
        {
          fileName,
        },
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }
      )
    ).data
  }
  return {
    downloadFromAzure,
  }
}
