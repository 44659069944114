import { useCallback, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { apiUrl } from '../../../config/api'

export const useGoogleRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [token, setToken] = useState('')

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('yourAction')
    setToken(token)
    apiUrl.defaults.headers.common['x-recaptcha-response'] = token
  }, [executeRecaptcha])

  return {
    handleReCaptchaVerify,
    token,
  }
}
