import { Navigate } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import PATHS from '../constants/paths'

interface IProps {
  children: React.ReactNode
  roles: string[] | string
}

const RequireRole = ({ roles, children }: IProps) => {
  const { permissions } = useAuth()
  let hasRole = false
  if (Array.isArray(roles)) {
    hasRole = permissions.some((role) => roles.includes(role))
  } else if (typeof roles === 'string') {
    hasRole = permissions.includes(roles)
  }

  if (!hasRole) return <Navigate to={PATHS.HOME} replace />

  return <>{children}</>
}

export default RequireRole
