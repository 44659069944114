import { IPermission } from './permission.interface'
import { IRoleUserPerson } from './user.interface'

export interface IRole {
  created_at: string
  description: string
  id: number
  name: null
  rolePermissions: IPermission[]
  status: number | string
  update_at: string
}
export interface IClient {
  id: number
  name: string
  lastname: string
  surname: string
  middlename: string
  email_verified: boolean
  phone_number: number | string
  email: string
  status: number | string
  number_document: number | string
  document_type: string
  customer_user_type: string
}
export interface IRoleCard {
  created_at: string
  description: string
  id: number
  name: string
  status: number | string
  total_users: string
}

export interface IViewUserRole {
  id: number
  name: string
  description: string
  rolePermissions: IPermission[]
}

export interface IViewRole extends IRole {
  userPersons: IRoleUserPerson[]
}

export interface ICreateRole {
  name: string
  description: string
  permissions: number[]
  admins?: number[] | any
}

export interface IRoleList {
  data: IRoleCard[]
  limit: number
  page: number
  total: number
}

export interface IRoleShow {
  data: IViewRole
}

export interface IRoleItem {
  id: number
  name: string
  status: number
  update_at: string
  editable: boolean
}

export interface IRoleListV2 {
  data: IRoleItem[]
  limit: number
  page: number
  total: number
}

export interface ICreateRoleV2 extends ICreateRole {
  group?: string
  status?: StatusEnum
}

export interface IRoleListDropdown {
  id?: number
  description?: string
  group_name?: string
  module?: string
  name: string
  selected?: boolean
  children?: IRoleListDropdown[]
  nameFormatted?: string
  secondary_name?: string
}

export interface IRoleView {
  id: number
  name: string
  status: StatusEnum
  description: string
  update_at: string
  permissions: IRoleListDropdown[]
  editable: boolean
}

export enum StatusEnum {
  ACTIVATED = 1,
  DISABLED = 0, // Can't login
  DELETED = -1, // Archived
  BLOCKED = -2, // Retries block by toc, email, password
  SUSPENDED = -3, // Retries block by toc, email, password
}
