import { useEffect } from 'react'
import { usePaginator } from '../../../common/hooks/usePaginator'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { ILogData, LogEventKeyEnum, ShuftiResponseStatusEnum } from '../../../common/interfaces'
import { LogService } from '../../../services/LogService'

const pageSizes = [50, 150, 250, 500]

export const useLogger = () => {
  const [selectedTab, setSelectedTab] = useStateCallback<LogEventKeyEnum>(LogEventKeyEnum.TOC)

  const paginator = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(0)!,
    search: '',
    pageSizes,
    paginatorOptions: {
      queryKey: ['log-list'],
      fetch: LogService().listLogs,
    },
    extra: selectedTab,
  })

  useEffect(() => {
    paginator.setPage(1)
    paginator.query.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginator.limit, paginator.query.refetch])

  const logIsError = (log: ILogData) => {
    switch (log.service) {
      case LogEventKeyEnum.TOC:
        return log.response['biometric result']
          ? !!(log.response['biometric result'] !== '2')
          : log.response.status
          ? Array.from(log.response.status.toString() as string)[0].includes('4')
          : Array.from(log.status.toString() as string)[0].includes('4')
      case LogEventKeyEnum.SHUFTI:
        return log.response.event
          ? Array.from(log.response.event.toString() as string)[0] ===
              ShuftiResponseStatusEnum.DECLINED
          : Array.from(log.status.toString() as string)[0].includes('4')
      case LogEventKeyEnum.SPECTRUM:
        break
      case LogEventKeyEnum.NOTIFICATIONS:
        break

      default:
        break
    }
  }

  const logCurrentStatus = (log: ILogData) => {
    switch (log.service) {
      case LogEventKeyEnum.TOC:
        return log.response['biometric result']
          ? !!(log.response['biometric result'] !== '2')
            ? 400
            : 200
          : log.response.status
          ? logIsError(log) && log.response.status
          : log.status
      case LogEventKeyEnum.SHUFTI:
        return log.response.event
          ? Array.from(log.response.event.toString() as string)[0] ===
            ShuftiResponseStatusEnum.DECLINED
            ? 400
            : 200
          : Array.from(log.status.toString() as string)[0].includes('4')
      case LogEventKeyEnum.SPECTRUM:
        break
      case LogEventKeyEnum.NOTIFICATIONS:
        break

      default:
        break
    }
  }

  const logQuantity = () => {
    const logs = paginator.query.data

    return {
      success: logs ? logs.data.filter((log: ILogData) => !logIsError(log)).length : 0,
      error: logs ? logs.data.filter((log: ILogData) => logIsError(log)).length : 0,
    }
  }

  const cleanupRequest = () => {
    paginator.setPage(1)
    paginator.query.refetch()
  }

  return {
    paginator,
    setSelectedTab,
    selectedTab,
    logIsError,
    logCurrentStatus,
    logQuantity,
    cleanupRequest,
  }
}
