export interface IListLogsResponse {
  data: ILogData[]
  limit: number
  page: number
  total: number
}

export interface ILogData {
  _id: string
  service: string
  url: string
  response: any
  status: number
  emittedDate: string
  __v: number
}

export enum LogEventKeyEnum {
  TOC = 'TOC',
  SHUFTI = 'SHUFTI',
  SPECTRUM = 'SPECTRUM',
  NOTIFICATIONS = 'NOTIFICATIONS',
}

export enum ShuftiResponseStatusEnum {
  ACCEPTED = 'verification.accepted',
  DECLINED = 'verification.declined',
}
