import React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom'
import PATHS from '../../../../app/common/constants/paths'
import { getPageSubTitle, getPageTitle } from './menuDictionary'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const isHome = pathname === PATHS.HOME
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <div
      className='mt-10px'
      style={{
        color: ' rgb(141, 141, 141)',
        fontSize: '24px',
        fontWeight: 'bold',
      }}
    >
      {!isHome ? (
        <span
          style={{
            color: '#344054',
            fontSize: '20px',
            marginRight: '10px',
          }}
          onClick={handleGoBack}
        >
          <i className='bi bi-chevron-left fs-2 cursor-pointer mr-5'></i>
        </span>
      ) : (
        <div className='ml-8'> </div>
      )}
      {getPageTitle(pathname)}
      <span
        style={{
          color: 'rgb(141 141 141 / 54%)',
          fontSize: '14px',
          fontWeight: 'bold',
          paddingLeft: '22px',
        }}
        className='mt-2 ml-15 d-block pl-8'
      >
        {getPageSubTitle(pathname)}
      </span>
    </div>
  )
}

export { MenuItem }
