import { useMutation } from '@tanstack/react-query'
import { IUpdatePassword } from '../../../common/interfaces/client.interface'
import { customSwal } from '../../../config/swal'
import { ClientService } from '../../../services/ClientService'

export const useClients = () => {
  const upsertPasswordMutation = useMutation(
    (req: IUpdatePassword) => ClientService().upsertPasswordClient(req),
    {
      onSuccess: () => {
        customSwal.fire({
          icon: 'success',
          title: 'Contraseña actualizada correctamente.',
        })
      },
    }
  )

  return {
    mutations: {
      upsertPasswordMutation,
    },
  }
}
