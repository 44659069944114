import React from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { apiUrl } from '../../config/api'
import { customSwal } from '../../config/swal'
interface IProps {
  selectUserId: number | string
  id: string
  title: string
  negativeAnswer: string
  positiveAnswer: string
  confirmationId: string
  confirmationTitle: string
  confirmationAnswer: string
}
export const ConfirmModal = ({
  selectUserId,
  id,
  title,
  negativeAnswer,
  confirmationAnswer,
  positiveAnswer,
  confirmationId,
  confirmationTitle,
}: IProps) => {
  const reload = () => {
    window.location.reload()
  }
  const RefreshUser = () =>
    apiUrl
      .patch(`/user/app/refresh-users/${selectUserId}`)
      .then((res) => {
        if (res) {
          customSwal.fire({
            icon: 'success',
            title: 'Se restableció los intentos correctamente.',
          })
          setTimeout(() => {
            reload()
          }, 1000)
        }
      })
      .catch((error) => {
        if (error) {
          customSwal.fire({
            icon: 'error',
            title: `Ha ocurrido un error al ${confirmationTitle} el cliente`,
            text: 'Intente nuevamente.',
          })
        }
      })

  return (
    <React.Fragment>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id={id} role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header border-0 pb-0'></div>
            <div className='modal-body p-0 mb-5'>
              <div className='text-center mb-8 p-0'>
                <img src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`} alt='e' />
              </div>
              <div className='fw-bolder'>
                <h1 className='text-alert text-center'>{title}</h1>
              </div>
            </div>
            <div className='text-center mx-5 row mb-5'>
              <React.Fragment>
                <button
                  style={{ backgroundColor: '#E2F4FF', color: '#1C73CF' }}
                  type='button'
                  className='btn btn col m-3'
                  data-bs-dismiss='modal'
                >
                  {negativeAnswer}
                </button>
                <button
                  style={{
                    background: '#0066CC',
                    color: 'white',
                  }}
                  type='button'
                  data-bs-toggle='modal'
                  onClick={RefreshUser}
                  data-bs-target={confirmationId}
                  className='btn col m-3 fw'
                >
                  {positiveAnswer}
                </button>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className='modal fade p-5 rounded-4'
        tabIndex={-1}
        id={confirmationId?.slice(1, confirmationId.length)}
        role='dialog'
      >
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-header  border-0 pb-0'></div>
            <div className='modal-body p-0 mb-5'>
              <div className='text-center mb-8 p-0'>
                <img src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`} alt='e'
                  onClick={reload} />
              </div>
              <div className='fw-bolder'>
                <h1 className='text-alert text-center'>{confirmationTitle}</h1>
              </div>
            </div>

            <div className='text-center mx-5 row mb-5'>
              <button
                style={{
                  background: '#0066CC',
                  color: 'white'
                }}
                data-bs-dismiss='modal'
                aria-label='Close'
                type='button'
                className='btn btn col m-3 fw'
                onClick={reload}
              >
                {confirmationAnswer}
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </React.Fragment>
  )
}
