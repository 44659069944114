/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../../app/common/helpers/ReduxHooks'
import { useStateCallback } from '../../../../app/common/hooks/useStateCallback'
import { clearNotifications } from '../../../../redux/notificationSlice'
import { KTSVG } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useNotifications } from '../../../partials/layout/hooks/useNotifications'
import { useLayout } from '../../core'

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-secondary w-30px h-30px w-md-40px h-md-40px'

const Topbar: FC = () => {
  const dispatch = useAppDispatch()
  useNotifications()

  const { config } = useLayout()
  const [openNotifications, setOpenNotifications] = useStateCallback<boolean>(false)
  const notification = useSelector((state: any) => state.notification)

  const desactivated = () => {
    if (notification.hasNotification) {
      dispatch(clearNotifications())
    }
    setOpenNotifications(!openNotifications)
  }

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* NOTIFICATIONS */}
      {/* dsggfds{hasNotification} */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}>
        <div
          style={{
            backgroundColor: 'rgb(231 231 233 / 43%)',
          }}
          className={clsx(btnClass, 'position-relative')}
          onClick={desactivated}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {notification.hasNotification ? (
            <i
              className='bi bi-bell fs-2 notification'
              style={{
                color: ' #b1b2b5',
              }}
            />
          ) : (
            <i className='bi bi-bell fs-2 ' />
          )}
        </div>
        <HeaderNotificationsMenu trigger={openNotifications} />
      </div> */}
      {/* end::Theme mode */}

      {/* begin::User */}

      <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
        {/* begin::Toggle */}
        <div
          style={{
            backgroundColor: 'rgb(231 231 233 / 43%)',
          }}
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-person-lines-fill'></i>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Topbar }
