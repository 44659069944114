import { DocumentTagEnum, FundAmountEnum } from '../../../common/interfaces'

export const uploadFiles = [
  {
    placeholder: 'Contrato de administración',
    document_tag: DocumentTagEnum.CONTRATO_ADMINISTRACION,
    constancy_filename: '',
    constancy_url: '',
    sign: false,
  },
  {
    placeholder: 'Reglamento de Fondos Mutuos',
    document_tag: DocumentTagEnum.REGLAMENTO_FONDOS_MUTUOS,
    constancy_filename: '',
    constancy_url: '',
    sign: false,
  },
  {
    placeholder: 'Anexo de reglamento',
    document_tag: DocumentTagEnum.ANEXO_REGLAMENTO,
    constancy_filename: '',
    constancy_url: '',
    sign: false,
  },
  {
    placeholder: 'Prospecto simplificado',
    document_tag: DocumentTagEnum.PROSPECTO_SIMPLIFICADO,
    constancy_filename: '',
    constancy_url: '',
    sign: false,
  },
]

export const featuresDisabled = Object.values(FundAmountEnum)
