import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'

type Option = {
  questionsId?: number
  title: string
  score: number
}

type Question = {
  questionnaireId?: number
  title: string
  options: Option[]
}

type Form = {
  formName: string
  status: number
  questions: Question[]
}
const initialQuestion: Question = {
  title: '',
  options: [],
}

export const AddForms = () => {
  const [formName, setFormName] = useState('')
  const queryClient = useQueryClient()
  const [questions, setQuestions] = useState<Question[]>([initialQuestion])
  const [error, setError] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [load, setLoad] = useState(false)
  const [loadButton, setLoadButton] = useState(false)
  const navigate = useNavigate()
  const handleCancel = () => {
    Swal.fire({
      title: '¿Está seguro que deseas cancelar la edición?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(0, 123, 195)',
      confirmButtonText: 'Seguir editando',
      cancelButtonText: 'Cerrar edición',
      reverseButtons: true,
    }).then((result) => {
      if (result.isDismissed) {
        navigate('/configuration/forms')
      }
    })
  }
  useEffect(() => {
    setLoad(true)
    const fetchData = async () => {
      try {
        const questionnaire = 'Preguntas para determinar la tolerancia al riesgo'
        const {
          data: { data },
        } = await apiUrl.post(`/app/customer/show-questionnaire/${questionnaire}`)
        if (data) {
          setLoad(false)
          setFormName(data.formName)
          const filteredData = data.questions.map((question: any) => {
            const { created_at, updated_at, id, ...rest } = question
            const filteredOptions = rest.options.map((option: any) => {
              const { created_at, updated_at, id, ...restOption } = option
              return restOption
            })
            return { ...rest, options: filteredOptions }
          })
          setQuestions(filteredData)
        }
      } catch (error) {
        setLoad(true)
        customSwal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          text: 'Intente nuevamente.',
        })
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const handleAddOption = (questionIndex: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions]
      const options = [
        ...updatedQuestions[questionIndex].options,
        {
          title: '',
          score: 0,
        },
      ]
      updatedQuestions[questionIndex] = { ...updatedQuestions[questionIndex], options }
      return updatedQuestions
    })
  }

  const handleQuestionChange = (questionIndex: number, value: string) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions]
      updatedQuestions[questionIndex] = { ...updatedQuestions[questionIndex], title: value }
      return updatedQuestions
    })
  }

  const handleOptionChange = (
    questionIndex: number,
    optionIndex: number,
    title: string,
    score: number
  ) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions]
      const updatedOptions = [...updatedQuestions[questionIndex].options]
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        title,
        score,
      }

      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: updatedOptions,
      }
      return updatedQuestions
    })
  }

  const handleAddQuestion = () => {
    if (questions.some((question) => question.title === '')) {
      setError('Completa la pregunta antes de agregar una nueva')
      return
    }
    setQuestions((prevQuestions) => [...prevQuestions, initialQuestion])
  }
  const handleRemoveQuestion = (questionIndex: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions]
      updatedQuestions.splice(questionIndex, 1)
      return updatedQuestions
    })
  }

  const handleRemoveOption = (questionIndex: number, optionIndex: number) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions]
      const updatedOptions = [...updatedQuestions[questionIndex].options]
      updatedOptions.splice(optionIndex, 1)
      updatedQuestions[questionIndex] = {
        ...updatedQuestions[questionIndex],
        options: updatedOptions,
      }
      return updatedQuestions
    })
  }
  const handleSave = () => {
    setError(' ')
    if (formName.trim() === '') {
      setError('Ingresa un nombre para el formulario')
      return
    } else if (questions.some((question) => question.title === '')) {
      setError('Completa todas las preguntas antes de guardar')
      return
    } else if (questions.length === 0) {
      setError('Completa todas las opciones y puntajes antes de guardar')
      return
    } else if (questions.some((question) => question.options.length === 0)) {
      setError('Agrega al menos una opción a la pregunta.')
      return
    } else if (
      questions.some((question) =>
        question.options.some((option) => option.title === '' || option.score === 0)
      )
    ) {
      setError('Completa todas las opciones y puntajes antes de guardar')
      return
    } else {
      setError(' ')
      const formData: Form = {
        status: 1,
        formName,
        questions,
      }
      setError(' ')
      setLoadButton(true)
      apiUrl
        .post(`/app/customer/update-questionnaire`, formData)
        .then(() => {
          setLoadButton(false)
          customSwal.fire({
            icon: 'success',
            text: 'El formulario se ha editado con éxito',
          })
          queryClient.invalidateQueries(['questionnaire-show'])
          navigate('/configuration/forms')
        })
        .catch((error) => {
          setLoadButton(false)

          customSwal.fire({
            icon: 'error',
            title:
              error.response.status === 403
                ? 'No tienes permisos para realizar esta acción'
                : 'Ha ocurrido un error al editar el formulario',
            text: 'Intente nuevamente.',
          })
        })
    }
  }
  return (
    <React.Fragment>
      <div className='container'>
        <div className='card'>
          <div className='m-5 p-5'>
            <h2 style={{ color: '#001F45', letterSpacing: '0.4px' }} className='text-start  p-3'>
              Detalle de formulario
            </h2>
            <div className='row p-2'>
              <div className='col-lg-12'>
                <div className='form-floating mb-7'>
                  <input
                    type='text'
                    className='form-control'
                    value={formName}
                    id='formName'
                    disabled
                    onChange={(e) => setFormName(e.target.value)}
                    placeholder='Nombre de Formulario'
                  />
                  <label>Nombre de Formulario</label>
                </div>
              </div>
            </div>
            <div className='w-full text-end'>
              <button
                onClick={handleAddQuestion}
                style={{
                  background: 'rgb(0, 123, 195)',
                  color: 'white',
                  height: '48px',
                }}
                className='btn fs-6 px-5 '
              >
                {' '}
                <i style={{ color: 'white', fontSize: 24 }} className='bi bi-plus'></i> Agregar
                pregunta
              </button>
            </div>
          </div>
        </div>
        {questions.map((question, questionIndex) => (
          <div className='card mt-6 mb-5' key={questionIndex}>
            <div className='text-end'>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i className='ki-duotone ki-cross fs-1 m-5'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
              <button
                onClick={() => handleRemoveQuestion(questionIndex)}
                style={{ background: 'none', color: 'black' }}
                className='btn btn-icon ml-3 '
              >
                {' '}
                <i style={{ color: 'black' }} className='bi bi-x-lg  fs-4'></i>
              </button>
            </div>
            <div className='m-5 p-2 row'>
              <h3 className='text-center text-muted mb-5'>Pregunta Nº {questionIndex + 1}</h3>
              <div className='col-lg-10'>
                <div className='form-floating mb-7'>
                  <input
                    type='text'
                    className='form-control h-42px'
                    id={`question-${questionIndex}`}
                    value={question.title}
                    onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                    placeholder='Nombre de Formulario'
                  />
                  <label>Nombre de la pregunta</label>
                </div>
              </div>
              <div className='col-lg-2'>
                <button
                  onClick={() => handleAddOption(questionIndex)}
                  style={{
                    background: 'rgb(0, 123, 195)',
                    color: 'white',
                    marginRight: '',
                    height: '48px',
                    width: '100%',
                  }}
                  className='btn  mr-3 fs-6 px-2 d-flex align-items-center justify-content-center'
                >
                  {' '}
                  <i style={{ color: 'white', fontSize: 24 }} className='bi bi-plus'></i> Agregar
                  opción
                </button>
              </div>
            </div>

            {question.options.map((option, optionIndex) => (
              <div className='m-5 p-5 pt-0 mt-0 mb-7' key={optionIndex}>
                <div className='row ml-5 pl-5'>
                  <div className='col-lg-9 '>
                    <div className='form-floating'>
                      <input
                        type='text'
                        className='form-control'
                        id={`option-${questionIndex}-${optionIndex}`}
                        value={option.title}
                        onChange={(e) =>
                          handleOptionChange(
                            questionIndex,
                            optionIndex,
                            e.target.value,
                            option.score
                          )
                        }
                        placeholder='Nombre de Formulario'
                      />
                      <label>Opción Nº {optionIndex + 1}</label>
                    </div>
                  </div>
                  <div className='col-lg-2'>
                    <div className='form-floating'>
                      <input
                        type='text'
                        className='form-control'
                        id={`score-${questionIndex}-${optionIndex}`}
                        value={option.score ? `${option.score}` : ''}
                        onChange={(e) =>
                          handleOptionChange(
                            questionIndex,
                            optionIndex,
                            option.title,
                            parseInt(e.target.value)
                          )
                        }
                        placeholder='Nombre de Formulario'
                      />
                      <label>Puntaje</label>
                    </div>
                  </div>
                  <div className='col-lg-1 d-flex align-items-center'>
                    <button
                      className='btn btn-icon '
                      style={{
                        background: 'transparent',
                        border: '1px solid #e1e3ea',
                      }}
                      onClick={() => handleRemoveOption(questionIndex, optionIndex)}
                    >
                      <i className='bi bi-trash text-gray-500 fs-3'></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
        {/* <button onClick={handleSave}>Guardar</button> */}
      </div>
      <div style={{ marginLeft: 25 }}>
        <span>{error && <p className='text-danger'>{error}</p>}</span>
      </div>
      <div className='card-footer border-0 mt-0 pt-0 m-5 p-5 row'>
        <div className='text-end col'>
          <button
            style={{ backgroundColor: '#eee', color: '#969696' }}
            type='button'
            className='btn btn col m-3'
            onClick={handleCancel}
          >
            Cancelar
          </button>
          <button
            style={{
              background: 'rgb(0, 123, 195)',
              color: 'white',
            }}
            disabled={loadButton ? true : false}
            onClick={handleSave}
            type='button'
            className='btn btn col m-3 fw'
          >
            {loadButton ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}
