import React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { IClient } from '../../../common/interfaces/role.interface'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
interface IProps {
  id: string
  title: string
  type: 'action' | 'confirmation'
  confirmation: boolean
  positiveAnswer: string
  confirmationId?: string
  confirmationAnswer?: string
  confirmationTitle?: string
  negativeAnswer?: string
  selectedValue?: IClient
  setChangedValue?: React.Dispatch<React.SetStateAction<any>>
  changedValue?: boolean
  archive?: boolean
  deleteUrl?: string
  updateStatusUrl?: string
}
export const ModalConfirm = ({
  id,
  title,
  negativeAnswer,
  positiveAnswer,
  confirmationId,
  selectedValue,
  changedValue,
  setChangedValue,
  archive,
  type,
  confirmationTitle,
  deleteUrl,
  updateStatusUrl,
}: IProps) => {
  const changeUserStatus = async () => {
    try {
      if (archive && deleteUrl) {
        await apiUrl.delete(deleteUrl + selectedValue?.id)
      } else if (updateStatusUrl) {
        await apiUrl
          .put(updateStatusUrl + selectedValue?.id)
          .then((response) => {
            if (response) {
              customSwal.fire({
                icon: 'success',
                title: 'Se ha realizado correctamente',
                text: 'Intente nuevamente.',
              })
            }
          })
          .catch((error) => {
            if (error) {
              customSwal.fire({
                icon: 'error',
                title:
                  error.response.status === 403
                    ? 'No tienes permisos para realizar esta acción'
                    : `Ha ocurrido un error al ${confirmationTitle} `,
                text: 'Intente nuevamente.',
              })
            }
          })
      }
      setChangedValue && setChangedValue(!changedValue)
    } catch (err) {}
  }
  return (
    <React.Fragment>
      <div className='modal fade p-5 rounded-4' tabIndex={-1} id={id} role='dialog'>
        <div className='modal-dialog rounded-4 modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body p-0 mb-5'>
              <div className='text-center mb-8 p-0'>
                <img src={`${toAbsoluteUrl('/✅ Web - Mm/icons-56.png')}`} alt='e' />
              </div>
              <div className='fw-bolder'>
                <h1 className='text-alert text-center'>{title}</h1>
              </div>
            </div>

            <div className='text-center mx-5 row mb-5'>
              {type === 'action' ? (
                <React.Fragment>
                  <button
                    style={{ backgroundColor: '#E2F4FF', color: '#1C73CF' }}
                    type='button'
                    className='btn btn col m-3'
                    data-bs-dismiss='modal'
                  >
                    {negativeAnswer}
                  </button>
                  <button
                    style={{
                      background: '#0066CC',
                      color: 'white',
                    }}
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target={confirmationId}
                    onClick={changeUserStatus}
                    className='btn col m-3 fw'
                  >
                    {positiveAnswer}
                  </button>
                </React.Fragment>
              ) : (
                <button
                  style={{
                    background: '#0066CC',
                    color: 'white',
                  }}
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  type='button'
                  className='btn btn col m-3 fw'
                >
                  {positiveAnswer}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
