import { IFundAmountRequest, IToggleSignDocument, IUpsertDocument } from '../common/interfaces'
import { apiUrl } from '../config/api'
import { getDocumentType } from '../modules/clients/utils'

export const FundService = () => {
  const fundsByUser = async (document_type: string, document_number: string) => {
    const document = getDocumentType(document_type)
    return (
      await apiUrl.get(
        `/fund/bo/funds-by-user?numIdentidad=${document_number}&tipoIdentidad=${document}&origin=WEB`
      )
    ).data
  }

  const showFunds = () => apiUrl.get(`/fund/show-funds-bo`).then((res) => res.data)

  const upsertFundDocument = (IUpsertDocument: IUpsertDocument) =>
    apiUrl.post(`/fund/bo/upsert-document`, IUpsertDocument).then((res) => res.data)

  const listFundDocuments = (cod_fund: string) =>
    apiUrl.get(`/fund/bo/list-documents/${cod_fund}`).then((res) => res.data)

  const toggleSignDocument = (toggleSignDocument: IToggleSignDocument) =>
    apiUrl.patch(`/fund/bo/toggle-sign-document`, toggleSignDocument).then((res) => res.data)

  const listProfile = () => apiUrl.get(`profile-fund/list`).then((res) => res.data)

  const changeStatus = (id: number) =>
    apiUrl.patch(`/fund/change-status/${id}`).then((res) => res.data)

  const spectrumSync = () => apiUrl.patch('/fund/spectrum-sync').then((res) => res.data)
  const setRecommendedFunds = (id: number | string) =>
    apiUrl.patch('profile-fund/set-recommended/' + id).then((res) => res.data)
  const setFundAmountInversion = (body: IFundAmountRequest) =>
    apiUrl.put('/fund/bo/update-fund-amount-inversion', body).then((res) => res.data)

  return {
    fundsByUser,
    showFunds,
    listFundDocuments,
    upsertFundDocument,
    toggleSignDocument,
    listProfile,
    changeStatus,
    spectrumSync,
    setRecommendedFunds,
    setFundAmountInversion,
  }
}
