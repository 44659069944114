import { AxiosError } from 'axios'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG } from '../../../../_metronic/helpers'
import { ICreateRoleV2 } from '../../../common/interfaces'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
import ModalAction from './components/modals/ModalAction'
import RoleForm from './form/RoleForm'

const createRoleSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mínimo 3 caracteres").max(140, 'Máximo 140 caracteres').required('Nombre es requerido'),
  description: Yup.string().min(3, "Mínimo 3 caracteres").max(140, 'Máximo 140 caracteres').required('Descripción es requerido'),
  status: Yup.number().required('Estado es requerido'),
  permissions: Yup.array().of(Yup.number()).min(1, 'Debe seleccionar al menos un permiso'),
})

const inits: ICreateRoleV2 = {
  name: '',
  status: undefined,
  description: '',
  permissions: [],
}

interface IPromise {
  resolve: (value?: any) => void
  reject: (reason?: any) => void
}

export const AddRoleV2 = () => {
  const navigate = useNavigate()
  const [promise, setPromise] = useState<IPromise | null>(null)
  const [showModalCancel, setShowModalCancel] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)

  const confirm = () => {
    setShowModalConfirm(true)
    return new Promise((resolve, reject) => {
      setPromise({ resolve, reject })
    })
  }

  const handleSubmit = async (values: ICreateRoleV2, actions: FormikHelpers<ICreateRoleV2>) => {
    values.status = Number(values.status)
    try {
      await confirm()
      await apiUrl.post(`/capability/role/create`, values)
      customSwal
        .fire({
          title: 'Rol creado satisfactoriamente',
          icon: 'success',
          allowOutsideClick: false,
        })
        .then((result) => {
          actions.resetForm()
          result.isConfirmed && navigate('/role/list')
        })
    } catch (error) {
      const axiosError = error as AxiosError<{ message?: string }>
      customSwal.fire({
        title:
          axiosError.response?.status === 403
            ? 'No tienes permisos para realizar esta acción'
            : axiosError.response?.data?.message || 'Ha ocurrido un error',
        text: 'Intente nuevamente.',
        icon: 'error',
      })
    } finally {
      setPromise(null)
      setShowModalConfirm(false)
    }
  }

  const handleCancel = () => {
    navigate(-1)
    setShowModalCancel(false)
  }

  return (
    <div className='card position-relative'>
      <Formik validationSchema={createRoleSchema} initialValues={inits} onSubmit={handleSubmit}>
        {(props: FormikProps<ICreateRoleV2>) => (
          <Form
            autoComplete='off'
            noValidate
            id='kt_create_account_form'
            className='w-100'
            onSubmit={props.handleSubmit}
          >
            <div className='current'>
              <div className='my-10 w-100'>
                <RoleForm formProps={props} mode='create' />
              </div>
            </div>
            <div className='card-footer border-0 d-flex flex-end gap-6'>
              <button
                type='button'
                className='btn btn-lg'
                style={{ backgroundColor: '#F9F9F9', color: '#7F8BA0' }}
                onClick={() => setShowModalCancel(true)}
              >
                Cancelar
              </button>
              <button
                type='submit'
                className='btn btn-lg'
                style={{ backgroundColor: '#0066CC', color: 'white' }}
              >
                <span className='indicator-label'>
                  Enviar
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr64.svg'
                    className='svg-icon-3 ms-2 me-0'
                  />
                </span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <ModalAction
        type='action'
        title='¿Seguro que deseas cancelar?'
        negativeAnswer='Cancelar'
        positiveAnswer='Aceptar'
        onSubmit={handleCancel}
        show={showModalCancel}
        onHide={() => setShowModalCancel(false)}
      />
      <ModalAction
        type='action'
        title='¿Está seguro que desea crear este rol?'
        negativeAnswer='No, regresar'
        positiveAnswer='Sí, crear'
        onSubmit={() => promise && promise.resolve()}
        show={showModalConfirm}
        onHide={() => setShowModalConfirm(false)}
      />
    </div>
  )
}
