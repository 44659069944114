import { FC, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { ClientRouting } from './modules/ClientRouting'
import { ConfigGeneralRouting } from './modules/ConfigGeneralRouting'
import { FundsRouting } from './modules/FundsRouting'
import { HomeRouting } from './modules/HomeRouting'
import { LoggerRouting } from './modules/LoggerRouting'
import { MovementsRouting } from './modules/MovementsRouting'
import { NotificationRouting } from './modules/NotificationRouting'
import { ProspectRouting } from './modules/ProspectRouting'
import { RoleRouting } from './modules/RoleRouting'
import { UserRouting } from './modules/UserRouting'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {/* <Route path='auth/*' element={<Navigate to='/client/list' />} /> */}
        {/* <Route path='auth/*' element={<Navigate to='/client/list' />} /> */}

        {/* create a route to Dashboard */}
        <Route path='auth/*' element={<Navigate to='/' />} />

        {/* Dashboard */}
        <Route path='/' element={<HomeRouting />} />

        {/* Users */}
        <Route path='/user/*' element={<UserRouting />} />

        {/* Roles */}
        <Route path='/role/*' element={<RoleRouting />} />

        {/* Clients */}
        <Route path='/client/*' element={<ClientRouting />} />

        {/* Notification */}
        <Route path='/notification/*' element={<NotificationRouting />} />

        {/* Prospect */}
        <Route path='/prospect/*' element={<ProspectRouting />} />

        {/* Logger */}
        <Route path='/logs/*' element={<LoggerRouting />} />

        {/* Movements */}
        <Route path='/movements/*' element={<MovementsRouting />} />

        {/* funds */}
        <Route path='/funds/*' element={<FundsRouting />} />

        {/* Config */}

        <Route path='/configuration/*' element={<ConfigGeneralRouting />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
