import { ICustomerDetail } from '../../../../common/interfaces'

interface IProps {
  customerDetail: ICustomerDetail
}

export const BankPersonTable = ({ customerDetail }: IProps) => {
  return (
    <>
      {customerDetail.bank_accounts.length ? (
        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
          <thead>
            <tr className='fw-normal text-muted'>
              <th></th>
              <th>ENTIDAD FINANCIERA</th>
              <th>TIPO DE CUENTA</th>
              <th>MONEDA</th>
              <th>N° CTA BANCARIA</th>
              <th>N° CCI</th>
            </tr>
          </thead>
          <tbody>
            {customerDetail.bank_accounts.map((bank_account, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                      }}
                      className='form-check form-check-custom form-check-solid'
                    >
                      <input
                        style={{
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                        }}
                        className='form-check-input'
                        type='checkbox'
                        id='flexCheckDefault'
                        disabled={true}
                        checked={bank_account.is_main}
                      />
                    </div>
                  </td>
                  <td>
                    <span className='text-muted d-block fs-5'>{bank_account.bank.description}</span>
                  </td>

                  <td>
                    <span className='text-muted d-block fs-5'>
                      {bank_account.account_type.description}
                    </span>
                  </td>
                  <td>
                    <span className='text-muted d-block fs-5'>{bank_account.money_type}</span>
                  </td>
                  <td>
                    <span className='text-muted d-block fs-5'>{bank_account.account_number}</span>
                  </td>
                  <td>
                    <span className='text-muted d-block fs-5'>{bank_account.cci}</span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className='d-flex flex-grow-1 justify-content-center align-items-center'>
          <span
            className='notFound text-muted text-muted fs-5 text-center'
            style={{ marginTop: 100 }}
          >
            No se encontraron cuentas bancarias para este cliente
          </span>
        </div>
      )}
    </>
  )
}
