import clsx from 'clsx'
import { FC, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { DrawerComponent } from '../../assets/ts/components'
import { WithChildren } from '../../helpers'
import { useLayout } from '../core'

const Content: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout()
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])
  const hasNotification = useSelector((state: any) => state.notification.hasNotification)

  useEffect(() => {
    if (hasNotification) {
      toast(
        (t) => (
          <div className='conatainer' style={{ borderRadius: '6px' }}>
            <div className='row'>
              <div className='col-8 mt-5 text-start'>
                <Link to={'/notification/page'}>
                  <span className='d-block fw-bold' style={{ color: '#1c73cf' }}>
                    {' '}
                    Tienes una nueva notificación
                  </span>
                  {/* <span> Tienes una notificación nueva</span> */}
                </Link>
                <div></div>
              </div>
              <div className='col-4 text-end' style={{ margin: 'auto' }}>
                <button
                  style={{ backgroundColor: '#f3f6f9', color: '#1c73cf' }}
                  className='btn'
                  onClick={() => toast.dismiss(t.id)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        ),
        {
          duration: 10000,
          position: 'top-right',
        }
      )
    }
  }, [hasNotification])
  return (
    <div id='kt_content_container' className={clsx(classes.contentContainer.join(' '))}>
      <Toaster />
      {children}
    </div>
  )
}

export { Content }
