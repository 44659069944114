import { AxiosError } from 'axios'
import { Form, Formik } from 'formik'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IUpdateClient } from '../../common/interfaces/client.interface'
import { apiUrl } from '../../config/api'
import { customSwal } from '../../config/swal'
import { EditClientForm } from './components/EditClientForm'

const editClientSchema = Yup.object().shape({
  username: Yup.string().required('Nombre de usuario es requerido'),
  email: Yup.string()
    .email('Formato de correo invalido')
    .required('Correo electrónico es requerido'),
  phone_number: Yup.string()
    .max(12, 'Debe tener menos de 12 caracteres')
    .required('Número de télefono es requerido'),
  password: Yup.string()
    .min(6, 'Contraseña debe tener 6 caracteres')
    .max(6, 'Contraseña debe tener 6 caracteres')
    .required('Contraseña es requerida'),
  passwordConfirmation: Yup.string()
    .required('Confirmación de contraseña es requerida')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Contraseña y Confirmar Contraseña no coinciden'
      ),
    }),
  number_document: Yup.string()
    .length(8, 'Debe tener 8 caracteres')
    .required('Número de documento es requerido'),
})

const inits: IUpdateClient = {
  username: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  phone_number: '',
  number_document: '',
}
export const EditClient = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <React.Fragment>
      <div className='position-relative'>
        <Link to={`/client/show/${id}`}>
          <img
            src={toAbsoluteUrl('/✅ Web - Mm/aroow.png')}
            className='position-absolute cursor-pointer'
            style={{
              top: '80px',
              left: '100px',
            }}
            alt=''
          />
        </Link>
        <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
          <Formik
            validationSchema={editClientSchema}
            initialValues={inits}
            onSubmit={async (values) => {
              try {
                await apiUrl.put('/user/app/update/' + id, values)

                customSwal
                  .fire({
                    title: 'Cliente editado satisfactoriamente',
                    icon: 'success',
                    allowOutsideClick: false,
                  })
                  .then((result) => {
                    result.isConfirmed && navigate('/client/show/' + id)
                  })
              } catch (err: any) {
                const errors = err as AxiosError
                if (errors.response?.data) {
                  customSwal.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error',
                    text: 'Intente nuevamente.',
                  })
                }
              }
            }}
          >
            {(props) => (
              <Form
                autoComplete='off'
                className='w-100 w-xl-700px mb-20 '
                onSubmit={props.handleSubmit}
                noValidate
                id='kt_create_account_form'
              >
                <div className='current' data-kt-stepper-element='content'>
                  <div className='mt-20 w-100'>
                    <EditClientForm formProps={props} />
                  </div>
                </div>

                <div className='d-flex flex-end pt-10'>
                  <button
                    type='submit'
                    style={{
                      backgroundColor: '#0066CC',
                      color: 'white',
                    }}
                    className='btn btn-lg '
                  >
                    <span className='indicator-label'>
                      {'Actualizar'}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 text-white ms-2 me-0'
                      />
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}
