import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../../app/common/helpers/ReduxHooks'
import { NotificationService } from '../../../../app/services/NotificationService'
import { setNotificationState } from '../../../../redux/notificationSlice'

export const useNotifications = (limit?: number, page?: number, trigger: boolean = false) => {
  const dispatch = useAppDispatch()
  const { data: notifications, ...notificationQuery } = useQuery(
    ['notifications', limit, page],
    () => NotificationService().listNotifications(limit!, page!),
    {
      enabled: !!limit && !!page && trigger,
    }
  )

  useEffect(() => {
    const source = new EventSource(process.env.REACT_APP_EVENT_SOURCE || '')
    source.onmessage = () => {
      dispatch(setNotificationState())
    }

    source.onerror = (error) => {
      console.error('Error en la conexión con el servidor de eventos:', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    query: {
      notificationStatus:
        (!notificationQuery.isLoading || !notificationQuery.isFetching) &&
        !notificationQuery.isError,
      notifications,
    },
  }
}
