export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string | string[]) {
  const current = getCurrentUrl(pathname)
  if (typeof url === 'string') {
    if (!current || !url) {
      return false
    }

    if (current === url) {
      return true
    }

    if (current.indexOf(url) > -1) {
      return true
    }

    return false
  } else {
    return url.some((value) => current.includes(value))
  }
}
