export interface HeaderExcel {
  'Nº De Documento': string
  'Tipo Documento': string
}

export const TabHeader = [
  { id: 'ListOfClient', name: 'Listado de clientes', icon: ' bi-person-lines-fill fs-3 m-2' },
  { id: 'ListOfClientBlocked', name: 'Clientes bloqueados', icon: '  bi-person-slash fs-3 m-2' },
]

export function getDocumentType(documentType: string) {
  switch (documentType) {
    case 'OTRO':
      return '00'
    case 'DNI':
      return '01'
    case 'MILITAR':
      return '02'
    case 'EXTRANJERIA':
      return '03'
    case 'REGISTRO_CONTRIBUYENTE':
      return '04'
    case 'PASAPORTE':
      return '05'
    case 'NUM_PARTICIPE':
      return '06'
    default:
      return ''
  }
}

interface IHistoryData {
  title?: string
  status: string
  date: string
  type: string
}

export const _historyData: IHistoryData[] = [
  {
    title: 'Nombre del fondo B',
    status: '1',
    date: '20/02/2024 20:03',
    type: 'subscription',
  },
  {
    title: 'Nombre del fondo A',
    status: '0',
    date: '20/02/2024 20:03',
    type: 'subscription',
  },
  {
    status: '1',
    date: '20/02/2024 20:03',
    type: 'register',
  },
]
