/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BackofficePermissionsEnum as Permissions } from '../../../../common/enums/backOfficePermissionsEnum'
import { getDDMMYYYYFormat } from '../../../../common/helpers/DateHandlers'
import { usePaginator } from '../../../../common/hooks/usePaginator'
import usePermissions from '../../../../common/hooks/usePermissions'
import { IRoleItem, StatusEnum } from '../../../../common/interfaces'
import { RoleService } from '../../../../services'
import { IFilters } from '../ListRole'

const pageSizes = [10, 20, 40, 100]

interface IProps {
  search: string
  setSearch: any
  changedRole: boolean
  setSelectedrole: React.Dispatch<React.SetStateAction<IRoleItem>>
  filters: IFilters
  setFilters?: (state: IFilters, cb?: ((state: IFilters) => void) | undefined) => void
}

export const RoleTable = ({
  search,
  setSearch,
  changedRole,
  setSelectedrole,
  filters,
  setFilters,
}: IProps) => {
  const filtersToFetch = getFilters(filters)
  const { hasPermission } = usePermissions()

  const {
    limit,
    setPage,
    setLimit,
    paginationComponent,
    query: { data: roles, isLoading, isFetching, refetch, isError },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(0)!,
    paginatorOptions: {
      queryKey: ['role-list-table'],
      fetch: RoleService().listRoles,
    },
    search,
    pageSizes,
    queryOptions: {
      retry: false,
    },
    filters: filtersToFetch,
  })

  const cleanupRequest = () => {
    setPage(1)
    setLimit(10)
    setSearch('')
    setFilters && setFilters({}, () => refetch())
  }

  useEffect(() => {
    setPage(1)
    refetch()
  }, [JSON.stringify(filters), limit, refetch, changedRole])

  return (
    <div className='card-body'>
      <div className='table-responsive-md p-2'>
        {(isLoading || isFetching) && !isError ? (
          <div className='text-center m-5 p-10'>
            <div className='spinner-border' role='status' />
          </div>
        ) : (
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bolder text-muted'>
                {hasPermission([
                  Permissions.ROLE_SHOW,
                  Permissions.ROLE_UPDATE,
                  Permissions.ROLE_DELETE,
                ]) && <th className='min-w-200px'>NOMBRE DEL ROL</th>}
                <th className='min-w-150px'>ÚLTIMA FECHA DE ESTADO</th>
                <th className='min-w-100px'>ESTADO</th>
                <th className='w-50px'>ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              {roles?.data?.map((role: IRoleItem, index: number) => (
                <tr key={`${role.id}-${index}`}>
                  <td>
                    <span className='text-muted'>{role.name}</span>
                  </td>
                  <td>
                    <span className='text-muted'>{getDDMMYYYYFormat(role.update_at, true)}</span>
                  </td>
                  <td>
                    {role.status === StatusEnum.ACTIVATED ? (
                      <span className='badge badge-light-success'>Activo</span>
                    ) : role.status === StatusEnum.DISABLED ? (
                      <span className='badge badge-light-danger'>Inactivo</span>
                    ) : (
                      <span className='badge badge-light-warning'>Archivado</span>
                    )}
                  </td>
                  {hasPermission([
                    Permissions.ROLE_SHOW,
                    Permissions.ROLE_UPDATE,
                    Permissions.ROLE_DELETE,
                  ]) && (
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <div className='dropdown'>
                          <button
                            className='btn btn-light'
                            type='button'
                            id='idDropdownRowButton'
                            data-bs-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                          >
                            <i className='fas fa-ellipsis-v p-0' />
                          </button>
                          <div className='dropdown-menu' aria-labelledby='idDropdownRowButton'>
                            {hasPermission(Permissions.ROLE_SHOW) && (
                              <Link to={`/role/show/${role.id}`} className='dropdown-item'>
                                <i className='bi bi-eye m-2' />
                                Ver detalle
                              </Link>
                            )}
                            {hasPermission(Permissions.ROLE_UPDATE) && role.editable && (
                              <Link to={`/role/edit/${role.id}`} className='dropdown-item'>
                                <i className='bi bi-pencil m-2' />
                                Editar
                              </Link>
                            )}
                            {hasPermission(Permissions.ROLE_UPDATE) && (
                              <>
                                {role.status === StatusEnum.ACTIVATED && (
                                  <button
                                    type='button'
                                    data-bs-toggle='modal'
                                    data-bs-target='#InactiveRole'
                                    className='dropdown-item text-danger'
                                    onClick={() => setSelectedrole(role)}
                                  >
                                    <i className='bi bi-lock m-2 text-danger' />
                                    Inactivar
                                  </button>
                                )}
                                {role.status === StatusEnum.DISABLED && (
                                  <button
                                    type='button'
                                    data-bs-toggle='modal'
                                    data-bs-target='#ActiveRole'
                                    className='dropdown-item text-success'
                                    onClick={() => setSelectedrole(role)}
                                  >
                                    <i className='bi bi-unlock m-2 text-success' /> Activar
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {(isError || (roles?.data?.length === 0 && !isLoading)) && (
          <span className='notFound text-muted fs-5 text-center d-flex flex-column m-8'>
            No se encontraron resultados
            <a className='link ml-2 cursor-pointer' onClick={cleanupRequest}>
              refrescar la tabla
            </a>
          </span>
        )}
        {paginationComponent()}
      </div>
    </div>
  )
}

const getFilters = (filters: IFilters) => {
  const { permissions, status, ...filtersSelected } = filters
  return {
    permissions: permissions?.length ? permissions.join(',') : null,
    status: status?.length ? status.join(',') : null,
    ...filtersSelected,
  }
}
