import { ICustomerDetailRequest, IUpdatePassword } from '../common/interfaces/client.interface'
import { apiUrl } from '../config/api'

export const ClientService = () => {
  const listClients = (
    page: number | string,
    limit: number | string,
    search: string,
    filters = {}
  ) =>
    apiUrl
      .post(`/user/app/list?page=${page}&limit=${limit}`, {
        search,
        filter: filters,
      })
      .then((res) => res.data)

  const showClient = (id: number | string) =>
    apiUrl.get(`/user/app/show/${id}`).then((res) => res.data)

  const customerDetail = (req: ICustomerDetailRequest) =>
    apiUrl.post(`/app/customer/customer-detail/`, req).then((res) => res.data)

  const upsertPasswordClient = ({
    customer_id,
    confirmation_password,
    new_password,
  }: IUpdatePassword) =>
    apiUrl
      .post(`/user/app/upsert-password/${customer_id}`, {
        new_password,
        confirmation_password,
      })
      .then((res) => res.data)

  return {
    listClients,
    showClient,
    upsertPasswordClient,
    customerDetail,
  }
}
