export interface IProfileFundListResponse {
  data: IProfileFundData
  limit: number
  page: number
  total: number
}

export enum DocumentTagEnum {
  CONTRATO_ADMINISTRACION = 'CONTRATO_ADMINISTRACION',
  REGLAMENTO_FONDOS_MUTUOS = 'REGLAMENTO_FONDOS_MUTUOS',
  ANEXO_REGLAMENTO = 'ANEXO_REGLAMENTO',
  PROSPECTO_SIMPLIFICADO = 'PROSPECTO_SIMPLIFICADO',
}
export enum FundAmountEnum {
  MIN_INITIAL_INVESTMENT = 'Inversión inicial mínima',
  MIN_FOLLOWING_INVESTMENT = 'Monto mínimo de suscripción adicional',
  MIN_RESCUE = 'Monto mínimo de rescate',
  MIN_AMOUNT_PERMANENCE = 'Saldo mínimo de permanencia',
  MIN_TIME_PERMANENCE = 'Plazo mínimo de permanencia',
}

export interface IToggleSignDocument {
  codFund: string
  document_tag: DocumentTagEnum
}

export interface IUpsertDocument {
  codFund: string
  document_tag: DocumentTagEnum
}

export interface IProfileFundData {
  profilesFunds: IProfilesFund[]
  funds: IFund[]
  profiles: IProfile[]
}

export interface IProfilesFund {
  id: number
  profileId: number
  fundId: number
  visible: boolean
  recommended: boolean
}

export interface IFund {
  id: number
  codFund: string
  cod_risk_profile: number
  level_risk: string
  title: string
  annualized_return: string
  msci_acwi_index: string
  effectiveness: string
  fee_value: string
  status: number
}

export interface IProfile {
  id: number
  name: string
  subtitle: string
  rangeMax: number
  detail: string
}

export enum FundSerieEnum {
  SERIEA = 'Serie A',
  SERIEB = 'Serie B',
}

export enum SerieCodeEnum {
  SERIEA = '000',
  SERIEB = '001',
}

export interface dataForm {
  codFund: number
  title: string
  description: string
  riskLevel: RiskLevel
  investmentStrategy: InvestmentStrategy
  performance: Performance
  briefcase: Briefcase
  features: Features
  status: string
}

export interface RiskLevel {
  visibility: boolean
  riskData: number
}

export interface InvestmentStrategy {
  visibility: boolean
  strategyTitle: string
  legalInformation: string
  data: InvestmentStrategyData[]
}

export interface InvestmentStrategyData {
  color: string
  name: string
  graphicValue: string
}

export interface Performance {
  title: string
  visibility: boolean
  data: PerformanceData[]
}

export interface PerformanceData {
  year: number
  total: number
  performanceDate: PerformanceDate[]
}

export interface PerformanceDate {
  dateYear: string
  valueNumber: string
}

export interface Briefcase {
  title: string
  visibility: boolean
  data: BriefcaseData[]
}

export interface BriefcaseData {
  color: string
  graphicValue: string
}

export interface Features {
  title: string
  visibility: boolean
  data: FeaturesData[]
}

export interface FeaturesData {
  color: string
  graphicValue: string
}

export interface IFundAmountRequest {
  cod_fund: string
  fund_amount_feature: IFundAmountFeature[]
}

export interface IFundAmountFeature {
  title: FundAmountEnum
  habitat: string
  description: string
}
export const FundsFormsInitial = {
  codFund: 0,
  title: '',
  description: '',
  riskLevel: {
    visibility: true,
    riskData: 0,
  },
  investmentStrategy: {
    visibility: true,
    strategyTitle: '',
    legalInformation: '',
    data: [
      {
        color: '',
        name: '',
        graphicValue: '',
      },
    ],
  },
  performance: {
    title: '',
    visibility: true,
    data: [
      {
        year: 2023,
        total: 2000,
        performanceDate: [
          {
            dateYear: '',
            valueNumber: '',
          },
        ],
      },
    ],
  },
  briefcase: {
    title: '',
    visibility: true,
    data: [
      {
        color: '',
        graphicValue: '',
      },
    ],
  },
  features: {
    title: '',
    visibility: true,
    data: [
      {
        color: '',
        graphicValue: '',
      },
    ],
  },
  status: '',
}
