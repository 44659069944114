import React from 'react'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ActionModal } from '../../../common/components/ActionModal'
import { BackofficePermissionsEnum as Permissions } from '../../../common/enums/backOfficePermissionsEnum'
import { useDebounce } from '../../../common/hooks/useDebounce'
import usePermissions from '../../../common/hooks/usePermissions'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { FilterRoles } from './components/FilterRoles'
import { RoleTable } from './components/RoleTable'

export interface IFilters {
  updated_at_start?: string
  updated_at_end?: string
  name?: string
  status?: number[]
  permissions?: number[]
}

export const ListRole = () => {
  const [search, setSearch] = useStateCallback('')
  const debouncedSearch = useDebounce(search, 500)
  const [selectedRole, setSelectedRole] = useStateCallback<any>({})
  const [changedRole, setChangedRole] = useStateCallback(false)
  const [filters, setFilters] = useStateCallback<IFilters>({})
  const { hasPermission } = usePermissions()

  return (
    <React.Fragment>
      <div className='card pt-5'>
        <div className='card-header border-bottom-0 align-items-center'>
          <div className='d-flex align-items-center gap-8'>
            <div
              style={{ background: 'rgb(0, 123, 195)' }}
              className='rounded p-2 d-flex justify-content-center align-items-center cursor-pointer'
              data-bs-toggle='modal'
              data-bs-target='#filter-roles'
            >
              <i className='bi bi-filter fs-2 text-white' />
            </div>
            {!(Object.keys(filters).length === 0 && filters.constructor === Object) && (
              <span
                style={{ borderBottom: '1px inset #a1a5b7' }}
                className='text-muted cursor-pointer d-block fs-6'
                onClick={() => setFilters({})}
              >
                Limpiar filtros
              </span>
            )}
          </div>
          {hasPermission(Permissions.ROLE_CREATE) && (
            <Link to={'/role/add'}>
              <button
                style={{ background: 'rgb(0, 123, 195)' }}
                className='btn px-5 text-white cursor-pointer d-flex align-items-center gap-3'
              >
                <img src={toAbsoluteUrl('/✅ Web - Mm/Plus.png')} alt='plus' />
                Agregar
              </button>
            </Link>
          )}
        </div>

        <RoleTable
          search={search}
          setSearch={setSearch}
          changedRole={changedRole}
          setSelectedrole={setSelectedRole}
          filters={filters}
          setFilters={setFilters}
        />

        <ActionModal
          id='ActiveRole'
          title='¿Está seguro que desea activar el rol?'
          positiveAnswer='Si, activar'
          type='action'
          negativeAnswer='No, regresar'
          confirmationTitle='El role fue activado'
          updateStatusUrl='/capability/role/change-status/'
          selectedValue={selectedRole}
          setChangedValue={setChangedRole}
          changedValue={changedRole}
        />
        <ActionModal
          id='InactiveRole'
          title='¿Está seguro que desea inactivar el rol?'
          positiveAnswer='Si, inactivar'
          type='action'
          negativeAnswer='No, regresar'
          confirmationTitle='El role fue inactivado'
          updateStatusUrl='/capability/role/change-status/'
          selectedValue={selectedRole}
          setChangedValue={setChangedRole}
          changedValue={changedRole}
        />
        <ActionModal
          id='ArchiveRole'
          title='¿Está seguro que desea archivar el rol?'
          negativeAnswer='No, regresar'
          positiveAnswer='Si, archivar'
          type='action'
          confirmationTitle='El role fue archivado'
          deleteUrl='/capability/role/delete/'
          archive={true}
          selectedValue={selectedRole}
          setChangedValue={setChangedRole}
          changedValue={changedRole}
        />
      </div>
      <FilterRoles setFilters={setFilters} filters={filters} />
    </React.Fragment>
  )
}
