import { apiUrl } from '../config/api'

export const UserService = () => {
  const showUser = (id: number | string) =>
    apiUrl.get(`/user/person/show/${id}`).then((res) => res.data)

  return {
    showUser,
  }
}
