/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { usePagination } from '../../../common/hooks/usePagination'
import { useStateCallback } from '../../../common/hooks/useStateCallback'
import { apiUrl } from '../../../config/api'
import { customSwal } from '../../../config/swal'
interface User {
  id: number
  name: string
  lastname: string
  email: string
  username: any
  code_unique_spectrum: string
  email_verified: boolean
  phone_number: any
  document_type: string
  number_document: string
  status: number
  send_email_password_bo: boolean
  customer_user_type: string
}

const pageSizes = [20, 30, 50, 100]

export const TocBloquedClients = forwardRef((props, ref) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useStateCallback('')
  const [selected, setSelected] = useState('')
  const [totalPages, setTotalPages] = useState(100)

  useImperativeHandle(ref, () => ({
    refreshList: () => {
      refetch()
    },
  }))

  const {
    isLoading,
    isError,
    data: users,
    isFetching,
    refetch,
  } = useQuery(['user-toc-bloqued'], () => listUsers(), {
    keepPreviousData: true,
  })
  const { page, limit, setPage, setLimit, paginationComponent } = usePagination({
    initPage: 1,
    initLimit: 10,
    list: users,
    pageSizes,
  })
  useEffect(() => {
    setPage(1)
    refetch()
  }, [limit, refetch])

  const cleanupRequest = () => {
    setPage(1)
    setLimit(20)
    setSearch('', () => {
      refetch()
    })
  }

  useEffect(() => {
    refetch()
  }, [page, search])

  const listUsers = () =>
    apiUrl
      .post(`/user/app/list-prospective-customer?limit=${limit}&page=${page}`, { search })
      .then((res) => res.data)

  const sendEmail = (email: string) => {
    customSwal
      .fire({
        title: `¿Está seguro que desea enviar correo al usuario para crear contraseña?`,
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: `Sí, enviar`,
        cancelButtonText: 'Cancelar',
      })
      .then((result) => {
        if (result.isConfirmed) {
          try {
            apiUrl
              .post(`/user/app/send-email-creation-password`, {
                email: email,
                resend: false,
                backoffice: true,
                origin: "WEB",
              })
              .then((response) => {
                setSelected('')
                customSwal.fire({
                  title: 'Se envió el correo exitosamente',
                  icon: 'success',
                })
              })
          } catch (error) {
            customSwal.fire({
              title: 'Ha ocurrido al enviar al correo',
              icon: 'error',
            })
          }
        }
      })
  }
  return (
    <React.Fragment>
      <div className='card'>
        <div className='card-header border-bottom-0 d-flex align-items-center'>
          <div className='text-center '>
            <input
              className='form-control'
              type='text'
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder='🔍︎  Buscador'
              style={{
                width: '100%',
                minWidth: '320px',
                backgroundColor: '#f9f9f9',
                border: 0,
                height: '40px',
              }}
              name='searchName'
            />
          </div>
          <div>
            <button
              style={{ background: '#007bc3', color: 'white' }}
              className='btn  hover-elevate-down'
              type='button'
              onClick={() => sendEmail(selected)}
              disabled={!selected}
            >
              Enviar correo
            </button>
          </div>
        </div>

        <div className='card-body'>
          <div className='table-responsive'>
            {(isLoading || isFetching) && !isError ? (
              <div className='text-center m-5 p-10'>
                <div className='spinner-border' role='status'></div>
              </div>
            ) : (
              <React.Fragment>
                {users.length === 0 ? (
                  <span className='text-title text-muted m-8 p-8 text-center'>
                    No se encontraron usuarios.
                  </span>
                ) : (
                  <div className='p-2'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='w-100px'></th>
                          <th className='min-w-150px'>NOMBRES</th>
                          <th className='min-w-150px'>APELLIDOS</th>
                          <th className='min-w-150px'>CORREO</th>
                          <th className='min-w-150px'>CELULAR</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.data.map((user: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: '15px',
                                  paddingBottom: '15px',
                                }}
                                className='form-check form-check-custom form-check-solid'
                              >
                                <input
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    cursor: 'pointer',
                                  }}
                                  className='form-check-input'
                                  type='checkbox'
                                  id='flexCheckDefault'
                                  onChange={() =>
                                    user.email !== selected
                                      ? setSelected(user.email)
                                      : setSelected('')
                                  }
                                  checked={selected === user.email}
                                />
                              </div>
                            </td>
                            <td>
                              <span className='text-muted fs-5'>
                                {(user.name ? `${user.name.trim()} ` : '') +
                                  (user.middlename ? `${user.middlename} ` : ' ')}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted fs-5'>
                                {(user.surname ? `${user.surname} ` : '') +
                                  (user.lastname ? `${user.lastname.trim()}` : '')}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted d-block fs-5'>
                                {user.email ? user.email : '--'}
                              </span>
                            </td>
                            <td>
                              <span className='text-muted d-block fs-5'>
                                {user.phone_number ? user.phone_number : '--'}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </React.Fragment>
            )}
            {isError && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-5'>
                No se encontraron resultados
                <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  refrescar la tabla
                </a>
              </span>
            )}
            {users?.data.length === 0 && !isLoading && (
              <span className='notFound text-muted text-muted fs-5 text-center mt-8 mb-8'>
                No se encontraron resultados
                <a
                  className='link'
                  style={{
                    marginLeft: 5,
                    cursor: 'pointer',
                  }}
                  onClick={cleanupRequest}
                >
                  mostrar todos los usuarios
                </a>
              </span>
            )}
            {paginationComponent()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
})
