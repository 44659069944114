/* eslint-disable react-hooks/exhaustive-deps */
import { SearchInput } from '../../../../common/components/SearchInput'
import { useDebounce } from '../../../../common/hooks/useDebounce'
import { useStateCallback } from '../../../../common/hooks/useStateCallback'
import { IPaymentStatusEnum } from '../../../../common/interfaces'
import { Filter } from '../Filter'
import { AnnulledTable } from '../tables/AnnulledTable'

export type IFilterStatus = '-1' | '0' | '1' | '2' | ''
export interface IFilters {
  start_date: string
  end_date: string
  fund?: string
  status?: IFilterStatus
  sended_spectrum?: boolean
}

export const AnnulledTab = () => {
  const [search, setSearch] = useStateCallback<string>('')
  const debouncedSearch = useDebounce(search, 500)
  const [filters, setFilters] = useStateCallback<IFilters>({
    start_date: '',
    end_date: '',
    sended_spectrum: undefined,
    status: IPaymentStatusEnum.ANNULLED as unknown as IFilterStatus,
  })

  return (
    <>
      <div className='card border-0 pt-5 border-radius-20px'>
        <div className='card-header border-bottom-0 mt-3'>
          <div className='d-flex align-items-center gap-5'>
            <SearchInput
              placeholder='🔍︎  Buscar movimiento'
              search={search}
              setSearch={setSearch}
            />
          </div>
        </div>
        <AnnulledTable
          search={search.length > 2 ? debouncedSearch! : ''}
          setSearch={setSearch}
          setFilters={setFilters}
          filters={filters}
        />
      </div>
      <Filter setFilters={setFilters} filters={filters} />
    </>
  )
}
