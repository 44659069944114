import { apiUrl } from '../config/api'

export const NotificationService = () => {
  const listNotifications = async (limit: number, page: number) => {
    return (await apiUrl.post(`/notification/list?limit=${limit}&page=${page}`)).data
  }

  return {
    listNotifications,
  }
}
