import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface INotificationState {
  notifications: []
  hasNotification: boolean
}

const initialState: INotificationState = {
  notifications: [],
  hasNotification: false,
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<any>) => {
      console.log(action)

      state.notifications = action.payload
      state.hasNotification = true
    },
    clearNotifications: (state) => {
      state.hasNotification = false
    },
    setNotificationState: (state) => {
      state.hasNotification = true
    },
  },
})

export const { setNotifications, clearNotifications, setNotificationState } =
  notificationsSlice.actions
export default notificationsSlice.reducer
